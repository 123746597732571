import { styled } from "@mui/material";
import Button from '@mui/material/Button';
import { get100Color, getPlus200Color } from "../../player/Utils";
import { PlayerContext } from "../../player/PlayerContext";
import { useContext } from "react";

function MaterialButton(props) {

    const playerCtxt = useContext(PlayerContext);
    const miniMultiplier =  playerCtxt != null ? playerCtxt.get("miniMultiplier") : 1;

    const FilledButton = styled(Button)(({ theme }) => ({
        color: 'white',
        backgroundColor: "#2FC4B5",
        fontWeight: 'bold',
        '&:hover': {
          backgroundColor: '#00a793',
        },
      }));

    const FilledInversedButton = styled(Button)(({ theme }) => ({
        color: '#2FC4B5',
        backgroundColor: "white",        
        '&:hover': {
            backgroundColor: '#DFF5F3',
        },
    }));

    const FilledInversedNeutralButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText("#FFFFFF"),
        backgroundColor: "white",
        '&:hover': {
            backgroundColor: '#DFF5F3',
        },
    }));

    const FacebookButton = styled(Button)(({ theme }) => ({
        color: 'white',
        backgroundColor: "#1877F2",
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#1D64DF',
        },
    }));

    const WeTransferButton = styled(Button)(({ theme }) => ({
        color: 'white',
        backgroundColor: "#419FFF",
        '&:hover': {
            backgroundColor: '#006edb',
        },
    }));

    const TonalButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText("#FFFFFF"),
        backgroundColor: "#2FC4B580",
        '&:hover': {
            backgroundColor: '#00a79380',
        },
    }));

    const OutlinedTonalButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText("#FFFFFF"),
        backgroundColor: "#FFFFFF",
        '&:hover': {
            backgroundColor: '#DFF5F3',
        },
    }));

    const BrandedFilledButton = styled(Button)(({ theme }) => ({
        color: props.color,
        backgroundColor: props.bgColor,
        '&:hover': {
          backgroundColor: getPlus200Color(props.bgColor),
        },
      }));

    const BrandedWhiteFilledButton = styled(Button)(({ theme }) => ({
        color: props.color,
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: get100Color(props.rippleColor != null ? props.rippleColor : props.color), 
        },
    }));

    const style: CSSProperties = {
        fontWeight: "bold",
        borderRadius : 20,
        padding: 8,
        paddingLeft: 16,
        paddingRight: 16,
        fontSize: 14,
        cursor: "pointer",
    };

    if (props.style == "outlined-primary"){
        return <Button id={props.id} variant="outlined" startIcon={props.icon != null && <img src={props.icon} style={{width: 16, height: 16, paddingBottom: 2}}/>} sx={{borderRadius: 20, paddingLeft: 2.5, paddingRight: 2.5, textTransform: 'capitalize', fontWeight: 'bold',}} onClick={props.onClick}>{props.value}</Button>;        
    }
    else if (props.style == "instagram-outlined"){
        style.border = "double 1px transparent";
        style.borderRadius = "20px";
        style.backgroundImage = "linear-gradient(white, white), radial-gradient(circle at top left, #f00,#3020ff)";
        style.backgroundOrigin = "border-box";
        style.backgroundClip = "padding-box, border-box";

        style.backgroundColor =  props.bgColor != null ? props.bgColor : "white";
        style.color = props.color != null ? props.color : "#79747E";  
        return <button id={props.id} className="maker-material-button" style={{...style, height: 36, textAlign: "center", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: 'center', alignContent: 'center', textTransform: 'capitalize'}} onClick={props.onClick}>{props.icon && <img src={props.icon} style={{width: 16, height: 16, marginRight: 16}}/>}{props.value}</button>         
    }
    else if (props.style == "outlined-tonal"){
        return <OutlinedTonalButton id={props.id} variant="outlined" startIcon={props.icon != null && <img src={props.icon} style={{width: 16, height: 16, paddingBottom: 2}}/>} sx={{borderRadius: 20, fontWeight: 'bold', paddingLeft: 2.5, paddingRight: 2.5, textTransform: 'capitalize'}} onClick={props.onClick}>{props.value}</OutlinedTonalButton>;            
    }
    else if (props.style == "filled-tonal"){
        return <TonalButton id={props.id} startIcon={props.icon != null && <img src={props.icon} style={{width: 16, height: 16, paddingBottom: 2}}/>} sx={{borderRadius: 20, fontWeight: 'bold', paddingLeft: 2.5, paddingRight: 2.5, textTransform: 'capitalize'}} onClick={props.onClick}>{props.value}</TonalButton>;
    }
    else if (props.style == "facebook"){        
        return <FacebookButton id={props.id} startIcon={props.icon != null && <img src={props.icon} style={{width: 16, height: 16, paddingBottom: 2}}/>} sx={{borderRadius: 20, paddingLeft: 2.5, paddingRight: 2.5, textTransform: 'capitalize'}} onClick={props.onClick}>{props.value}</FacebookButton>;
    }
    else if (props.style == "wetransfer"){        
        return <WeTransferButton id={props.id} startIcon={props.icon != null && <img src={props.icon} style={{width: 16, height: 16, paddingBottom: 2}}/>} sx={{borderRadius: 20, paddingLeft: 2.5, paddingRight: 2.5, textTransform: 'capitalize'}} onClick={props.onClick}>{props.value}</WeTransferButton>;
    }
    else if (props.style == "filled"){
        return <FilledButton id={props.id} startIcon={props.icon != null && <img src={props.icon} style={{width: 16, height: 16, paddingBottom: 2}}/>} sx={{borderRadius: 20, paddingLeft: 2.5, paddingRight: 2.5, textTransform: 'capitalize'}} onClick={props.onClick}>{props.value}</FilledButton>;
    }
    else if (props.style == "instagram-filled"){
        style.border = "none";
        style.color = "white";
        style.background = "linear-gradient(to right, #F1000C 0%, #C20098 100%)";
        return <button id={props.id} className="maker-material-button" style={{...style, height: 36, textAlign: "center", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: 'center', alignContent: 'center', textTransform: 'capitalize'}} onClick={props.onClick}>{props.icon && <img src={props.icon} style={{width: 16, height: 16, marginRight: 16}}/>}{props.value}</button> 
    }
    else if (props.style == "text"){        
        const reducedStyles = props.reducedPadding ? {display: 'inline-block', minWidth: 40, minHeight: 0, padding: 0.5, } : {};
        if (props.lowercase)
            reducedStyles.textTransform = 'lowercase';
        return <Button id={props.id} variant="text" startIcon={props.icon != null && <img src={props.icon} style={{width: 16, height: 16, paddingBottom: 2}}/>} sx={{borderRadius: 20, fontWeight: 'bold', paddingLeft: 2.5, paddingRight: 2.5, textTransform: 'capitalize', ...reducedStyles}} onClick={props.onClick}>{props.value}</Button>;
    } 
    else if (props.style == "filled-inversed"){
        return <FilledInversedButton id={props.id} variant="text" startIcon={props.icon != null && <img src={props.icon} style={{width: 16, height: 16, paddingBottom: 2}}/>} sx={{borderRadius: 20, fontWeight: 'bold', paddingLeft: 2.5, paddingRight: 2.5, textTransform: 'capitalize'}} onClick={props.onClick}>{props.value}</FilledInversedButton>;
    } 
    else if (props.style == "filled-inversed-neutral"){
        return <FilledInversedNeutralButton id={props.id} variant="text" startIcon={props.icon != null && <img src={props.icon} style={{width: 16, height: 16, paddingBottom: 2}}/>} sx={{borderRadius: 20, fontWeight: 'bold', paddingLeft: 2.5, paddingRight: 2.5, textTransform: 'capitalize'}} onClick={props.onClick}>{props.value}</FilledInversedNeutralButton>;
    } 
    else if (props.style == "branded-filled"){
        return <BrandedFilledButton id={props.id} name={props.id} startIcon={props.icon != null && props.icon} sx={{width: props.width, height: props.height, borderRadius: '8px', fontFamily: "Roboto condensed", fontWeight: 'bold', fontSize: 18 * miniMultiplier, textTransform: 'capitalize', paddingTop: 0.7, ...props.sx}} onClick={props.onClick}>{props.value}</BrandedFilledButton>;
    }
    else if (props.style == "branded-white-filled"){
        return <BrandedWhiteFilledButton id={props.id} name={props.id} startIcon={props.icon != null && props.icon} sx={{width: props.width, height: props.height, borderRadius: '8px', fontFamily: "Roboto condensed", fontWeight: 'bold', fontSize: 18 * miniMultiplier, textTransform: 'capitalize', paddingTop: 0.7, ...props.sx}} onClick={props.onClick}>{props.value}</BrandedWhiteFilledButton>;
    } 
        
}

export default MaterialButton;