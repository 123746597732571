import React, {useState, useEffect, useContext} from 'react';
import Autocomplete from "react-google-autocomplete";
import { getFirestore, doc, updateDoc, deleteField, arrayUnion, arrayRemove, getDoc, runTransaction} from "firebase/firestore";
import { useTranslation } from 'react-i18next';
import { printObj, validURL } from '../player/Utils';
import {MakerContext} from "./MakerContext";
import MaterialButton from './component/MaterialButton';
import TextField from '@mui/material/TextField';
import { useMediaQuery } from 'react-responsive';
import MobileMenu from './component/MobileMenu';
import { useNavigate } from "react-router-dom";
import {AppContext} from '../AppContext';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { analyticsManager } from '..';
import { resetStoryInDB } from './MenuController';
import { getRestaurantAlias, updateStoryOrGoToStories } from './MakerController';
import LoginDialog from './LoginDialog';
import LineBreak from './component/LineBreak';
import MobileBottomBar from './component/MobileBottomBar';
import * as Sentry from "@sentry/react";

// for some reason story is not accessible from the Autocomplete callback, hack to access the story id
var chooseRestaurantStoryId = null;

function ChooseRestaurantForm(props) { 

    const [restaurantNameAddress, setRestaurantNameAddress] = useState("");
    const [restaurantName, setRestaurantName] = useState("");
    const [restaurantPhone, setRestaurantPhone] = useState("");
    const [dialogRestaurantPhone, setDialogRestaurantPhone] = useState("");
    const [url, setUrl] = useState(null);
    const [reservable, setReservable] = useState(null);
    const [showLinkBooking, setShowLinkBooking] = useState(false);
    const [showInfoPreview, setShowInfoPreview] = useState(false);    

    const story = useContext(MakerContext).get("story");
    
    const storyId = useContext(MakerContext).get("storyId");
    const setActiveForm = useContext(MakerContext).get("setActiveForm");
    const setHighlight = useContext(MakerContext).get("setHighlight");      
    const fbResponse = useContext(AppContext).get("fbResponse");
    const navigate = useNavigate();

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    const { t } = useTranslation();
    
    chooseRestaurantStoryId = story != null ? storyId : null;

    async function updatePosId(place){          
        await resetStoryInDB(chooseRestaurantStoryId, place.place_id);
        setTimeout(()=>{setHighlight("restaurantInfos book share contact hours reviews location")}, 1500);     
    } 
    
    useEffect(() => {
        analyticsManager.logMakerScreenViewTag();
    }, []);       

    useEffect(() => {           
        if (story != null && story.posId != null && restaurantName === "" && document.getElementById("autocompleater") != null && props.show)
            initAutocomplete(story);         
      }, [story && props.show, story && story.posId]);    

    function initAutocomplete(story){        
                // TODO make php api works on localhost
        fetch("https://upreality.store/php/mapsapi.php?fields=formatted_address%2Cname%2Caddress_components%2Cformatted_phone_number%2Curl%2Creservable&place_id=" + story.posId)
            .then(response => response.json())
            .then(data => {                                               
                if (data.status === "OK") {
                    const place = data.result;   
                                        
                    setRestaurantNameAddress(place.name + " " + place.formatted_address);            
                    setRestaurantName(place.name);                
                    if (place.formatted_phone_number != null && place.formatted_phone_number.length !== 0 && story.posPhone == null){                        
                        setRestaurantPhone(place.formatted_phone_number);
                    } 
                    else if (story.posPhone != null && document.getElementById("restaurantPhone") != null){
                        document.getElementById("restaurantPhone").value = story.posPhone; 
                    }                     
                    setUrl(place.url);
                    setReservable(place.reservable); 
                    // Only the first call     
                    if (story.posName == null){                        
                        const citySearch = place.address_components.find((component)=>component.types.includes("locality"));
                        const city = citySearch != null ? citySearch.short_name : "";
                        const countrySearch = place.address_components.find((component)=>component.types.includes("country"));
                        const country = countrySearch != null ? countrySearch.short_name : "";
                        analyticsManager.logChooseRestaurantTag(storyId, city, country, place.reservable);     

                        updateStoryFromPlace(place.name, place.reservable, city, country);
                        
                        if (isTabletOrMobile && fbResponse != null && fbResponse.isAnonymous)
                            setTimeout(()=>setShowInfoPreview(true), 2000);
                    }
                }
            })
            .catch((error) => {                
                Sentry.captureMessage(printObj(error));                
                });
    }

    async function handleOnFocusOut(e){
        const db = getFirestore();        
        const docRef = doc(db, "stories", chooseRestaurantStoryId);
        const update = {
            savedTime: new Date().getTime(),
        };        

        switch(e.target.id) {
            case "restaurantType":
                if (e.target.value === story.posType) return;
                update.posType = e.target.value;
                analyticsManager.logChooseBusinessTypeTag(storyId, e.target.value, e.target.value !== "");
                break;
            case "restaurantArea":
                if (e.target.value === story.posArea) return;
                update.posArea = e.target.value;
                analyticsManager.logChooseNeighborhoodTag(storyId, e.target.value, e.target.value !== "");
                break;
            case "restaurantPhone":
                if (e.target.value === story.posPhone) return;
                //if (e.target.value != e.target.defaultValue){
                    update.posPhone = e.target.value;
                    analyticsManager.logChoosePhoneNumberTag(storyId, "choose_restaurant_form", e.target.value !== "");
                //}
                break;
        }

        await updateDoc(docRef, update);

        if (e.relatedTarget != null && e.relatedTarget.id === "theme-btn"){
            analyticsManager.logMyRestaurantScreenViewTag(storyId);
            setActiveForm("my-restaurant");
        }
    }

    function showInputPhone(){         
        if (story != null && restaurantPhone != "" && story.posPhone == null){            
            return (
                <TextField id='restaurantPhone' type='text' style={{width: '100%'}} disabled value={restaurantPhone} />
            );
        }
        else {
            return (
                <TextField id='restaurantPhone' type='text' style={{width: '100%'}} defaultValue={story.posPhone != null ? story.posPhone : ""} onBlur={(e)=>{handleOnFocusOut(e);setTimeout(()=>setHighlight("book"), 1000);}} />
            );
        }   
    }

    async function updateStoryFromPlace(restaurantName, reservable, city, country){
        const restaurantAlias = await getRestaurantAlias(restaurantName, city, story, storyId);
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);
        const previousAlias = story.alias != null ? story.alias : (story.baseStoryId != null ? story.baseStoryId : storyId);
        const update = {    
            posName: restaurantName, 
            city: city,
            country: country,
            alias: restaurantAlias,    
            aliasLowerCase: restaurantAlias.toLowerCase(),
            previousAlias: previousAlias,
            previousAliasLowerCase: previousAlias.toLowerCase(),
            storyLink: process.env.REACT_APP_HOST + "/p/" + restaurantAlias,     
            posBookingByPhone: reservable != null && reservable === true,
            savedTime: new Date().getTime(),
        };              
        if (reservable)                 
            update.posBookingLink = "googlemaps";

        await updateDoc(docRef, update);
    }        

    function showFormOrLogin(){               
        if ((fbResponse == null || fbResponse.isAnonymous) && (story == null || story.posId == null)) {            

            return (            
                <>
                    <div style={{marginTop: 20, textAlign: 'center'}}> 
                        <img src="/images/arc_arrow.png" width="160px"/>                                      
                </div>
                </>
            );                        
        }
        else if (story != null && story.posId != null){
            const screenWidthToMakerForm = isTabletOrMobile ? 0 : 360;
            const isMobileBottomBarDisplayed = isTabletOrMobile && fbResponse != null && fbResponse.isAnonymous != null && ! fbResponse.isAnonymous;

            return (
                <div>                        
                    <div style={{fontSize: 20, marginTop: 20}}>{t('YourInfos')} </div>  
                    <br/>                
                    <TextField id='restaurantName' type='text' style={{width: '100%'}} value={restaurantName} disabled /><br/><br/>
                    <TextField id='restaurantType' type='text' style={{width: '100%'}} autoFocus={story.posType == null || story.posType === "" ? true : false} defaultValue={story.posType != null ? story.posType : ""} placeholder={t('PlaceholderRestaurantType')} onBlur={(e)=>{setTimeout(()=>setHighlight("address"), 1000);handleOnFocusOut(e);}}/><br/><br/>
                    <TextField id='restaurantArea' type='text' style={{width: '100%'}} defaultValue={story.posArea != null ? story.posArea : ""} placeholder={t('PlaceholderRestaurantArea')} onBlur={(e)=>{setTimeout(()=>setHighlight("address"), 1000);handleOnFocusOut(e);}} /><br/><br/>                    
                    {showInputPhone()}<br/><br/>                                        
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <MaterialButton lowercase={true} style="text" onClick={() => onLinkBookingClick()} value={t('LinkBooking')}/>
                    </div>
                    <MobileMenu storyId={storyId} story={story}/>                    
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', bottom: 16 + (isMobileBottomBarDisplayed ? 56 : 0), right: screenWidthToMakerForm+16, position: 'absolute'}}>
                        <MaterialButton id="theme-btn" style="filled-tonal" onClick={() => {analyticsManager.logMyRestaurantScreenViewTag(storyId);setActiveForm("my-restaurant");}} value={t('ChooseRestaurantToTheme')}/>
                    </div>                       
                </div>
            );                     
        }
        else return null;
    } 
    
    async function onLinkBookingClick(){
        const phoneInput = document.getElementById("restaurantPhone");
        if (phoneInput.disabled != true && phoneInput.value.trim() !== "" && phoneInput.value != story.posPhone){
            const db = getFirestore();        
            const docRef = doc(db, "stories", storyId); 
            const update = {
                posPhone: phoneInput.value,
                savedTime: new Date().getTime(),
            };
            await updateDoc(docRef, update);
        }
        analyticsManager.logOpenPopupTag(storyId, "booking_link", true);
        setShowLinkBooking(true);
        setDialogRestaurantPhone("");
    }

    async function onLogin(user){              
        updateStoryOrGoToStories(user, storyId, story)
            .then((navigateToLogged)=>{
                if (navigateToLogged) 
                    navigate("/logged");                 
                });
    }

    function showAlertModifySnack(){
        if (props.firstDisplayWarning){            
            return (
                <Snackbar open={props.firstDisplayWarning} autoHideDuration={6000} onClose={()=>props.setFirstDisplayWarning(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}  sx={{ width: "90%" }}>
                    <Alert onClose={()=>props.setFirstDisplayWarning(false)} severity="warning" sx={{ width: '100%' }}>     
                    {t('ModifyStoryWarning')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    }

    function showInfoPreviewSnack(){
        if (showInfoPreview){            
            return (
                <Snackbar open={showInfoPreview} autoHideDuration={6000} onClose={()=>setShowInfoPreview(false)} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                    <Alert onClose={()=>setShowInfoPreview(false)} severity="info">     
                    {t('InfoPreview')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    }

    function showLinkBookingDialog(){

        async function close(){
            if (story.posBookingLink == "googlemaps" || document.getElementById("booking-link") != null && validURL(document.getElementById("booking-link").value) || document.getElementById("booking-link").value.trim() === ""){                                            
                analyticsManager.logOpenPopupTag(storyId, "booking_link", false);
                let unvalidatedOrderingLink = false;
                if (story.orderingPlatforms != null){
                    for (let i = 1; i < story.orderingPlatforms.length+1; i++){                        
                        if (!validURL(document.getElementById("ordering-link-" + i).value) && document.getElementById("ordering-link-" + i).value.trim() !== ""){
                            unvalidatedOrderingLink = true;
                            break;
                        }
                    }
                    
                    if (!unvalidatedOrderingLink){                         
                        for (let i = 1; i < story.orderingPlatforms.length+1; i++){
                            await addOrderingPlatform(i-1, document.getElementById("ordering-link-" + i).value);
                        }
                        if (story.orderingPlatforms.includes(""))
                            await deleteOrderingPlatform("");                                                
                    }                    
                }
                if (!unvalidatedOrderingLink){                                     
                    if (restaurantPhone === "" && document.getElementById("booking-phone") != null && document.getElementById("booking-phone").value != story.posPhone){
                        await updatePosPhone(document.getElementById("booking-phone").value);                           
                        if (document.getElementById("booking-phone").value != document.getElementById("restaurantPhone").value)
                            document.getElementById("restaurantPhone").value = document.getElementById("booking-phone").value;
                    }
                    if (document.getElementById("booking-link") != null && document.getElementById("booking-link").value != story.posBookingLink){
                        await updatePosBookingLink(document.getElementById("booking-link").value);
                    }
                }
                setShowLinkBooking(unvalidatedOrderingLink);
            }
            setDialogRestaurantPhone("");
            setTimeout(()=>{setHighlight("book")}, 1000);
        }

        async function updatePosBookingLink(link){
            const db = getFirestore();        
            const docRef = doc(db, "stories", storyId);
            let finalLink = link;
            if (finalLink === "googlemaps" && reservable === false)
                finalLink = null;
            if (finalLink != null && finalLink.trim() === "")
                finalLink = null;

            const update = {
                posBookingLink: finalLink,
                savedTime: new Date().getTime(),
            };                        
    
            await updateDoc(docRef, update);
        }

        async function updatePosPhone(phone){
            const db = getFirestore();        
            const docRef = doc(db, "stories", storyId);            

            if (phone !== "none"){
                let finalPhone = phone;
                if (finalPhone == null || finalPhone.trim() === "")
                    finalPhone = deleteField();

                const update = {
                    posPhone: finalPhone,
                    posBookingByPhone: true,
                    savedTime: new Date().getTime(),
                };                        
                await updateDoc(docRef, update);                     
                if (phone != null && phone.trim() !== "" && document.getElementById("restaurantPhone") != null)
                    document.getElementById("restaurantPhone").value = phone;
            }
            else {
                await updateDoc(docRef, {
                    posBookingByPhone: false,
                    savedTime: new Date().getTime(),
                });
            }            
        }

        function showBookingPhoneOption(){            
            if (story.posBookingLink == null && story.orderingPlatforms == null){               
                if (!story.posBookingByPhone){
                    return (
                        <>
                            <LineBreak/>
                            {t('BookingOptionPhoneOff')}                            
                            <MaterialButton lowercase={true} style="text" value={t('BookingOptionPhoneToOn')} onClick={()=>{analyticsManager.logAuthorizePhoneNumberTag(storyId, true);updatePosPhone(story.posPhone);setDialogRestaurantPhone(story.posPhone);}}/>                        
                        </>
                    );
                }
                else {
                    return (
                        <>           
                            <LineBreak/>   
                            {t('BookingOptionPhoneOn')}
                            <LineBreak/>
                            {showBookingPhoneOptionInput()}                        
                            <MaterialButton lowercase={true} style="text" value={t('BookingOptionPhoneToOff')} onClick={()=>{analyticsManager.logAuthorizePhoneNumberTag(storyId, false);updatePosPhone("none");setDialogRestaurantPhone("");}} />
                        </>
                    );
                }
            } else return null;

            function showBookingPhoneOptionInput(){                   
                if (story != null && (restaurantPhone !== "" || story.posPhone != null && story.posPhone !== "" && dialogRestaurantPhone === "")){                    
                    return (
                        <TextField id='booking-phone' type='text' style={{width: '100%'}} disabled value={restaurantPhone !== "" ? restaurantPhone : story.posPhone} />
                    );
                }
                else {                    
                    return (
                        <TextField id='booking-phone' type='text' style={{width: '100%'}} defaultValue={story.posPhone != null ? story.posPhone : ""} onBlur={(e)=>{analyticsManager.logChoosePhoneNumberTag(storyId, "booking_popup", true);updatePosPhone(e.target.value);setDialogRestaurantPhone(e.target.value);}} />
                    );
                }   
            }
        }        

        function showBookingWebOption(){            
            if (story.posBookingLink === 'googlemaps' && reservable === true){
                return (
                    <>
                        {t('BookingOptionGoogleMaps')}
                        <br/>
                        <MaterialButton lowercase={true} style="text" onClick={() => {analyticsManager.logReplaceGBookingByWebLinkTag(storyId);updatePosBookingLink(null);}} value={t('BookingReplaceByLink')} />                        
                    </>
                );
            }
            else {
                return (
                    <>
                        {t('BookingOptionLink')}<LineBreak/>
                        <TextField id='booking-link' type='url' error={story.posBookingLink != null && document.getElementById("booking-link") != null && !validURL(document.getElementById("booking-link").value)} style={{width: '100%'}} defaultValue={story.posBookingLink === 'googlemaps' || story.posBookingLink == null ? "": story.posBookingLink} onBlur={(e)=>{analyticsManager.logChooseBookingLinkTag(storyId, true);updatePosBookingLink(e.target.value)}} placeholder={t('BookingLinkHint')} />                        
                        {reservable === true && <div style={{marginTop: 8, fontSize: 12, fontStyle: 'italic'}}>{t('BookingLinkHelp')}</div>}
                        {reservable === true && <MaterialButton lowercase={true} style="text" onClick={() => {analyticsManager.logReplaceWebLinkByGBookingTag(storyId);analyticsManager.logChooseBookingLinkTag(storyId, false);updatePosBookingLink('googlemaps');}} value={t('BookingReplaceByMaps')} />}
                    </>
                );
            }
        }

        function showOrderingOption(){

            let orderingLink = null;
            if (story.orderingPlatforms != null){
                let orderingLinkCounter = 0;
                orderingLink = story.orderingPlatforms.map((orderingPlatform)=>{
                    orderingLinkCounter++;
                    return <div key={orderingLinkCounter}><br/><div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}><TextField id={"ordering-link-" + orderingLinkCounter} type='url' error={story.orderingPlatforms[orderingLinkCounter-1].trim() !== "" && !validURL(story.orderingPlatforms[orderingLinkCounter-1])} style={{width: '100%'}} defaultValue={orderingPlatform} placeholder={t('OrderingLinkHint')} onBlur={(e)=>addOrderingPlatform(orderingLinkCounter-1, e.target.value)} /><MaterialButton style="text" value="X" reducedPadding={true} onClick={()=>{analyticsManager.logAddOrderingPlatformTag(storyId, false);deleteOrderingPlatform(document.getElementById("ordering-link-" + orderingLinkCounter).value);}}/></div></div>
                });
            }

            return (<>                        
                        <div style={{fontSize: 20, width: '100%'}}>                            
                            {t('Ordering')}                            
                            {orderingLink}
                            <LineBreak/>
                            <MaterialButton style="text" value={t('OrderingAddAnotherPlatform')} onClick={(e)=>{analyticsManager.logAddOrderingPlatformTag(storyId, true);addOrderingPlatform(story.orderingPlatforms != null ? story.orderingPlatforms.length : 0, e.target.value);}} />
                        </div>
                </>
                );                        
        }

        async function addOrderingPlatform(idx, link){
            const db = getFirestore();        
            const docRef = doc(db, "stories", storyId);                            
            
            await runTransaction(db, async (transaction) => {
                if (story.orderingPlatforms != null && link.trim() !== ""){
                    const deletePrevious = {
                        orderingPlatforms: arrayRemove(story.orderingPlatforms[idx]),
                        savedTime: new Date().getTime(),
                    };                                
                    await transaction.update(docRef, deletePrevious);
                }
            
                let update = {
                    orderingPlatforms: arrayUnion(link.trim()),
                    savedTime: new Date().getTime(),
                };                    
                await transaction.update(docRef, update);
              });
                                
        }

        async function deleteOrderingPlatform(link){
            const db = getFirestore();        
            const docRef = doc(db, "stories", storyId);
            const update = {
                orderingPlatforms: arrayRemove(link),
                savedTime: new Date().getTime(),
            };                                
            await updateDoc(docRef, update);  
            const uptodateStory = (await getDoc(docRef)).data();
            if (uptodateStory.orderingPlatforms != null && uptodateStory.orderingPlatforms.length === 0){
                const update2 = {
                    orderingPlatforms: deleteField(),
                    savedTime: new Date().getTime(),
                };                                
                await updateDoc(docRef, update2);  
            }
        }

        if (showLinkBooking)
            return (
                <Dialog onClose={close} open={showLinkBooking}>
                    <DialogTitle style={{userSelect: 'none'}}>{t('LinkBooking')}</DialogTitle>  
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: "flex-start", padding: 16}}>
                        <div style={{fontSize: 20}}>{t('Booking')}</div><LineBreak/>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: "flex-start"}}>                        
                            {showBookingWebOption()}                            
                            {showBookingPhoneOption()}
                            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20, marginBottom: 20}}>
                                <div style={{width: 140, height: 1, background: '#A0A0A070'}}/>
                            </div> 
                            {showOrderingOption()}
                        </div>
                    </div>
                </Dialog>
            ); 
        else return null; 
    }
    
    if (props.show) {
        return(
            <div>
                <div style={{padding: 16}}>
                    <div style={{fontSize: 28}}>
                        {t('ChooseRestaurant')} 
                        <br/>
                        {(fbResponse == null || fbResponse.isAnonymous) && (story == null || story.posId == null) && 
                            <>                                
                                <div style={{fontSize: 14, fontStyle: 'italic', marginTop: 20}}>{t('PlaceholderAutocompleaterHelp')}</div>                                
                            </>
                        }
                        <Autocomplete         
                            id="autocompleater"           
                            defaultValue={restaurantNameAddress}
                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            onPlaceSelected={(place) => updatePosId(place)}
                            style={{width: '100%' }}
                            placeholder={t('PlaceholderAutocompleater')}
                            options={{
                                types: ["restaurant"], componentRestrictions: {country: "fr"}
                            }}  
                            disabled={story != null && story.posId != null}                      
                            />
                    </div>                
                    {showFormOrLogin()}   
                    {showLinkBookingDialog()}  
                    {showAlertModifySnack()}    
                    {showInfoPreviewSnack()}                
                </div>                
                <MobileBottomBar currentTab="0" storyId={storyId} />
            </div>
        );
    }
    else return null;
}

export default ChooseRestaurantForm;