import './../../Player.css';

import React,{} from 'react';
import StickerGallery, {insideType} from './StickerGallery';
import StreetViewTour from './StreetViewTour';

function StickerInside(props){
    
    if (props.story.panoId == null && props.story.tourMediasRef == null) return (null);

    if (props.story.panoId != null){

      return (
        <StreetViewTour selectedItem={props.selectedItem} placeDetails={props.placeDetails} story={props.story} setFullScreen={props.setFullScreen}/>
      );  
    }
    else {

      return (
        <StickerGallery selectedItem={props.selectedItem} storyId={props.storyId} story={props.story} placeDetails={props.placeDetails} mediasType={insideType} medias={props.story.tourMediasRef} setFullScreen={props.setFullScreen} fromMaker={props.fromMaker}/>                          
      );
    }

}

export default StickerInside;