
import { DialogActions } from '@mui/material';
import { DialogContent } from '@mui/material';
import { DialogTitle } from '@mui/material';
import { Dialog } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
import MaterialButton from './MaterialButton';
import { analyticsManager } from '../..';
import { MakerContext } from '../MakerContext';

function ConfirmDialog(props) {
    // TODO cancel should send a specific tag , would need to add a new props to the function
    const makerContext = useContext(MakerContext);
    const storyId = makerContext != null ? makerContext.get("storyId") : null;
    const { t } = useTranslation();

    function close(){
        if (props.showProgress) return;
        
        analyticsManager.logOpenPopupTag(storyId, props.tagName, false);
        props.setShowConfirm(false);
    }

    function showProgressDialog(){                
        if (props.showProgress){
            return (
                <CircularProgress style={{transform: 'translate(-50%, -50%)', position: 'absolute', top: '50%', left: '40%' }} sx={{color: '#2FC4B5', }}/>
            );
        }else return null;
    }

    if (props.showConfirm){
        return (
            <Dialog onClose={close} open={props.showConfirm}>
                <DialogTitle style={{userSelect: 'none'}}>{props.dialogTitle}</DialogTitle>
                <div style={{visibility: props.showProgress ? 'hidden' : 'visible'}}>
                    <DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 16,}}>
                        {props.dialogText}
                    </DialogContent>
                    <DialogActions style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignContent: 'flex-end', alignItems: 'flex-end', padding: 16,}}>
                        <MaterialButton style="text" value={t('Cancel')} onClick={close}/>
                        <MaterialButton style="text" value={props.dialogAction} onClick={props.onSuccess}/>
                    </DialogActions>
                </div>  
                {showProgressDialog()}
            </Dialog>
        );
    } else return null;
}

export default ConfirmDialog;