import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { MakerContext } from '../MakerContext';
import { useNavigate } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ListIcon from '@mui/icons-material/List';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { AppContext } from '../../AppContext';
import { useContext, useState } from 'react';
import { checkMobile, getWindowDimensions } from '../../player/Utils';
import { analyticsManager } from '../..';
import { LoggedContext } from '../../logged/LoggedContext';
import { useEffect } from 'react';

export default function MobileBottomBar(props){
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    let setActivePage = null;
    const loggedContext = React.useContext(LoggedContext);
    if (loggedContext != null){ 
        setActivePage = loggedContext.get("setActivePage");        
    }

    const navigate = useNavigate();
    const { t } = useTranslation(); 
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const fbResponse = React.useContext(AppContext).get("fbResponse");

    useEffect(() => {
        function handleOrientationChange(e){      
          setTimeout(() => {setWindowDimensions(getWindowDimensions());}, 200);
        }    
        if (! checkMobile()) return;    

        if (window.screen != null && window.screen.orientation != null)
            window.screen.orientation.addEventListener("change", handleOrientationChange);    
        return () => {      
            if (window.screen != null && window.screen.orientation != null)
                window.screen.orientation.removeEventListener('change', handleOrientationChange);
        };        
    }, []);
    
    if (isTabletOrMobile && fbResponse != null && fbResponse.isAnonymous != null && ! fbResponse.isAnonymous){
        return (
            <div style={{position: 'absolute', background: 'white', bottom: 0, height: 56, width: windowDimensions.width, display: 'flex', flexDirection: 'row', boxShadow: '0 -1px 1px 0 rgba(0, 0, 0, 0.2)'}}>
                <div style={{position: 'absolute', left: 0, width: (windowDimensions.width-32)/3, height: 56,}} onClick={()=>{analyticsManager.logOpenTabTag(props.storyId, "maker");document.getElementById("bottom-tab-0").click();navigate("/maker");}}>
                    <div id="bottom-tab-0" style={{position: 'absolute', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', userSelect: 'none'}}>
                        <IconButton sx={{width: '100%'}} aria-label="delete" color={props.currentTab === "0" ? "primary":""}>                
                            <AddCircleOutlineIcon sx={{paddingBottom: 2}} />
                        </IconButton>
                    </div>
                    <div style={{position: 'absolute', bottom: 8, color: props.currentTab === "0" ? "#2FC4B5":"#666666", fontSize: 12, width: '100%', textAlign: 'center'}}>
                        {t('TabMaker')}
                    </div>
                </div>
                <div style={{position: 'absolute', left: (windowDimensions.width-32)/3, width: (windowDimensions.width-32)/3, height: 56,}} onClick={()=>{analyticsManager.logOpenTabTag(props.storyId, "my-stories");document.getElementById("bottom-tab-1").click();if (setActivePage == null) navigate("/logged"); else setActivePage("list-stories");}}>
                    <div id="bottom-tab-1" style={{position: 'absolute', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <IconButton sx={{width: '100%'}} aria-label="delete" color={props.currentTab === "1" ? "primary":""}>                
                            <ListIcon sx={{paddingBottom: 2}} />
                        </IconButton>
                    </div>
                    <div style={{position: 'absolute', bottom: 8, color: props.currentTab === "1" ? "#2FC4B5":"#666666", fontSize: 12, width: '100%', textAlign: 'center'}}>
                        {t('TabStories')}
                    </div>
                </div>
                <div style={{position: 'absolute', left: (windowDimensions.width-32)*2/3, width: (windowDimensions.width-32)/3, height: 56,}} onClick={()=>{analyticsManager.logOpenTabTag(props.storyId, "settings");document.getElementById("bottom-tab-2").click();if (setActivePage == null) navigate("/logged/preferences"); else setActivePage("preferences");}}>
                    <div id="bottom-tab-2" style={{position: 'absolute', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <IconButton sx={{width: '100%'}} aria-label="delete" color={props.currentTab === "2" ? "primary":""}>                
                            <SettingsIcon sx={{paddingBottom: 2}} />
                        </IconButton>
                    </div>
                    <div style={{position: 'absolute', bottom: 8, color: props.currentTab === "2" ? "#2FC4B5":"#666666", fontSize: 12, width: '100%', textAlign: 'center'}}>
                    {t('TabAccount')}
                    </div>
                </div>
            </div>
        );
    }
    else return null;
}