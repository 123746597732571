import React, {useState, useEffect, useContext} from 'react';
import {MakerContext} from "./MakerContext";
import ChooseRestaurantForm from "./ChooseRestaurantForm";
import MyRestaurantForm from "./MyRestaurantForm";
import StoreFrontForm from './StoreFrontForm';
import ChooseMenuForm from './ChooseMenuForm';
import UseIt from './UseIt';

function MakerForm(props) {
    
    const activeForm = useContext(MakerContext).get("activeForm"); 
    
    const showChooseRestaurant = activeForm === "choose-restaurant";
    const showMyRestaurant = activeForm === "my-restaurant";
    const showStoreFront = activeForm === "store-front";
    const showChooseMenu = activeForm === "choose-menu";
    const showUseIt = activeForm === "use-it";

    return(
        <div style={{visibility: props.previewDisplayed != null && props.previewDisplayed ? 'hidden' : 'visible'}}>
            <ChooseRestaurantForm show={showChooseRestaurant} firstDisplayWarning={props.firstDisplayWarning} setFirstDisplayWarning={props.setFirstDisplayWarning} />
            <MyRestaurantForm show={showMyRestaurant} />
            <StoreFrontForm show={showStoreFront} />
            <ChooseMenuForm show={showChooseMenu} />
            <UseIt show={showUseIt} />
        </div>
    );
}

export default MakerForm;