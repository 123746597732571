import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ShareIcon from '@mui/icons-material/Share';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MapIcon from '@mui/icons-material/Map';
import DeleteIcon from '@mui/icons-material/Delete';
import MaterialButton from '../maker/component/MaterialButton';
import { useNavigate } from "react-router-dom";
import React, {useState, useContext, useEffect} from 'react';
import { useMediaQuery } from 'react-responsive';
import * as Sentry from "@sentry/react";
import { LoggedContext } from './LoggedContext';
import ConfirmDialog from '../maker/component/ConfirmDialog';
import { Alert, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { browserLocales, checkMobile, getTextWidth, isAndroid } from '../player/Utils';
import { analyticsManager } from '..';
import { AppContext } from '../AppContext';
import { deleteStoryVersions, isStoryReadyForEdition } from './StatsController';

function Stats(props) {
    const windowDimensions = useContext(LoggedContext).get("windowDimensions");
    const makerDesktopHeight = useContext(AppContext).get("makerDesktopHeight");
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const [showProgress, setShowProgress] = useState(false);  
    const navigate = useNavigate();
    const story = props.selectedStory;
    let imageHeight = makerDesktopHeight * 0.4;
    if (isTabletOrMobile && windowDimensions != null) {
        imageHeight = windowDimensions.height * 0.4;    
    }   
    const [showAckLinkCopied, setShowAckLinkCopied] = useState(false);
    const [showWarningDownsizeRunning, setShowWarningDownsizeRunning] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (story != null)
         analyticsManager.logStatDetailScreenViewTag(story.id);
    }, [story]);

    function showThumbnail(){
        if (story.imageRef != null){
            return (
                <img alt="thumbnail of the story" src={story.imageRef} alt={"TODO"} style={{width: '100%', height: imageHeight, objectFit: 'cover', borderTopRightRadius: !checkMobile() ? 10 : 0,}}/>
            );
        }
        else if (story.videoRef != null){
            if (story.videoFSTnRef != null)
                return (
                    <img alt="thumbnail of the story" src={story.videoFSTnRef} alt={"TODO"} style={{width: '100%', height: imageHeight, objectFit: 'cover', borderTopRightRadius: !checkMobile() ? 10 : 0,}}/>
                );
            else 
                return (
                    <video alt="thumbnail of the story" muted style={{width: '100%', height: imageHeight, objectFit: 'cover', userSelect: 'none', pointerEvents: 'none', borderTopRightRadius: !checkMobile() ? 10 : 0,}}>
                        <source src={story.videoRef} />
                    </video>
                );
        }
        else {
            return (
                <img alt="thumbnail of the story" src="/images/placeholder_storefront.jpg" alt={"TODO"} style={{width: '100%', height: imageHeight, objectFit: 'cover', borderTopRightRadius: !checkMobile() ? 10 : 0,}}/>
            );
        }
    }    
    
    function showAckLinkCopiedSnack(){
        if (showAckLinkCopied){
            return (
                <Snackbar open={showAckLinkCopied} autoHideDuration={2000} onClose={()=>setShowAckLinkCopied(false)} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                    <Alert onClose={()=>setShowAckLinkCopied(false)} severity="success" sx={{ width: '100%' }}>     
                    {t('AckLinkCopied')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    } 

    function showWarningDownsizeRunningSnack(){
        if (showWarningDownsizeRunning){
            return (
                <Snackbar open={showWarningDownsizeRunning} autoHideDuration={8000} onClose={()=>setShowWarningDownsizeRunning(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <Alert onClose={()=>setShowWarningDownsizeRunning(false)} severity="warning" sx={{ width: '100%' }}>     
                    {t('WarningDownsizeRunning')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    } 

    async function modifyStory(){
        if (await isStoryReadyForEdition(story.id) || checkMobile()){            
            analyticsManager.logModifyStoryScreenViewTag(story.id);
            navigate("/maker/" + story.id);
        }
        else {
            analyticsManager.logModifyStoryScreenViewTag(story.id, true);
            setShowWarningDownsizeRunning(true);
        }
    }

    if (props.selectedStory != null){

        let divWidth = 360;
        if (isTabletOrMobile && windowDimensions != null) {
            divWidth = windowDimensions.width;
        }
        const nameOn2lines = getTextWidth(story.posName, "26px Roboto") > divWidth - 2 * 16;        
        const spaceBetweenStatLines = isTabletOrMobile ? (nameOn2lines ? 20 : 30) : ((nameOn2lines ? 30 : 40)/(makerDesktopHeight > 740 ? 1 : 1.7));   
        
        let bookOverContact = true;
        if ((story.posBookingLink == null || story.posBookingLink === "none") && story.orderingPlatforms == null && !story.posBookingByPhone)
            bookOverContact = false;

        //TODO avatarUrl <-> TN
        return (
            <div style={{position: 'absolute', zIndex: 30, width: divWidth, height: '100%', background: '#FFFFFF', display: 'flex', flexDirection: 'column', borderTopRightRadius: !checkMobile() ? 10 : 0, borderBottomRightRadius: !checkMobile() ? 10 : 0}}>
                {showThumbnail()}     
                <div style={{width: 1, height: makerDesktopHeight * 0.6, backgroundColor: '#b2ede7', position: 'absolute', bottom: 0}} />           
                <div style={{padding: 16, }}>
                    <div className='stat-restaurant-names' style={{fontSize: 26,}}>{story.posName}</div>                    
                    <div style={{display: 'flex', flexDirection: 'row', fontSize: 22, alignItems: 'flex-end',}}><div>{t('Interest')}{story.rating != null ? story.rating.toFixed(1) : 0}</div><div style={{marginBottom: 1}}> ⭐</div></div>                                        
                    <div style={{marginTop: spaceBetweenStatLines, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                        <div style={{width: 164, display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 2,}}>
                            <VisibilityIcon style={{ width: 24, height: 24, marginRight: 8}}/>{(story.viewNb != null ? story.viewNb : 0) + t('XUniqueViews')}
                        </div>
                        <div style={{width: 164, display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 2}}>
                            <AccessTimeIcon style={{ width: 24, height: 24, marginRight: 8}}/> {(story.duration != null ? story.duration / ((story.viewNb != null ? story.viewNb : 0) + (story.returnNb != null ? story.returnNb : 0)) : 0).toFixed(2) + t('XAvgDurationTime')}
                        </div>   
                    </div>                    
                    <div style={{marginTop: spaceBetweenStatLines, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                        <div style={{width: 164, display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 2}}>
                            <ShareIcon style={{ width: 24, height: 24, marginRight: 8}}/>{(story.shares != null ? story.shares : 0) + t('XShares')}
                        </div>
                        <div style={{width: 164, display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 2}}>
                            <LocalPhoneIcon style={{ width: 24, height: 24, marginRight: 8}}/> {(story.bookingIntentNb != null ? story.bookingIntentNb : 0) + (bookOverContact ? t('XBookingIntent') : t('XContact'))}
                        </div>   
                    </div>                    
                    <div style={{marginTop: spaceBetweenStatLines, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                        <div style={{width: 164, display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 2}}>
                            <MapIcon style={{ width: 24, height: 24, marginRight: 8}}/>{(story.itineraryNb != null ? story.itineraryNb : 0) + t('XItineraries')}
                        </div>
                        <div style={{width: 164, display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 2}}>
                            <VisibilityIcon style={{ width: 24, height: 24, marginRight: 8}}/> {(story.returnNb != null ? story.returnNb : 0) + t('XReturns')}
                        </div>   
                    </div>
                    <div style={{marginTop: spaceBetweenStatLines, width: '100%', fontSize: 12, fontStyle: 'italic', textAlign: 'end'}}>{t('PublicationDate') + (story.publicationDate != null ? new Date(story.publicationDate).toLocaleString(browserLocales(), new Date().getTimezoneOffset()) : t('NotPublished'))}</div>                            

                    <div style={{position: 'absolute', bottom: 16, right: 16, textAlign: 'end'}}>                        
                        <MaterialButton style="text" value={t('Modify')} onClick={()=>modifyStory()}/>
                        <MaterialButton style="text" value={t('Launch')} onClick={()=>{analyticsManager.logLaunchStoryScreenViewTag(story.id);window.open(story.storyLink, "_blank");}} /><div style={{fontSize: 8}}><br/></div>
                        <MaterialButton style="filled" value={t('CopyWebLink')} onClick={()=>{{analyticsManager.logCopyLinkTag(story.id, "stat");navigator.clipboard.writeText(story.storyLink);setShowAckLinkCopied(true);}}} />
                    </div>
                    <IconButton style={{position: 'absolute', width: 40, height: 40, bottom: 12, left: 16, }} onClick={()=>{analyticsManager.logOpenPopupTag(story.id, "delete_story", true);setShowDeleteConfirm(true);}}>
                        <DeleteIcon style={{color: '#2FC4B5', width: 32, height:32, }} />
                    </IconButton>

                </div>
                <IconButton style={{position: 'absolute', width: 48, height: 48, top: 8, right: 8, }} onClick={() => {analyticsManager.logCloseDetailScreenViewTag(story.id);props.setSelectedStory(null);}}>
                    <CloseIcon style={{color: '#2FC4B5', width: 48, height:48, }} />
                </IconButton>
                {showDeleteConfirm && <ConfirmDialog showConfirm={showDeleteConfirm} setShowConfirm={setShowDeleteConfirm} showProgress={showProgress} setShowProgress={setShowProgress} dialogTitle={t('DeleteStory')} dialogText={t('DeleteStoryWarning')} dialogAction={t('Delete')} tagName="delete_story" onSuccess={()=>{
                    analyticsManager.logDeleteStoryTag(story.id, true);
                    setShowProgress(true);
                    deleteStoryVersions(story.id)
                        .then(()=>window.location.reload())
                        .catch((e)=>{
                            setShowProgress(false);
                            Sentry.captureMessage("Delete story , storyId " + story.id + " exception " + e, "error");
                        });                        
                    }} />}
                {showAckLinkCopiedSnack()}
                {showWarningDownsizeRunningSnack()}
            </div>
        );
        // TODO do delete without reload (eg story list with refresh subscription), delete event has not the time to be send to GA
    }
    else return null;
}

export default Stats;