import { useNavigate } from "react-router-dom";
import WebPageMock from "./player/side_menu/components/WebPageMock";
import React, { useEffect, useState } from "react";
import { getWindowDimensions, openFullscreen } from "./player/Utils";
import MaterialButton from "./maker/component/MaterialButton";
import { useTranslation } from "react-i18next";

function DemoInstantAd(props) {
    const navigate = useNavigate();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions()); 

    const audioRef = React.createRef();
    const [playAudio, setPlayAudio] = useState(false);
         
    const [showOverlay, setShowOverlay] = useState(true);
    const { t } = useTranslation();  

    useEffect(() => {    
        if (playAudio && audioRef.current != null && audioRef.current.paused){
            audioRef.current.play();
            setPlayAudio(false);
        }
    }, [playAudio]);

    return (
        <div>            
            <WebPageMock id="web_demo" open={true} url={"/images/demo_instant_ad.jpg"} width={windowDimensions.width} height={windowDimensions.height} imageOverWebsite={true} imgStyle={{objectFit: "contain", backgroundColor: 'black'}} close={()=>{if (! showOverlay) navigate("/p/CafeUpReality?fromDemoInstantAd=true");}} />
            <audio ref={audioRef} loop src={"/music/music-spring.mp3"}/>
            {showOverlay &&
            <>
                <div className="BlockingOverlay" style={{zIndex: 99, width: windowDimensions.width, height: windowDimensions.height, }} />            
                <div style={{position: "absolute", zIndex: 100, width: windowDimensions.width, height: windowDimensions.height, display: "flex", flexDirection: "column", alignItems: "center", alignContent: "center", justifyContent: "center",}}>
                    <MaterialButton style="filled" onClick={()=>{openFullscreen();setPlayAudio(true);setShowOverlay(false);}} value={t("LaunchDemoInstantAd")} />
                </div>
            </>        
            }
        </div>
    );
}

export default DemoInstantAd;