import './Maker.css';
import '../player/Player.css';
import React, {useState, useContext, useEffect} from 'react';
import {MakerContext} from "./MakerContext";
import { useTranslation } from 'react-i18next';
import { getFirestore, doc, updateDoc, deleteField} from "firebase/firestore";
import MaterialButton from './component/MaterialButton';
import ContentPickerDialog from './component/ContentPickerDialog';
import { useMediaQuery } from 'react-responsive';
import MobileMenu from './component/MobileMenu';
import {CHECK_MEDIA_CONTENT_REPLACE, MEDIA_TYPE_AVATAR, MEDIA_TYPE_MUSIC, MEDIA_TYPE_PROFILE, checkMediaContent, cleanMakerQueryString, getAvatarFromInstagram, saveAvatarUrl, saveInstagramUserName, saveMusicUrl, uploadMediaContent} from './MakerController';
import { deleteStoryMediaContent } from './MakerController';
import { getFileNameFromUrl, getTextWidth, printObj, reduceMusicFileName } from '../player/Utils';
import { Alert, Snackbar, TextField } from '@mui/material';
import LoginDialog from './LoginDialog';
import { analyticsManager, functions } from '..';
import * as Sentry from "@sentry/react";
import MobileBottomBar from './component/MobileBottomBar';
import { AppContext } from '../AppContext';
import ShareIcon from '@mui/icons-material/Share';
import ColorPicker from './component/ColorPicker';
import { httpsCallable } from "firebase/functions";

function MyRestaurantForm(props) {
    const setInstagramAccountId = useContext(MakerContext).get("setInstagramAccountId");  
    const instagramLoginType = useContext(MakerContext).get("instagramLoginType");
    const setInstagramLoginType = useContext(MakerContext).get("setInstagramLoginType");      
    const fbAccessToken = useContext(MakerContext).get("fbAccessToken");    
    const setFbAccessToken = useContext(MakerContext).get("setFbAccessToken");
    const [showLogoPicker, setShowLogoPicker] = useState(false);
    const story = useContext(MakerContext).get("story");
    const storyId = useContext(MakerContext).get("storyId");
    const baseStory = useContext(MakerContext).get("baseStory");
    const setActiveForm = useContext(MakerContext).get("setActiveForm");
    const activePopup = useContext(MakerContext).get("activePopup");
    const setActivePopup = useContext(MakerContext).get("setActivePopup");
    const setHighlight = useContext(MakerContext).get("setHighlight");
    const windowDimensions = useContext(MakerContext).get("windowDimensions");
    const [showIGConnectedSuccess, setShowIGConnectedSuccess] = useState(false);
    const setResetAudio = useContext(MakerContext).get("setResetAudio");

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const fbResponse = useContext(AppContext).get("fbResponse");

    const [bypassConnectToIGForProfile, setBypassConnectToIGForProfile] = useState(false); 
    const [bypassConnectToIGForAvatar, setBypassConnectToIGForAvatar] = useState(false); 

    const { t } = useTranslation();

    useEffect(() => {
        if ((activePopup === MEDIA_TYPE_PROFILE || activePopup === MEDIA_TYPE_AVATAR) && props.show){    
            if (activePopup === MEDIA_TYPE_AVATAR){
                setShowLogoPicker(MEDIA_TYPE_AVATAR);
                setBypassConnectToIGForAvatar(true);
            } 
            else if (activePopup === MEDIA_TYPE_PROFILE){
                setBypassConnectToIGForProfile(true);
            }          
            setActivePopup(null);
            if (instagramLoginType === "ig")
                cleanMakerQueryString(story.baseStoryId != null ? story.baseStoryId : storyId);
        }
      }, [activePopup && props.show]);

    function showInstagramProfile(){

        function disconnectInstagramProfile(){
            setInstagramAccountId(null);
            setFbAccessToken(null);
            setInstagramLoginType(null);

            const db = getFirestore();        
            const docRef = doc(db, "stories", storyId);
            const update = {
                instagramProfileName: deleteField(),
                instagramProfileUserName: deleteField(),
                posChatLink: deleteField(),
                savedTime: new Date().getTime(),
            };                        

            updateDoc(docRef, update);
        }        

        if (story.instagramProfileUserName != null)
            return <>
                <div style={{display: 'flex', flexDirection: "row", alignItems: 'center', alignContent: 'center'}}>                    
                    <img alt="instagram logo" src="/images/ic_instagram_color.png" style={{width: 20, height: 20, marginRight: 8}}/>
                    <span style={{fontSize: 12, fontStyle: 'italic', marginRight: 6}}>{t('IGProfileConnected', {name: story.instagramProfileName})}</span>
                    <MaterialButton style="text" onClick={() => {analyticsManager.logConnectInstagramTag(storyId, false);disconnectInstagramProfile();}} value="X"/>                    
                </div>
                <br/>
            </>;
        else
            return <>
                    <LoginDialog origin="connect_instagram" title={t('ConnectInstagram')} buttonText={t('ConnectInstagram')} loginForContent={true} mediaContentType={MEDIA_TYPE_PROFILE} bypassFromIG={bypassConnectToIGForProfile} resetBypassFromIg={setBypassConnectToIGForProfile} onPageSelected={onInstagramConnected} />                    
                    <br/>
                </>;
    }

    function onInstagramConnected(igAccountId, instagramLoginTypeFromWeb = null){ 
        analyticsManager.logConnectInstagramTag(storyId, true); 
        setShowIGConnectedSuccess(story.avatarUrl == null);        
        const igLoginType = instagramLoginType != null ? instagramLoginType : instagramLoginTypeFromWeb;

        if (igLoginType === "fb" && story.avatarUrl == null){     
            // TODO mutualize with content picker dialog      
            getAvatarFromInstagram(fbAccessToken, igAccountId)
                .then((profile_picture_url)=> 
                    new Promise((resolve, reject) => {
                        fetch(profile_picture_url)
                            .then(res => res.blob())
                            .then(blob => {                                                       
                                blob.name = getFileNameFromUrl(profile_picture_url);                                
                                return blob;                  
                            })
                            .then((response)=> resolve(response))
                            .catch((error)=> reject(error));
                    })                
                )
                .then((blob)=>
                    new Promise((resolve, reject) => {
                        checkMediaContent(blob, story, storyId, baseStory, MEDIA_TYPE_AVATAR, ()=>{}, CHECK_MEDIA_CONTENT_REPLACE)
                            .then(()=> uploadMediaContent(story, storyId, blob, MEDIA_TYPE_AVATAR, "instagram"))
                            .then((mediaUrl)=> resolve(mediaUrl))
                            .catch((error)=> reject(error));
                    }))
                .then((mediaUrl)=> setAvatarUrl(mediaUrl))
                .then((mediaUrl)=>{                          
                    analyticsManager.logChooseMyLogoTag(storyId, "connect_instagram", "photo", true);                                                          
                    if (story.primaryColor === "#2FC4B5" && mediaUrl.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){                    
                        analyticsManager.logChoosePrimaryColorTag(storyId, "connect_instagram");                                           
                        const detectSetColors = httpsCallable(functions, 'detectSetColors');
                        detectSetColors({ storyId: storyId })
                            .then(()=>setTimeout(()=>{setHighlight("avatar book share contact")}, 1000))
                            /*.catch((error)=>log(printObj(error)))*/;
                    }
                    else 
                        setTimeout(()=>{setHighlight("avatar book contact");}, 1000);
                })
                .catch((error)=>{  
                    setFbAccessToken(null);
                    setInstagramAccountId(null);
                    setInstagramLoginType(null);
                    Sentry.captureMessage(printObj(error));
                });            
        }
        else if (igLoginType === "ig"){                
            fetch("https://graph.instagram.com/me?fields=id,username&access_token=" + fbAccessToken)
                .then((response) => response.json())                    
                .then((response)=>{
                    saveInstagramUserName(storyId, response.username, response.username);
                    setTimeout(()=>{setHighlight("book contact");}, 1000);
                })
                .catch((error)=>{  
                    setFbAccessToken(null);
                    setInstagramAccountId(null);
                    setInstagramLoginType(null);
                    Sentry.captureMessage(printObj(error));
                });
        }
    }


    let avatarUrl = "/images/avatar_placeholder.png";
    if (story != null && story.avatarUrl != null && story.avatarUrl !== ""){
        avatarUrl = story.avatarUrl;
    }

    async function onAvatarPicked(url, source, type){
        analyticsManager.logChooseMyLogoTag(storyId, source, "photo", true);
        await setAvatarUrl(url);        
        if (story.primaryColor != null && story.primaryColor.toLowerCase() === "#2fc4b5" && url.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){
            analyticsManager.logChoosePrimaryColorTag(storyId, "choose_avatar");                                           
            const detectSetColors = httpsCallable(functions, 'detectSetColors');
            detectSetColors({ storyId: storyId })
                .then(()=>setTimeout(()=>{setHighlight("avatar book share contact");}, 1000))
                /*.catch((error)=>log(printObj(error)))*/;
        }
    }

    async function setAvatarUrl(url){
        await saveAvatarUrl(storyId, url);
        setTimeout(()=>{setHighlight("avatar");}, 1000);

        return url;
    }   
    
    async function onMusicPicked(url, source, type){
        analyticsManager.logChooseMyMusicTag(storyId, source, "music", true);
        setResetAudio(true);
        
        await setMusicUrl(url);        
    }

    async function setMusicUrl(url){
        await saveMusicUrl(storyId, url);        

        return url;
    } 

    async function updateChatMsg(e){
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);
        const update = {
            chatMsg: e.target.value,
            savedTime: new Date().getTime(),
        };                        

        await updateDoc(docRef, update);
    }
    
    function showIGConnectedSuccessSnack(){
        if (showIGConnectedSuccess){
            return (
                <Snackbar open={showIGConnectedSuccess} autoHideDuration={4000} onClose={()=>setShowIGConnectedSuccess(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}  sx={{ width: "90%" }}>
                    <Alert onClose={()=>setShowIGConnectedSuccess(false)} severity="success" sx={{ width: '100%' }}>     
                    {t('AckInstagramChat')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    }

    async function onChatBlur(e){
        analyticsManager.logChooseTaglineTag(storyId, e.target.value !== "");
        await updateChatMsg(e);
        setTimeout(()=>setHighlight("chat"), 1000);
        if (e.relatedTarget != null && e.relatedTarget.id === "choose-btn"){
            analyticsManager.logChooseRestaurantScreenViewTag(storyId);
            setActiveForm("choose-restaurant");
        }
        else if (e.relatedTarget != null && e.relatedTarget.id === "store-btn"){
            analyticsManager.logChooseStoreFrontScreenViewTag(storyId);
            setActiveForm("store-front");
        }
    }

    if (props.show) {
        const screenWidthToMakerForm = isTabletOrMobile ? 0 : 360;

        function showAvatarDeleteButton(){
            if (story.avatarUrl != null && story.avatarUrl !== ""){
                return(
                    <div style={{cursor: 'pointer', background: "#FFFFFF", borderRadius: 20, position: 'absolute', bottom: 4, right: 4, padding: 2, width: 12, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={()=>deleteStoryMediaContent(story, storyId, story.avatarUrl, baseStory != null ? baseStory.avatarUrl : null).then(()=>{analyticsManager.logChooseMyLogoTag(storyId, "my_restaurant_from", "photo", false);setAvatarUrl("");})}>x</div>
                );
            }
            else return null;
        }

        function showMusicDeleteButton(){
            if (story.musicUrl != null && story.musicUrl !== ""){
                return(<div style={{display: 'flex', flexDirection: "row", alignItems: 'center'}}>
                        {story.musicUrl !== null && reduceMusicFileName(story.musicUrl)}
                        <MaterialButton style="text" reducedPadding={true} onClick={()=>deleteStoryMediaContent(story, storyId, story.musicUrl, baseStory != null ? baseStory.musicUrl : null).then(()=>{analyticsManager.logChooseMyMusicTag(storyId, "my_restaurant_from", "music", false);setMusicUrl("");})} value="X"/>                        
                    </div>
                );
            }
            else return null;
        }

        const isMobileBottomBarDisplayed = isTabletOrMobile && fbResponse != null && fbResponse.isAnonymous != null && ! fbResponse.isAnonymous;
        const additionalStyle = isMobileBottomBarDisplayed ? {overflowY: "scroll", height: windowDimensions.height - 56} : {};

        return(
            <div>
                <div style={{padding: 16}}>
                    <div style={{...additionalStyle}}>
                        <div style={{fontSize: 28}}>{t('MyRestaurant')} </div>
                        <br/><br/>                    
                        {showInstagramProfile()}<br/>
                        <div style={{fontSize: 20}}>{t('MyLogo')}</div>
                        <div style={{display: 'flex', flexDirection: "row", alignItems: 'center'}}>
                            <MaterialButton style="outlined-tonal" onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "content_picker_avatar", true);setShowLogoPicker(MEDIA_TYPE_AVATAR);}} value={t('ChooseMyLogo')} />
                            <div style={{marginLeft: 100, position: 'relative'}}>
                                <img alt="restaurant's avatar" src={avatarUrl} className="maker-logo-container"/>
                                {showAvatarDeleteButton()}                            
                            </div>                        
                        </div>                        
                        <div style={{fontSize: 20, marginTop: 20}}>{t('YourColors')}</div>
                        <div style={{display: 'flex', flexDirection: "row", alignItems: 'center', paddingTop: 10}}>
                            <div style={{textAlign: 'center'}}>
                                <label htmlFor="primaryColor" style={{marginTop: 100, fontSize: 14, fontStyle: 'italic'}}>{t('PrimaryColorBg')}</label>                                
                                <ColorPicker style={{marginTop: 4}} color={story.primaryColor} type="primaryColor" source="my_restaurant_form" />
                            </div>
                            <div style={{marginLeft: 8, textAlign: 'center'}}>
                                <label htmlFor="secondaryColor" style={{marginTop: 100, fontSize: 14, fontStyle: 'italic'}}>{t('PrimaryColorTxt')}</label>
                                <ColorPicker style={{marginTop: 4}} color={story.secondaryColor} type="secondaryColor" source="my_restaurant_form" />
                            </div>
                            <div style={{ marginLeft: 100, marginTop: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                <label htmlFor="shareButton" style={{fontSize: 14, fontStyle: 'italic'}}>{t('PreviewButtonLabel')}</label>
                                <div className="maker-primary-button" style={{marginTop: 4, width: getTextWidth(t('ShareButton'), "14px Roboto") + 36, backgroundColor: story.primaryColor}}>                                    
                                    <ShareIcon id="shareButton" sx={{width: 16, height: 16, color: story.secondaryColor}} /><div className="maker-primary-button-text" style={{color: story.secondaryColor}}>{t('ShareButton')}</div>
                                </div>
                            </div>
                        </div>
                        <br/><br/>
                        <div style={{fontSize: 20}}>{t('MyMusic')}</div>
                        <br/>
                        <div style={{display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'space-between'}}>
                            <MaterialButton style="outlined-tonal" onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "content_picker_music", true);setShowLogoPicker(MEDIA_TYPE_MUSIC);}} value={t('ChooseMyMusic')} />
                            <div>                                
                                {showMusicDeleteButton()}                            
                            </div>                        
                        </div>
                        <br/><br/>
                        <div style={{fontSize: 20}}>{t('YourWelcomeMsg')} </div>
                        <br/>
                        <TextField id='restaurantChatMsg' type='text' style={{width: '100%'}} defaultValue={story.chatMsg != null ? story.chatMsg : ""} placeholder={t('PlaceholderChatMsg')} onBlur={(e)=>onChatBlur(e)}/><br/><br/>
                        <MobileMenu storyId={storyId} story={story}/>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', bottom: 16 + (isMobileBottomBarDisplayed ? 56 : 0), left: 16, position: 'absolute'}}>
                            <MaterialButton id="choose-btn" style="outlined-tonal" onClick={() => {analyticsManager.logChooseRestaurantScreenViewTag(storyId);setActiveForm("choose-restaurant");}} value={t('ThemeToChooseRestaurant')} />                        
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', bottom: 16 + (isMobileBottomBarDisplayed ? 56 : 0), right: screenWidthToMakerForm+16, position: 'absolute'}}>
                            <MaterialButton id="store-btn" style="filled-tonal" onClick={() => {analyticsManager.logChooseStoreFrontScreenViewTag(storyId);setActiveForm("store-front");}} value={t('ThemeToStoreFront')}/>
                        </div>
                        {isMobileBottomBarDisplayed && <div style={{height: 100}}/>}    
                    </div>  
                    <ContentPickerDialog showContentPickerDialog={showLogoPicker} setShowContentPickerDialog={setShowLogoPicker} mediaContentType={showLogoPicker} storyId={storyId} story={story} baseStory={baseStory} storyContentField={MEDIA_TYPE_AVATAR ? story.avatarUrl : story.musicUrl} withProgress={false} onSuccess={showLogoPicker === MEDIA_TYPE_AVATAR ? onAvatarPicked : onMusicPicked} bypassFromIG={bypassConnectToIGForAvatar} resetBypassFromIg={setBypassConnectToIGForAvatar} />    
                    {showIGConnectedSuccessSnack()}
                </div>
                <MobileBottomBar currentTab="0" storyId={storyId} />
            </div>
        );
    }
    else return null;
}

export default MyRestaurantForm;