import React, {useState, useEffect, useContext} from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import ListStories from './ListStories';
import {LoggedContext} from "./LoggedContext";
import Stats from './Stats';
import {AppContext} from '../AppContext';
import { checkMobile, getWindowDimensions } from '../player/Utils';
import { useParams, useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import MyAccountMenu from './MyAccountMenu';
import Preferences from './Preferences';
import MaterialButton from '../maker/component/MaterialButton';
import { analyticsManager } from '../index.js';
import MobileBottomBar from '../maker/component/MobileBottomBar.js';
import { getAuth } from 'firebase/auth';
import Help from './Help.js';
import Legals from './Legals.js';
import MenuEditor from './MenuEditor.js';

function Logged() {    
    const [selectedStory, setSelectedStory] = useState(null);    
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [uid, setUid] = useState(null);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const fbResponse = useContext(AppContext).get("fbResponse");
    const makerDesktopHeight = useContext(AppContext).get("makerDesktopHeight");
    const justDeletedAccount = useContext(AppContext).get("justDeletedAccount");
    const queryParams = useParams(); 
    const navigate = useNavigate();
    const [activePage, setActivePage] = useState(queryParams.page != undefined && queryParams.page !== "ackAbandon" && queryParams.page !== "warningDownsize" ? queryParams.page : "list-stories");
    const [showAckAbandon, setShowAckAbandon] = useState(queryParams.page != undefined && queryParams.page === "ackAbandon");  
    const [showWarningDownsize, setShowWarningDownsize] = useState(queryParams.page != undefined && queryParams.page === "warningDownsize");
    const { t } = useTranslation(); 

    document.title = t('TitleLogged');

    document.getElementsByTagName('body')[0].className = 'PatternBackground';
    document.getElementById("theme-color").setAttribute("content", "#b2ede7");
    
    const scriptList = document.querySelectorAll("script[type='text/javascript']");
    const convertedNodeList = Array.from(scriptList);
    const hubspotScript = convertedNodeList.find(script => script.id === "hs-script-loader");

    useEffect(() => {
        if (!isTabletOrMobile && hubspotScript == null){
            const script = document.createElement('script');
        
            script.id = "hs-script-loader";
            script.src = "//js-eu1.hs-scripts.com/143936779.js";
            script.async = true;
            script.defer = true;
            script.type = "text/javascript";
        
            document.body.appendChild(script);
        
            // do not remove script when unmounting
        }
      }, []);

      // access to logged page redirected to home if not logged
      useEffect(() => {
        getAuth()
            .authStateReady()
            .then(() => {
                if (getAuth().currentUser == null && activePage !== "menu-editor"){                                      
                    navigate("/");
                }
            });
      }, );
    
    useEffect(() => {
        if (fbResponse != null && (fbResponse.isAnonymous == null || ! fbResponse.isAnonymous)){
          setUid(fbResponse.uid);          
        }
      }, [fbResponse]);

    // justDeletedAccount test prevent a bug when deleting an account with recent login error
    useEffect(() => {
        if (justDeletedAccount){
            setSelectedStory(null);        
        }
    }, [justDeletedAccount]);
      
    const handleResize = ()=> {              
        setWindowDimensions(getWindowDimensions());                
    };

    useEffect(() => {            
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {

        function handleOrientationChange(e){      
          setTimeout(() => handleResize(), 200);
        }
    
        if (! checkMobile()) return;
    
        if (window.screen != null && window.screen.orientation != null)
            window.screen.orientation.addEventListener("change", handleOrientationChange);
    
        return () => {   
            if (window.screen != null && window.screen.orientation != null)   
                window.screen.orientation.removeEventListener('change', handleOrientationChange);
        };        
    }, []);

    function showStats(){
        if (isTabletOrMobile && selectedStory != null){
            return (
                <Stats selectedStory={selectedStory} setSelectedStory={setSelectedStory}/>
            );
        }
        else return null;
    }
    function showListStories(){
        if (isTabletOrMobile && selectedStory == null){
            return (
                <ListStories selectedStory={selectedStory} setSelectedStory={setSelectedStory}/>
            );
        }
        else return null;
    }

    function showAckAbandonSnack(){
        if (showAckAbandon){
            return (
                <Snackbar open={showAckAbandon} autoHideDuration={4000} onClose={()=>setShowAckAbandon(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
                    <Alert onClose={()=>setShowAckAbandon(false)} severity="success" sx={{ width: '100%' }}>     
                    {t('AcknowledgeAbandonChanges')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    } 
    
    function showWarningDownsizeSnack(){
        if (showWarningDownsize){
            return (
                <Snackbar open={showWarningDownsize} autoHideDuration={8000} onClose={()=>setShowWarningDownsize(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
                    <Alert onClose={()=>setShowWarningDownsize(false)} severity="warning" sx={{ width: '100%' }}>
                    {t('WarningDownsizeRunning')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    }

    if (isTabletOrMobile) {
        
        return (
            <div style={{width: '100%', height: '100%', background: '#FFFFFF'}}>
                <LoggedContext.Provider value={new Map([["uid", uid],["windowDimensions", windowDimensions], ["activePage", activePage], ["setActivePage", setActivePage]])}>
                    {activePage === "list-stories" &&
                        <>
                            {showListStories()}
                            {showStats()}
                        </>
                    }
                    {activePage === "preferences" &&
                        <Preferences/>
                    }
                    {activePage === "help" &&
                        <Help />
                    }
                    {activePage === "legals" &&
                        <Legals />
                    }
                    {showAckAbandonSnack()}  
                    {showWarningDownsizeSnack()}
                    {activePage !== "help" && activePage !== "legals" &&                
                        <MobileBottomBar currentTab={activePage === "list-stories" ? "1" : "2"} />
                    }
                </LoggedContext.Provider>                
            </div>
        );
    }
    else {
        
        return (
            <LoggedContext.Provider value={new Map([["uid", uid],["windowDimensions", windowDimensions], ["activePage", activePage], ["setActivePage", setActivePage]])}>                
                <div style={{width: "100%", height: activePage === "menu-editor" ? "" : "100%", position: "absolute"}}>
                    <div id="logged-container" style={{width: activePage === "menu-editor" ? windowDimensions.width - 340 : 720, height: activePage === "menu-editor" ? "" : makerDesktopHeight, display: "flex", flexDirection: "row", transform: activePage === "menu-editor" ? "translate(-50%, 0%)" : 'translate(-50%, -50%)', position: 'absolute', top: activePage === "menu-editor" ? "" : '50%', left: '50%',}}>
                        {activePage === "list-stories" &&
                            <>
                                <div style={{width: 360, height: makerDesktopHeight, display: "flex", flexDirection: "column", justifyContent: 'flex-end',}}>
                                    <div style={{width: 360, height: makerDesktopHeight, background: '#FFFFFF', borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}>                            
                                        <ListStories selectedStory={selectedStory} setSelectedStory={setSelectedStory}/>                            
                                    </div>
                                </div>
                                <div style={{width: 360, height: makerDesktopHeight, marginTop: 0}}>
                                    <Stats selectedStory={selectedStory} setSelectedStory={setSelectedStory}/>
                                </div>
                                {showAckAbandonSnack()}
                                {showWarningDownsizeSnack()}
                            </>
                        }
                        {activePage === "preferences" &&
                            <Preferences />
                        }
                        {activePage === "legals" &&
                            <div style={{width: 720, height: makerDesktopHeight, marginTop: 0}}>
                                <Legals />
                            </div>
                        }
                        {activePage === "help" &&
                            <Help />
                        }
                        {activePage === "menu-editor" &&
                            <MenuEditor />
                        }
                    </div>
                    {(activePage === "preferences" || activePage === "help" || activePage === "legals") && <div style={{position: 'absolute', top: window.innerHeight/2 - makerDesktopHeight/2 + 40 + 0, left: window.innerWidth/2 - 720/2 - 260, backgroundColor: 'white', padding: 8, borderRadius: 10, width: 200}}>
                        <MaterialButton style="text" onClick={()=>{analyticsManager.logListStoriesTag(null, "preferences");setActivePage("list-stories");}} value={t('MyStories')}/>
                    </div>}
                    {fbResponse != null && ! fbResponse.isAnonymous && <><MyAccountMenu/></>}
                </div>                
            </LoggedContext.Provider>
        );
    }

}

export default Logged;