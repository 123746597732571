import './../../Player.css';
import React, {useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { PlayerContext } from '../../PlayerContext';
import { checkMobile, isAndroid, isIOS, isInstagramInAppBrowser, isSnapchatInAppBrowser } from '../../Utils';
import { analyticsManager } from '../../..';
import CloseButton from '../../side_menu/components/CloseButton';

function Infos(props){
    const { t } = useTranslation();

    const storyDimensions = useContext(PlayerContext).get("storyDimensions");
    const playMode = useContext(PlayerContext).get("playMode");
    const miniMultiplier = useContext(PlayerContext).get("miniMultiplier"); 

    const menuColor = useContext(PlayerContext).get("menuColor");     
    
    console.log("info.js " + props.show);
    if (props.show){          
        // scroll up closes the webpage otherwise disallowing us to scroll up the page before the snapchat browser scroll up to close feature
        // overflow visible allow to make the page scrollable, then we set it to hidden on menu close (setting back to auto would make the story video scrollable) 
        if (isSnapchatInAppBrowser() && isAndroid() || isIOS() && isInstagramInAppBrowser() || isIOS() && isSnapchatInAppBrowser()){                
            document.getElementsByClassName("StoryVideo")[0].style.display = "none";
            document.getElementsByClassName("StoryVideoContainer")[0].style.overflow = "visible";  
            if (isIOS() && isSnapchatInAppBrowser()){
                document.getElementsByClassName("BlockingOverlay")[0].style.display = "none"; 
                document.getElementsByTagName('body')[0].className = 'WhiteBackground';   
            }              
        }     
        
        let additionalStyle = {};
        // on iOS with Snapchat we force a standard positionning to avoid scrolling up to close the web page
        if (! (isIOS() && isSnapchatInAppBrowser())){
            additionalStyle = {
                position: 'absolute',
                overflowX: 'hidden', 
                overflowY: 'auto'
            };
        }                                  

        return ( 
            <div style={{pointerEvents: 'auto'}}>                 
                {  
                    <div id="menu-container" className={'menu-container' + ((checkMobile() || playMode === "demo") ? ' menu-container-scroll-mobile' : ' menu-container-scroll-desktop')} style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight, ...additionalStyle}}>                                                                
                        {playMode === "preview" && !checkMobile() && <div style={{width: 1, height: storyDimensions.containerHeight, backgroundColor: '#b2ede7', position: 'absolute', left: 0, bottom: 0}} />}                 
                            
                        <div className="menu-page-title-container" style={{marginTop: 36}}>
                            <div style={{color: '#404040', fontSize: 36 * miniMultiplier, fontFamily: 'Roboto condensed', fontWeight: 'bold'}}>Programme</div>                            
                        </div> 

                        <div className="menu-items" style={{marginTop: 36, marginLeft: 24 * miniMultiplier, marginBottom: 20 * miniMultiplier, width: storyDimensions.containerWidth - 48 * miniMultiplier, marginRight: 24 * miniMultiplier, }}>                    
                            
                            <div>
                                <b>Les fondamentaux du développement informatique</b><br/>
                                <div style={{marginTop: 10}}/>
                                🎓 Formation : 9 mois <br/> 💼 Stage : 3 à 6 mois<br/>
                                <div style={{marginTop: 10}}/>
                                👉 Des bases solides pour bien comprendre le fonctionnement de tous les langages. <br/>
                                <div style={{marginTop: 10}}/>
                                C / Shell / Python / Bases de données<br/>
                                <div style={{marginTop: 10}}/>
                                <i><a href="https://www.holbertonschool.fr/programme/les-fondamentaux" target='_blank'>Retrouvez le détail du programme</a></i><br/>
                            </div>

                            <div style={{marginTop: 40}}>
                                <b>Les spécialisations en résumé</b><br/>
                                <div style={{marginTop: 10}}/>
                                🎓 Formation en initial : 9 mois<br/>💼 Stage : 3 mois à 6 mois<br/>
                                <div style={{marginTop: 10}}/>
                                👉 <b>Au choix</b> : <ul><li>Web full stack, </li><li>machine learning, </li><li>réalité augmentée/ virtuelle, </li><li>blockchain & programmation systèmes, </li><li>cybersécurité</li></ul>
                                <div style={{marginTop: 10}}/>
                                <i><a href="https://www.holbertonschool.fr/programme/specialisations" target='_blank'>Retrouvez le détail du programme</a></i><br/>
                            </div>


                            <div style={{marginTop: 40}}>
                                🎓 Formation en alternance : 24 mois<br/>
                                <div style={{marginTop: 10}}/>
                                👉 <b>Au choix</b> : Web full stack, machine learning, réalité augmentée/ virtuelle<br/>
                                <div style={{marginTop: 10}}/>
                                <i><a href="https://www.holbertonschool.fr/programme/specialisations" target='_blank'>Retrouvez le détail du programme</a></i><br/>
                            </div>

                        </div>
                                    
                    </div>   
                }  
                
                <div style={{position: 'absolute', zIndex: 30, pointerEvents: "auto", top: storyDimensions.containerHeight - 48 * miniMultiplier - 8 * miniMultiplier, left: storyDimensions.containerWidth - 48 * miniMultiplier - 8 * miniMultiplier, }}>
                    <CloseButton onClick={(e) => closeMenuOrDetail(e)} color={menuColor}/>
                </div>                          
            </div>
        );
    }
    else {
        return null;        
    }   

    function closeMenuOrDetail(e){
        closeMenu(e);
    }
    
    function closeMenu(e){        
        if (isSnapchatInAppBrowser() && isAndroid() || isIOS() && isInstagramInAppBrowser() || isIOS() && isSnapchatInAppBrowser()){                            
            document.getElementsByClassName("StoryVideoContainer")[0].style.overflow = "hidden"; 
            document.getElementsByClassName("StoryVideo")[0].style.display = "";
            if (isIOS() && isSnapchatInAppBrowser()){
                document.getElementsByTagName('body')[0].className = 'PatternBackground'; 
                document.getElementsByClassName("BlockingOverlay")[0].style.display = ""; 
            }
        }      
        props.closeMenu();
        analyticsManager.logOpenMenuTag(props.storyId, playMode, false);
    }

}


export default Infos;