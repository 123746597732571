import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import { createTheme, ThemeProvider } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
        "Soja",
        "Pisces",
        "Egg",
        "Mustard",
        "Molluscs",
        "Lupine",
        "Milk",
        "Sesame seeds",
        "Gluten",
        "Nuts",
        "Crustaceans",
        "Cereals",
        "Celery",
        "Peanuts",
        "Sulphurous anhydrous",  
];

export default function AllergensSelect(props) {
  const [allergens, setAllergens] = React.useState([]);
  const { t } = useTranslation();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setAllergens(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );

    if (props.callback){                 
        props.callback(value.toString());
    }
    
  };

  const handleClose = () => {
    if (window.ttq){
      window.ttq.track('ViewContent', {                
          "contents": [
              {              
                  "content_id": "0",           
                  "content_name": "set allergens to " + allergens.toString(),
              }
          ],
      });
    }
    if (window.fbq){                    
        window.fbq('track', 'ViewContent', {"content_name": "set allergens to " + allergens.toString()});
    };
  };

  return (
    <div>
      <ThemeProvider theme={createTheme({
        palette: {
          primary: {
            main: props.color,
          },    
        },
      })}>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="allergens-select-label">{t("Allergens")}</InputLabel>
          <Select
            labelId="allergens-select-label"
            id="allergens-select"
            multiple
            value={allergens}
            onTouchStart={(e)=>e.stopPropagation()}
            onTouchMove={(e)=>e.stopPropagation()}
            onTouchEnd={(e)=>e.stopPropagation()}
            onChange={handleChange}
            onClose={handleClose}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}          
          >
            {names.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={allergens.indexOf(name) > -1} />
                <ListItemText primary={t(name)} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ThemeProvider>
    </div>
  );
}