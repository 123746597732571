import { getFirestore, doc, deleteField, deleteDoc, runTransaction, query, collection, where, getDocs } from "firebase/firestore";
import { getFileNameFromUrl } from "../player/Utils";
import { deleteAllStorage } from "./MakerController";
import * as Sentry from "@sentry/react";

export async function resetStory(story, storyId, setShowResetConfirm){      
    deleteAllStorage(story, storyId);
    
    await resetStoryInDB(storyId);
    
    setShowResetConfirm(false);
    // TODO do without reload to avoid flickering and send the related ga tag
    window.location.reload();
}

export async function resetStoryInDB(storyId, newPosId = deleteField()){
    const db = getFirestore();       
    const storyRef = doc(db, "stories", storyId);
    
    try {
        await runTransaction(db, async (transaction) => {
            const storyDoc = await transaction.get(storyRef);
            if (!storyDoc.exists()) {
                throw "Document does not exist!";
            }   
            
            // possible in this cases ?
            const q = query(collection(db, "story_ratings"), where("storyId", "==", storyDoc.id));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((rating) => {                  
                transaction.delete(doc(db, "story_ratings", rating.id));    
            });

            // possible in this cases ?
            const q2 = query(collection(db, "web_users"), where("storyId", "==", storyDoc.id));
            const querySnapshot2 = await getDocs(q2);
            querySnapshot2.forEach((web_user) => {                
                transaction.delete(doc(db, "web_users", web_user.id));    
            });

            // possible in this cases ?          
            transaction.update(storyRef, {
                posId: newPosId,   
                creationDate: new Date().getTime(),
                // permit sticker positionning 
                width: 360,
                height: 800, 
                dimWithOffset: "width",
                offsetValue: 0,
                primaryColor: "#2FC4B5",
                secondaryColor: "#FFFFFF",
                savedTime: new Date().getTime(),
                // delete every fields
                alias: deleteField(),
                aliasLowerCase: deleteField(),
                previousAlias: deleteField(),
                previousAliasLowerCase: deleteField(),
                posName: deleteField(),
                country: deleteField(),
                posType: deleteField(),
                posPhone: deleteField(),                
                posBookingByPhone: deleteField(),
                posBookingLink: deleteField(),
                orderingPlatforms: deleteField(),
                posArea: deleteField(),
                instagramProfileName: deleteField(),
                instagramProfileUserName: deleteField(),
                posChatLink: deleteField(),
                chatMsg: deleteField(),
                avatarUrl: deleteField(),                        
                musicUrl: deleteField(),
                imageRef: deleteField(),
                videoRef: deleteField(),
                shareTnRef: deleteField(),
                videoFSTnRef: deleteField(),
                mediasCenterXpct: deleteField(),
                mediasCenterYpct: deleteField(),
                mediasRef: deleteField(),
                virtualTourCenterXpct: deleteField(),
                virtualTourCenterYpct: deleteField(),
                tourMediasRef: deleteField(),
                menuId: deleteField(),
                menuFile: deleteField(),
                dayMenuFile: deleteField(),
                withDayMenu: false,
                withMenu: false,        
            });              
        });            
      } catch (e) {        
            Sentry.captureMessage("resetStoryInDB , storyId " + storyId + " exception " + e, "error");
      }
}

export async function abandonChanges(baseStory, story, storyId, navigate){    

    const mediasToKeep = [];
    if (baseStory.avatarUrl != null && baseStory.avatarUrl.startsWith(process.env.REACT_APP_STORAGE_LOCATION))
        mediasToKeep.push(getFileNameFromUrl(baseStory.avatarUrl));
    if (baseStory.musicUrl != null && baseStory.musicUrl.startsWith(process.env.REACT_APP_STORAGE_LOCATION))
        mediasToKeep.push(getFileNameFromUrl(baseStory.musicUrl));
    if (baseStory.imageRef != null)
        mediasToKeep.push(getFileNameFromUrl(baseStory.imageRef));
    if (baseStory.videoRef != null)
        mediasToKeep.push(getFileNameFromUrl(baseStory.videoRef));
    if (baseStory.shareTnRef != null)
        mediasToKeep.push(getFileNameFromUrl(baseStory.shareTnRef));
    if (baseStory.videoFSTnRef != null)
        mediasToKeep.push(getFileNameFromUrl(baseStory.videoFSTnRef));
    if (baseStory.tourMediasRef != null)
        baseStory.tourMediasRef.forEach(media => mediasToKeep.push(getFileNameFromUrl(media)));
    if (baseStory.mediasRef != null)
        baseStory.mediasRef.forEach(media => mediasToKeep.push(getFileNameFromUrl(media)));
    if (baseStory.menuFile != null)
        mediasToKeep.push(getFileNameFromUrl(baseStory.menuFile));
    if (baseStory.dayMenuFile != null)
        mediasToKeep.push(getFileNameFromUrl(baseStory.dayMenuFile));
    mediasToKeep.push(baseStory.id + ".jpg");    
    
    deleteAllStorage(story, baseStory.id, mediasToKeep);

    const db = getFirestore();  
    const docRef = doc(db, "stories", storyId);              
    await deleteDoc(docRef);
    navigate("/logged/ackAbandon");
}