import './../../Player.css';

import React, {useState, useEffect, useContext} from 'react';
import ReactPlayer from 'react-player';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import CloseIcon from '@mui/icons-material/Close';
import { PlayerContext } from '../../PlayerContext';
import { isImg, isVideo, printObj } from '../../Utils';
import { AppContext } from '../../../AppContext';
import * as Sentry from "@sentry/react";

function FullScreenMediaPlayer(props){
    const dp = 1 /** window.devicePixelRatio*/;   
    const [dimRef, setDimRef] = useState(null);

    const windowDimensions = useContext(PlayerContext).get("windowDimensions");

    function checkPlaying() {
        const slides = document.getElementsByClassName("FullScreenContainer");      
        try{
            if (slides != null && slides.length !== 0){
                const video = slides[0].children[0].children[0];
                if (video.readyState === 4){
                    video.play();                
                }
            }
        } catch(error){
            Sentry.captureMessage(printObj(error));
        }
    }

    useEffect(() => {  
        if (isImg(props.fullScreenMediaFormat) && dimRef == null){
            const img = new Image();

            function onLoad(){
                // the component does not permit to pan the full image for certain ratios
                //alert ("" + windowDimensions.height / windowDimensions.width + " > " + this.naturalHeight / this.naturalWidth + " " + (windowDimensions.height / windowDimensions.width > this.naturalHeight / this.naturalWidth));
                //if (windowDimensions.height / windowDimensions.width > this.naturalHeight / this.naturalWidth) {
                    setDimRef({height: windowDimensions.height});
                /*}
                else {
                    setDimRef({width: windowDimensions.width});        
                } */   
            }
            img.addEventListener("load", onLoad);
            img.src = props.mediaFullScreenOpened;

            return () => img.removeEventListener("load", onLoad);
        }
      });
      
    if (props.isFullScreenOpened){

        if (isImg(props.fullScreenMediaFormat) && dimRef == null){
            return ("");
        }
        else if (isImg(props.fullScreenMediaFormat) && dimRef != null){
            return (                
                <div className="FullScreenContainer">              
                    <TransformWrapper>
                        <TransformComponent>
                            <img alt="full screen content" src={props.mediaFullScreenOpened} style={{...dimRef}}/>                         
                        </TransformComponent>
                    </TransformWrapper>          
                    <CloseIcon onClick={() => props.setFullScreen(props.mediaFullScreenOpened, false)} className="FullScreenClose" style={{color: props.btnColor, top: 16 * dp, right: 16 * dp, width: 48 * dp, height: 48 * dp}}/>
                </div>                            
            );
        }
        else if (isVideo(props.fullScreenMediaFormat)){      
            const onClose = () => {
                const slides = document.getElementsByClassName("slide selected");      
                try{
                    if (slides != null && slides.length !== 0){
                        slides[0].children[0].children[0].play();                
                    }
                } catch(error){
                    Sentry.captureMessage(printObj(error));
                }
                props.setFullScreen(props.mediaFullScreenOpened, false);
            }

            // TODO limit retries
            function retry(){
                const slides = document.getElementsByClassName("FullScreenContainer");    
                try{    
                    if (slides != null && slides.length !== 0){
                        slides[0].children[0].children[0].load();
                    }
                } catch(error){
                    Sentry.captureMessage(printObj(error));
                }
            }                             

            return ( 
                    <div className="FullScreenContainer"> 
                        <ReactPlayer onClick={() => checkPlaying()} onError={() => retry()} height={windowDimensions.height} url={props.mediaFullScreenOpened} playsinline playing={true} loop={true} style={{zIndex: 100}}/>
                        <CloseIcon onClick={() => onClose()} className="FullScreenClose" style={{color: props.btnColor, top: 16 * dp, right: 16 * dp, width: 48 * dp, height: 48 * dp, zIndex: 110}}/>             
                    </div>                      
            );
        }
        else {         
            return null;
        }
    }
    else {
        return null;
    }
}

export default FullScreenMediaPlayer;