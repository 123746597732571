import React, {useState, useEffect, useContext, useRef} from 'react';
import { getFirestore, doc, updateDoc, deleteField, arrayUnion, arrayRemove, getDoc, } from "firebase/firestore";
import { useTranslation } from 'react-i18next';
import { getFileNameFromUrl, isImg, isVideo, getFileFormat, fetchImageSize, isFirefox} from '../player/Utils';
import {MakerContext} from "./MakerContext";

import { menuType, insideType } from '../player/overlay/stickers/StickerGallery';
import MaterialButton from './component/MaterialButton';
import MobileMenu from './component/MobileMenu';
import { useMediaQuery } from 'react-responsive';
import { MEDIA_TYPE_STORE_FRONT, deleteExistingMediaContent, MEDIA_TYPE_INSIDE_PREVIEW, MEDIA_TYPE_MENU_PREVIEW, deleteInsideMediaDB, MEDIA_TYPE_STORE_FRONT_TN, deleteThumbnailImg, deleteFSThumbnailImg, MEDIA_TYPE_STORE_FRONT_FS_TN, cleanMakerQueryString } from './MakerController';
import ContentPickerDialog from './component/ContentPickerDialog';
import { analyticsManager } from '..';
import { AppContext } from '../AppContext';
import MobileBottomBar from './component/MobileBottomBar';

function StoreFrontForm(props) { 
    const [showPicker, setShowPicker] = useState(false);   
    const story = useContext(MakerContext).get("story");
    const storyId = useContext(MakerContext).get("storyId");
    const baseStory = useContext(MakerContext).get("baseStory");
    const setActiveForm = useContext(MakerContext).get("setActiveForm");
    const activePopup = useContext(MakerContext).get("activePopup");
    const setActivePopup = useContext(MakerContext).get("setActivePopup");
    const stickerDrop = useContext(MakerContext).get("stickerDrop");
    const setStickerDrop = useContext(MakerContext).get("setStickerDrop");   
    const videoLoaded = useContext(MakerContext).get("videoLoaded");   
    const setVideoLoaded = useContext(MakerContext).get("setVideoLoaded");
    const setFeatureReset = useContext(MakerContext).get("setFeatureReset"); 
    const setResetStickerOutOfScreen = useContext(MakerContext).get("setResetStickerOutOfScreen"); 
    const [touchStartElementPosition, setTouchStartElementPosition] = useState(-1);    
    const [touchStartMediaType, setTouchStartMediaType] = useState(null);
    const setHighlight = useContext(MakerContext).get("setHighlight");
    const [selectedMenuContents, setSelectedMenuContents] = useState([]);
    const [selectedInsideContents, setSelectedInsideContents] = useState([]);
    const [bypassContentPickerLogin, setBypassContentPickerLogin] = useState(false);   
    const [videoLoadedDuringSession, setVideoLoadedDuringSession] = useState(false);  
    const instagramLoginType = useContext(MakerContext).get("instagramLoginType");
    const touchStartPositionRef = useRef(null);
    const dragTime = useRef(0);

    const windowDimensions = useContext(MakerContext).get("windowDimensions");
    const fbResponse = useContext(AppContext).get("fbResponse");
    const makerDesktopHeight = useContext(AppContext).get("makerDesktopHeight");
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const stickerMoveAreaWidth = isTabletOrMobile ? windowDimensions.width : 360.0;
    const stickerMoveAreaHeight = isTabletOrMobile ? windowDimensions.height : makerDesktopHeight;

    const { t } = useTranslation();

    useEffect(() => {
        if (props.show && (activePopup === MEDIA_TYPE_STORE_FRONT || activePopup === MEDIA_TYPE_INSIDE_PREVIEW || activePopup === MEDIA_TYPE_MENU_PREVIEW)){            
            setShowPicker(activePopup);
            analyticsManager.logOpenPopupTag(storyId, "content_picker_" + activePopup, true);
            setBypassContentPickerLogin(true);
            setActivePopup(null);
            if (instagramLoginType === "ig")
                cleanMakerQueryString(story.baseStoryId != null ? story.baseStoryId : storyId);
        }
      }, [activePopup && props.show]);

    async function setStoreFrontUrl(url, source, type){
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);

        const update = {
            savedTime: new Date().getTime(),
        };
        const fileFormat = getFileFormat(getFileNameFromUrl(url));
        if (isImg(fileFormat)){
            analyticsManager.logChooseStoreFrontTag(storyId, source, type, true);
            update.imageRef = url;
            update.videoRef = deleteField()
            
            const dimensions = await fetchImageSize(url);
            if (dimensions != null){
                // TODO global variables
                if (stickerMoveAreaHeight / stickerMoveAreaWidth > dimensions.height / dimensions.width) {
                    update.dimWithOffset = "width";
                    let trueWidthOnVideoFormat = Math.round(stickerMoveAreaWidth * dimensions.height / stickerMoveAreaHeight);
                    update.offsetValue = dimensions.width - trueWidthOnVideoFormat;
                } else {
                    update.dimWithOffset = "height";
                    let trueWidthOnVideoFormat = stickerMoveAreaHeight * dimensions.width / stickerMoveAreaWidth;
                    update.offsetValue = Math.round(dimensions.height - trueWidthOnVideoFormat);
                }
                if (story.virtualTourCenterXpct != null){
                    update.virtualTourCenterXpct = 50;
                    update.virtualTourCenterYpct = 25;
                }
                if (story.mediasCenterXpct != null){
                    update.mediasCenterXpct = 50;
                    update.mediasCenterYpct = 60;
                } 

                setResetStickerOutOfScreen(true);

                update.width = dimensions.width;
                update.height = dimensions.height;
            }            
            setShowPicker(false);
        }
        else if (isVideo(fileFormat)){
            setVideoLoadedDuringSession(true);
            analyticsManager.logChooseStoreFrontTag(storyId, source, type, true);
            update.videoRef = url;
            update.imageRef = deleteField();
            update.width = deleteField();
            update.height = deleteField();
            update.dimWithOffset = deleteField();
            update.offsetValue = deleteField();
            // TODO width, height, dimWithOffset et offsetValue à calculer par batch        
            setShowPicker(false);            
        }
        // = the uploaded content returns a "" url, should never happen
        /*else if (url === ""){
            analyticsManager.logChooseStoreFrontTag(storyId, source, type, false);
            update.videoRef = deleteField();
            update.imageRef = deleteField();
            update.width = stickerMoveAreaWidth;// -> err 360, see delete store front
            update.height = stickerMoveAreaHeight;// -> err 800, see delete store front
            update.dimWithOffset = "width";
            update.offsetValue = 0;
            setShowPicker(false);
        }*/

        await updateDoc(docRef, update);
    }

    async function addInsideMediaToStory(url, source, type){
        analyticsManager.logChooseInsideTag(storyId, source, type, true);
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);    
        const update = {
            tourMediasRef: arrayUnion(url),
            savedTime: new Date().getTime(),
        };
        if (story.tourMediasRef == null){
            update.virtualTourCenterXpct = 50;
            update.virtualTourCenterYpct = 25;
        }
        await updateDoc(docRef, update);
        setTimeout(()=>{setHighlight("inside")}, 1000);
    }

    async function addMenuMediaToStory(url, source, type){
        analyticsManager.logChooseMenuTag(storyId, source, type, true);
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);
        const update = {
            mediasRef: arrayUnion(url), 
            savedTime: new Date().getTime(),           
        };
        if (story.mediasRef == null){
            update.mediasCenterXpct = 50;
            update.mediasCenterYpct = 60;
        }
        await updateDoc(docRef, update);
        setTimeout(()=>{setHighlight("menu")}, 1000);
    }
    
    function getTouchElementPosition(e){              
        var mediasArray = Array.from(e.target.parentNode.parentNode.childNodes);
        let idx = -1;
        mediasArray.forEach((media) => { 
            if (media == e.target.parentNode) idx = mediasArray.indexOf(media);                
        });
        return idx;
    }

    function getTouchElementPositionFromCoords(e, containerId){    
        let containerElement = document.getElementById(containerId);    
        var mediasArray = Array.from(containerElement != null ? containerElement.childNodes : []);
        let idx = -1;
        mediasArray.forEach((media) => {
            if (media.className == "maker-gallery-container-box" && e.changedTouches[0].clientX > media.getBoundingClientRect().left && e.changedTouches[0].clientX < media.getBoundingClientRect().right && e.changedTouches[0].clientY > media.getBoundingClientRect().top && e.changedTouches[0].clientY < media.getBoundingClientRect().bottom){
                idx = mediasArray.indexOf(media);
            }            
        });
        return idx;
    }

    function onGalleryTouchStart(e){                
        setTouchStartElementPosition(getTouchElementPosition(e));
        setTouchStartMediaType(e.target.parentNode.parentNode.id);  
        dragTime.current = new Date().getTime();
        touchStartPositionRef.current = {targetId: e.target.id};         
    }

    function onGalleryTouchEnd(e){                       
        const touchEndElementPosition = getTouchElementPositionFromCoords(e, touchStartMediaType);        
        if (new Date().getTime() - dragTime.current < 200 && touchStartPositionRef.current.targetId === e.target.id && e.target.id.startsWith("delete-")){
            if (touchStartMediaType === "inside-gallery-container"){
                document.getElementById(e.target.id).click();
            }
            else if (touchStartMediaType === "menu-gallery-container"){
                document.getElementById(e.target.id).click();
            }            
            return false;
        }
        dragTime.current = 0;            
        touchStartPositionRef.current = null;
       
        if (touchEndElementPosition !== -1 && touchStartElementPosition !== touchEndElementPosition){
            if (touchStartMediaType === "inside-gallery-container"){
                invertInsideOrder();
            }
            else if (touchStartMediaType === "menu-gallery-container"){                
                const firstPosToInvert = touchStartElementPosition;
                const secondPosToInvert = touchEndElementPosition;                
                const medias = story.mediasRef;
                const tmp = medias[firstPosToInvert];
                medias[firstPosToInvert] = medias[secondPosToInvert];
                medias[secondPosToInvert] = tmp;

                updateMediaOrder(medias);
            }
        }
        setTouchStartElementPosition(-1);
        setTouchStartMediaType(null);
    }
    

    function showInsideGallery(){
        function showThumbnail(media){
            if (isImg(getFileFormat(getFileNameFromUrl(media)))){
                return (
                    <img alt={isFirefox() ? "":"thumbnail of a photo of the inside gallery"} onLoad={(e) => e.target.style.color='white'} onError={(e) => e.target.style.color='transparent'} style={{color: 'transparent', borderRadius: 4,}} src={media} />
                );
            }
            else {
                return (
                    <video style={{borderRadius: 4,}}>
                        <source key={media} src={media} />
                    </video>
                );
            }
        }

        if (story != null && story.tourMediasRef != null){
            let mediaCounter = 0;
            const medias = story.tourMediasRef.map((media) =>{          
                mediaCounter++;
                return (
                    <div key={media} style={{position: 'relative', height: 100}} draggable="true" onDragStart={(e)=>handleDragStart(e)} onDragOver={(e)=>handleDragOver(e)} onDragEnd={(e)=>handleDragEnd(e)} onDrop={(e)=>handleDropForInsideMedia(e)} onTouchStart={(e)=>onGalleryTouchStart(e)} onTouchEnd={(e)=>onGalleryTouchEnd(e)} className="maker-gallery-container-box">
                        {showThumbnail(media)}
                        <div id={"delete-" + media} style={{cursor: 'pointer', background: "#FFFFFF", borderTopLeftRadius: 4, position: 'absolute', bottom: 0, right: 0, padding: 2, width: 12, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={()=>deleteInsideMedia(media)}>x</div>
                    </div>
                );                                
            });
            return medias;
        }
        else return null;
    }

    async function deleteInsideMedia(media){
        analyticsManager.logChooseInsideTag(storyId, "inside", "photo", false);
        setFeatureReset(true);
        deleteExistingMediaContent(story, storyId, baseStory, MEDIA_TYPE_INSIDE_PREVIEW, media)
            .then(()=>deleteInsideMediaDB(storyId, media))
            .then(()=>{
                const newSelectedInsideContents = Array.from(selectedInsideContents);
                newSelectedInsideContents.splice(selectedInsideContents.indexOf(media), 1);
                setSelectedInsideContents(newSelectedInsideContents);
            });                 
    }

    function showMenuGallery(){
        function showThumbnail(media){
            if (isImg(getFileFormat(getFileNameFromUrl(media)))){
                return (
                    <img alt={isFirefox() ? "":"thumbnail of a photo of the menu gallery"} onLoad={(e) => e.target.style.color='white'} onError={(e) => e.target.style.color='transparent'} key={media} src={media} style={{color: 'transparent', borderRadius: 4,}}/> 
                );
            }
            else {
                return (
                    <video style={{borderRadius: 4,}}>
                        <source key={media} src={media} />
                    </video>
                );
            }
        }

        if (story != null && story.mediasRef != null){            
            const medias = story.mediasRef.map((media) =>{                                           
                return (
                    <div key={media} style={{position: 'relative', height: 100}} draggable="true" onDragStart={(e)=>handleDragStart(e)} onDragOver={(e)=>handleDragOver(e)} onDragEnd={(e)=>handleDragEnd(e)} onDrop={(e)=>handleDropForMenuMedia(e)} onTouchStart={(e)=>onGalleryTouchStart(e)} onTouchEnd={(e)=>onGalleryTouchEnd(e)} className="maker-gallery-container-box">
                        {showThumbnail(media)}
                        <div id={"delete-" + media} style={{cursor: 'pointer', background: "#FFFFFF", borderTopLeftRadius: 4, position: 'absolute', bottom: 0, right: 0, padding: 2, width: 12, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={()=>deleteMenuMedia(media)}>x</div>
                    </div>
                );                                
            });
            return medias;
        }
        else return null;
    }

    async function deleteMenuMedia(media){        
        const imgOverVideo = isImg(getFileFormat(getFileNameFromUrl(media)));
        analyticsManager.logChooseMenuTag(storyId, "menu", imgOverVideo ? "photo" : "video", false);
        setFeatureReset(true);
        deleteExistingMediaContent(story, storyId, baseStory, MEDIA_TYPE_MENU_PREVIEW, media)
            .then(()=>deleteMenuMediaDB())
            .then(()=>{
                const newSelectedMenuContents = Array.from(selectedMenuContents);
                newSelectedMenuContents.splice(selectedMenuContents.indexOf(media), 1);
                setSelectedMenuContents(newSelectedMenuContents);
            });

        async function deleteMenuMediaDB(){
            const db = getFirestore();        
            const docRef = doc(db, "stories", storyId);
            const update = {
                mediasRef: arrayRemove(media),
                savedTime: new Date().getTime(),
            };
            await updateDoc(docRef, update).then(
                () => deleteMenuMediaField(docRef)
            );
        }   
                
        async function deleteMenuMediaField(docRef){
            const story = await getDoc(docRef);    
            if (story.data().mediasRef != null && story.data().mediasRef.length === 0){
                const update2 = {
                    mediasRef: deleteField()
                };
                updateDoc(docRef, update2);                                
            } 
        }
    }

    let dragSrcEl = null;
    function handleDragStart(e) {
        e.target.opacity = '0.4';
        dragSrcEl = e.target;

        e.dataTransfer.effectAllowed = 'move';
      }
      
      function handleDragEnd(e) {
        e.target.opacity = '1';
      }    

      function handleDragOver(e) {
        e.preventDefault();
        return false;
      }

      function handleDropForInsideMedia(e) {
        e.stopPropagation(); // stops the browser from redirecting.        
        if (dragSrcEl != null && dragSrcEl.parentNode.parentNode.id == e.target.parentNode.parentNode.id && dragSrcEl !== e.target) {
            invertInsideOrder();
        }
        dragSrcEl = null;
    
        return false;
      }

      function handleDropForMenuMedia(e) {
        e.stopPropagation(); // stops the browser from redirecting.        
        if (dragSrcEl!= null && (dragSrcEl.parentNode.parentNode.id == e.target.parentNode.parentNode.id || dragSrcEl.parentNode.id == e.target.parentNode.parentNode.id)&& dragSrcEl !== e.target) {
            var mediasArray = Array.from(e.target.parentNode.parentNode.childNodes);
            mediasArray = mediasArray.filter((element) => element.className == "maker-gallery-container-box");
            
            const firstPosToInvert = mediasArray.findIndex((div) => div.childNodes[0].src == dragSrcEl.src || div.childNodes[0].childNodes[0] != null && dragSrcEl.childNodes[0] != null && div.childNodes[0].childNodes[0].src == dragSrcEl.childNodes[0].childNodes[0].src);
            const secondPosToInvert = mediasArray.findIndex((div) => div.childNodes[0].src == e.target.src || div.childNodes[0].childNodes[0] != null && div.childNodes[0].childNodes[0].src == e.target.src);
            const medias = story.mediasRef;
            const tmp = medias[firstPosToInvert];
            medias[firstPosToInvert] = medias[secondPosToInvert];
            medias[secondPosToInvert] = tmp;

            updateMediaOrder(medias);
          }
          dragSrcEl = null;
        
          return false;
      }

      async function invertInsideOrder(){
        analyticsManager.logDragNDropTag(storyId, "inside");     
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);
        const firstTour = story.tourMediasRef[0];
        const update = {
            tourMediasRef: arrayRemove(firstTour),
            savedTime: new Date().getTime(),
        };
        await updateDoc(docRef, update).then(
            () => addNewPosition()
        );
        
        async function addNewPosition(){
            const update2 = {
                tourMediasRef: arrayUnion(firstTour)
            };
            await updateDoc(docRef, update2);
        }
    }

    async function updateMediaOrder(medias){  
        analyticsManager.logDragNDropTag(storyId, "menu");      
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);
        const update = {
            mediasRef: deleteField(),
            savedTime: new Date().getTime(),
        };
        await updateDoc(docRef, update).then(() =>
            addNewPosition()
        );
        
        async function addNewPosition(){
            const update2 = {
                mediasRef: medias
            };
            await updateDoc(docRef, update2);
        }
    }    

    function showInsideAdder(){
        if (story != null && (story.tourMediasRef == null || story.tourMediasRef.length < 2)){
            return (
                <div className='maker-gallery-container-add' onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "content_picker_inside", true);setShowPicker(MEDIA_TYPE_INSIDE_PREVIEW);}}>
                    +
                </div>
            );
        }
    }

    function showMenuAdder(){
        if (story != null && (story.mediasRef == null || story.mediasRef.length < 4)){
            return (
                <div className='maker-gallery-container-add' onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "content_picker_menu", true);setShowPicker(MEDIA_TYPE_MENU_PREVIEW);}}>
                    +
                </div>
            );
        }
    }

    useEffect(() => {
        updateStickerPositionOnDB();
       }, [stickerDrop]);

    useEffect(() => {
        if (videoLoaded){
            const videoElement = document.getElementsByTagName("video")[0];
            updateVideoDimensions(videoElement);            
            setVideoLoaded(null);
        }        
    }, [videoLoaded]);

    async function updateVideoDimensions(videoElement){        
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);    
        const update = {
            width: videoElement.videoWidth,
            height: videoElement.videoHeight,
        };
        
        if (stickerMoveAreaHeight / stickerMoveAreaWidth > videoElement.videoHeight / videoElement.videoWidth) {
            update.dimWithOffset = "width";
            let trueWidthOnVideoFormat = Math.round(stickerMoveAreaWidth * videoElement.videoHeight / stickerMoveAreaHeight);
            update.offsetValue = videoElement.videoWidth - trueWidthOnVideoFormat;           
        } else {
            update.dimWithOffset = "height";
            let trueWidthOnVideoFormat = stickerMoveAreaHeight * videoElement.videoWidth / stickerMoveAreaWidth;
            update.offsetValue = Math.round(videoElement.videoHeight - trueWidthOnVideoFormat);           
        }
        
        if (videoLoadedDuringSession){
            if (story.virtualTourCenterXpct != null){
                update.virtualTourCenterXpct = 50;
                update.virtualTourCenterYpct = 25;
            }
            if (story.mediasCenterXpct != null){
                update.mediasCenterXpct = 50;
                update.mediasCenterYpct = 60;
            }  
            setResetStickerOutOfScreen(true);  
        }         
        await updateDoc(docRef, update);
    }

    async function updateStickerPositionOnDB(){        
        if (stickerDrop != null){           
            const xPct = Math.round((stickerDrop.x) * 100 / stickerMoveAreaWidth);
            const yPct = Math.round((stickerDrop.y) * 100 / stickerMoveAreaHeight);
                    
            //set x and y to firebase
            const db = getFirestore();   
            
            const docRef = doc(db, "stories", storyId);
            let update = {
                dimWithOffset: stickerDrop.dimWithOffset,
                offsetValue: stickerDrop.offsetValue,
                savedTime: new Date().getTime(),
            };

            if (stickerDrop.mediaType === insideType){
                update = {
                    virtualTourCenterXpct: xPct,
                    virtualTourCenterYpct: yPct,
                };
            }
            else if (stickerDrop.mediaType === menuType){
                update = {
                    mediasCenterXpct: xPct,
                    mediasCenterYpct: yPct,
                };
            }
            await updateDoc(docRef, update);

            setStickerDrop(null);
        }
    }    

    async function deleteStoreFront(){
        analyticsManager.logChooseStoreFrontTag(storyId, "store_front", story.imageRef != null ? "photo" : "video", false);
        deleteExistingMediaContent(story, storyId, baseStory, MEDIA_TYPE_STORE_FRONT).then(()=>deleteStoreFrontField()); 
        deleteExistingMediaContent(story, storyId, baseStory, MEDIA_TYPE_STORE_FRONT_TN).then(()=>deleteThumbnailImg(storyId));
        deleteExistingMediaContent(story, storyId, baseStory, MEDIA_TYPE_STORE_FRONT_FS_TN).then(()=>deleteFSThumbnailImg(storyId));        
        
        async function deleteStoreFrontField(){
            const db = getFirestore();            
            const docRef = doc(db, "stories", storyId);
            // TODO recalcul width, weight, dimoffset et offsetvalue pour le placeholder
            const update = {                
                width: 460, // placeholder
                height: 1024, // placeholder
                savedTime: new Date().getTime(),
            };   
            
            if (stickerMoveAreaHeight / stickerMoveAreaWidth > update.height / update.width) {
                update.dimWithOffset = "width";
                let trueWidthOnVideoFormat = Math.round(stickerMoveAreaWidth * update.height / stickerMoveAreaHeight);
                update.offsetValue = update.width - trueWidthOnVideoFormat;           
            } else {
                update.dimWithOffset = "height";
                let trueWidthOnVideoFormat = stickerMoveAreaHeight * update.width / stickerMoveAreaWidth;
                update.offsetValue = Math.round(update.height - trueWidthOnVideoFormat);           
            }

            if (story.virtualTourCenterXpct != null){
                update.virtualTourCenterXpct = 50;
                update.virtualTourCenterYpct = 25;
            }
            if (story.mediasCenterXpct != null){
                update.mediasCenterXpct = 50;
                update.mediasCenterYpct = 60;
            } 

            setResetStickerOutOfScreen(true);
            
            if (story.imageRef != null){
                update.imageRef = deleteField();
            }
            else if (story.videoRef != null){
                update.videoRef = deleteField();
            }
            await updateDoc(docRef, update);
        }
    }
    
    function showStoreFrontDelete(){
        if (story != null && (story.imageRef != null || story.videoRef != null)){
            return (
                <MaterialButton style="text" onClick={deleteStoreFront} value="X" />
            );
        }
        else return null;
    }

    if (props.show) {
        const screenWidthToMakerForm = isTabletOrMobile ? 0 : 360;

        let onSuccess = null;
        let selectedContents = [];
        let setSelectedContents = null;
        let mediaContentType = null;
        switch (showPicker){
            case MEDIA_TYPE_STORE_FRONT:
                mediaContentType = MEDIA_TYPE_STORE_FRONT;
                onSuccess = setStoreFrontUrl;                
                break;
            case MEDIA_TYPE_INSIDE_PREVIEW:
                mediaContentType = MEDIA_TYPE_INSIDE_PREVIEW;
                selectedContents = selectedInsideContents;
                setSelectedContents = setSelectedInsideContents;
                onSuccess = addInsideMediaToStory;
                break;
            case MEDIA_TYPE_MENU_PREVIEW:
                mediaContentType = MEDIA_TYPE_MENU_PREVIEW;
                selectedContents = selectedMenuContents;
                setSelectedContents = setSelectedMenuContents;
                onSuccess = addMenuMediaToStory;
                break;
        }

        const isMobileBottomBarDisplayed = isTabletOrMobile && fbResponse != null && fbResponse.isAnonymous != null && ! fbResponse.isAnonymous;
        const additionalStyle = isMobileBottomBarDisplayed ? {overflowY: "scroll", height: windowDimensions.height - 56} : {};

        return(
            <div>
                <div style={{padding: 16}}>
                    <div style={{...additionalStyle}}>
                        <div style={{fontSize: 28}}>{t('MyStoreFront')}</div>   
                        <br/>
                        <div style={{fontSize: 20}}>{t('RestaurantStoreFront')}</div><br/>
                        <div style={{display: 'flex', flexDirection: "row", alignItems: 'center'}}>
                            <MaterialButton style="outlined-tonal" onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "content_picker_store_front", true);setShowPicker(MEDIA_TYPE_STORE_FRONT); setFeatureReset(true);/*setShowStoreFrontPicker(true)*/}} value={t('ChooseMyStoreFront')} />
                            {showStoreFrontDelete()}
                        </div>
                        <div style={{fontSize: 20, marginTop: 20}}>{t('RestaurantInside')}<br/><span style={{fontStyle: 'italic', fontSize: 14}}>{t("RestaurantInsideMax")}</span></div>
                        <br/>
                        <div style={{display: 'flex', flexDirection: "row", alignItems: 'center'}}>
                            <div id={"inside-gallery-container"} className='maker-gallery-container'>
                                {showInsideGallery()}
                                {showInsideAdder()}
                            </div>                        
                        </div>
                        <div style={{fontSize: 20, marginTop: 20}}>{t('PlatesPreview')}<br/><span style={{fontStyle: 'italic', fontSize: 14}}>{t("PlatesPreviewMax")}</span></div>
                        <br/>
                        <div style={{display: 'flex', flexDirection: "row", alignItems: 'center'}}>
                            <div id={"menu-gallery-container"} className='maker-gallery-container'>
                                {showMenuGallery()}
                                {showMenuAdder()}
                            </div>                        
                        </div>
                        <MobileMenu storyId={storyId} story={story}/>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', bottom: 16 + (isMobileBottomBarDisplayed ? 56 : 0), left: 16, position: 'absolute'}}>
                            <MaterialButton style="outlined-tonal" onClick={() => { analyticsManager.logMyRestaurantScreenViewTag(storyId);setActiveForm("my-restaurant");}} value={t('StoreFrontToTheme')} />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', bottom: 16 + (isMobileBottomBarDisplayed ? 56 : 0), right: screenWidthToMakerForm+16, position: 'absolute'}}>
                            <MaterialButton style="filled-tonal" onClick={() => {analyticsManager.logChooseMenuScreenViewTag(storyId);setActiveForm("choose-menu");}} value={t('StoreFrontToMenu')}/>
                        </div>
                        <ContentPickerDialog showContentPickerDialog={showPicker != false} setShowContentPickerDialog={setShowPicker} mediaContentType={mediaContentType} bypassFromIG={bypassContentPickerLogin} resetBypassFromIg={setBypassContentPickerLogin} selectedContents={selectedContents} setSelectedContents={setSelectedContents} storyId={storyId} story={story} baseStory={baseStory} withProgress={true} onSuccess={onSuccess} /> 
                        {isMobileBottomBarDisplayed && <div style={{height: 100}}/>}
                    </div>              
                </div>
                <MobileBottomBar currentTab="0" storyId={storyId} />
            </div>
        );
    }
    else return null;
}

export default StoreFrontForm;