import { collection, doc, getDoc, getDocs, getFirestore, query, runTransaction, where } from "firebase/firestore";
import { deleteAllStorage } from "../maker/MakerController";

import * as Sentry from "@sentry/react";
import { getFileNameWithoutFormat, printObj } from "../player/Utils";
import { getMetadata, getStorage, ref } from "firebase/storage";

export async function deleteStoryVersions(storyId){
    const db = getFirestore();  
    const docRef = doc(db, "stories", storyId);
    const baseStoryDoc = await getDoc(docRef);
    let editedVersionDoc = null;
    
    const q = query(collection(db, "stories"), where("baseStoryId", "==", baseStoryDoc.id));
    const querySnapshot = await getDocs(q);            
    querySnapshot.forEach((doc) => {            
        editedVersionDoc = doc;
    });     

    return await deleteStory(baseStoryDoc, editedVersionDoc);        
}

export async function deleteStory(baseStoryDoc, editedVersionDoc){    
    await deleteAllStorage(baseStoryDoc.data(), baseStoryDoc.id);               

    const db = getFirestore();   
    const storyRef = doc(db, "stories", baseStoryDoc.id);
    
    return await runTransaction(db, async (transaction) => {
        const storyDoc = await transaction.get(storyRef);
        if (!storyDoc.exists()) {
            throw "Document does not exist!";
        }

        if (editedVersionDoc != null){
            transaction.delete(doc(db, "stories", editedVersionDoc.id));
        }

        // to not do again menu OCR
        /*const menus = collection(db, "menus");
        const menuPages = collection(db, "menu_pages");
        if (storyDoc.data().menuId != null){
            const menuRef = doc(menus, storyDoc.data().menuId);
            const menuDoc = await transaction.get(menuRef);
            if (!menuDoc.exists()) {
                throw "Document does not exist!";
            }
            menuDoc.data().menuPages.forEach((pageId) => {           
                transaction.delete(doc(menuPages, pageId));
            });
            transaction.delete(menuRef);
        }*/
        
        const q = query(collection(db, "story_ratings"), where("storyId", "==", storyDoc.id));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((rating) => {                  
            transaction.delete(doc(db, "story_ratings", rating.id));    
        });

        const q2 = query(collection(db, "web_users"), where("storyId", "==", storyDoc.id));
        const querySnapshot2 = await getDocs(q2);
        querySnapshot2.forEach((web_user) => {                
            transaction.delete(doc(db, "web_users", web_user.id));    
        });

        transaction.delete(doc(db, "stories", baseStoryDoc.id));
        
    }).catch((error) => {
        Sentry.captureMessage("Delete stories for uid " + baseStoryDoc.data().uid + ", story " + baseStoryDoc.id + "  failed with error: " + printObj(error));
        return false;
    });                                    
}   

/**
 * block edition when media downsizing is running
 */
export async function isStoryReadyForEdition(storyId){
    const db = getFirestore();
    const docRef = doc(db, "stories", storyId);
    const docSnap = await getDoc(docRef);
    const story = docSnap.data();

    if (story.neverPublishedAsLogged){        
        return true;
    }

    let editedVersionDoc = null;    
    const q = query(collection(db, "stories"), where("baseStoryId", "==", storyId));
    const querySnapshot = await getDocs(q);            
    querySnapshot.forEach((doc) => {            
        editedVersionDoc = doc;
    }); 
    if (editedVersionDoc != null && editedVersionDoc.id !== storyId){        
        return true;
    }

    // 4 menu gallery videos * downsizeMedia timeout + 10s for orchestration
    const totalTimeToDownsize = 4 * 240 * 1000 + 10000;
    if (story.publicationDate == null || new Date().getTime() - story.publicationDate > totalTimeToDownsize){        
        return true;
    }
    
    const mediasPotentiallyDownsizing = [];
    if (story.imageRef && !getFileNameWithoutFormat(story.imageRef).endsWith("_dwnsz")){
        mediasPotentiallyDownsizing.push(story.imageRef);
    }
    if (story.videoRef && !getFileNameWithoutFormat(story.videoRef).endsWith("_dwnsz")){
        mediasPotentiallyDownsizing.push(story.videoRef);
    }
    if (story.avatarUrl && story.avatarUrl.startsWith(process.env.REACT_APP_STORAGE_LOCATION) && !getFileNameWithoutFormat(story.avatarUrl).endsWith("_dwnsz")){
        mediasPotentiallyDownsizing.push(story.avatarUrl);
    }   
    if (story.musicUrl && story.musicUrl.startsWith(process.env.REACT_APP_STORAGE_LOCATION) && !getFileNameWithoutFormat(story.musicUrl).endsWith("_dwnsz")){
        mediasPotentiallyDownsizing.push(story.musicUrl);
    } 
    if (story.dayMenuFile && !getFileNameWithoutFormat(story.dayMenuFile).endsWith("_dwnsz")){
        mediasPotentiallyDownsizing.push(story.dayMenuFile);
    }
    if (story.mediasRef){
        story.mediasRef.forEach((media) => {
            if (!getFileNameWithoutFormat(media).endsWith("_dwnsz")){
                mediasPotentiallyDownsizing.push(media);
            }
        });
    }
    if (story.tourMediasRef){
        story.tourMediasRef.forEach((media) => {
            if (!getFileNameWithoutFormat(media).endsWith("_dwnsz")){
                mediasPotentiallyDownsizing.push(media);
            }
        });
    }

    if (mediasPotentiallyDownsizing.length === 0){        
        return true;
    }

    let ready = true;

    const storage = getStorage();       
    for (const media of mediasPotentiallyDownsizing){
        let replaceString = "https://storage.googleapis.com/" + process.env.REACT_APP_FIREBASE_STORAGE_BUCKET + "/";        
        const storagePath = media.replace(replaceString, "");  
        const storageRef = ref(storage, storagePath);  
        await getMetadata(storageRef).then((metadata) => { 
            if (metadata.customMetadata && metadata.customMetadata.published_for){
                ready = false;                        
            }            
        });  
        if (!ready){
            break;
        }     
    }         

    return ready;
}