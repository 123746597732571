import '../../Player.css';

import React,{ useContext } from 'react';
import { useTranslation } from 'react-i18next';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import WebIcon from '@mui/icons-material/Web';
import PhoneIcon from '@mui/icons-material/Phone';
import ChatIcon from '@mui/icons-material/Chat';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { analyticsManager } from '../../../index';
import { bookType } from '../components/PrimaryButton';
import { PlayerContext } from '../../PlayerContext';
import DialogContainer from '../components/DialogContainer';
import * as Sentry from "@sentry/react";
import {getDomainName} from '../../Utils';

export const internetBookingType = "internetBooking";
export const internetOrderingType = "internetOrdering";
export const internetSecondaryType = "internetSecondary";
export const phoneType = "phone";
export const chatType = "chat";

function BookDialog(props){
    const { t } = useTranslation();
    const { onClose, selectedValue, opened } = props;
    const playMode = useContext(PlayerContext).get("playMode");   
    const miniMultiplier = useContext(PlayerContext).get("miniMultiplier");  

    let showOnline = {};
    const hide = {
        display: 'none',
    };
    if (props.optionsToShow.webLink == null || props.optionsToShow.webLink === '' || props.story.posBookingLink === "googlemaps" && props.story.noGMAPS){            
        showOnline = hide;
    }
    let showPhone = {};
    if (props.optionsToShow.phone == null || props.optionsToShow.phone === '' || props.storyId === "LnSX2qKrthKsRmr1vcxc" || props.storyId === "ZcJ9o5kOjzeIvIZ4z0kw" || props.storyId === "zUEpyuVwo5Tws3hnvFds" || props.storyId === "YPuRVqGYO2Q2QFKEkRvQ"){
        showPhone = hide;
    }
    let showChat = {};    
    if (props.optionsToShow.chatLink == null || props.optionsToShow.chatLink === ''){        
        showChat = hide;
    }   
  
    const selectOption = (bookingType, value = null, label = null) => {
        onClose(bookingType, value, label);
    };

    const orderingPlatformsList = [];
    if (props.story.orderingPlatforms != null){        
        for (let i = 0; i < props.story.orderingPlatforms.length; i++) {
            orderingPlatformsList[i] =             
                    <ListItemButton onClick={() => selectOption(internetOrderingType, props.story.orderingPlatforms[i])} key={i}>                
                        <ListItemText className="booking-option" primary={getOrderingPlatform(props.story.orderingPlatforms[i])} style={{marginLeft: 8, }}/>
                    </ListItemButton>;            
        }
    }    

    const title = props.titleType === bookType ? "BookTable" : "Contact";

    function showOrderingPlatforms(){
        if (props.story.orderingPlatforms != null){
            return (
                <>
                    <DialogTitle style={{userSelect: 'none'}}>{t('OrderOn')}</DialogTitle>
                    <List>
                        {orderingPlatformsList}
                    </List>
                </>
            );
        }
        else return null;
    }

    return (    
        <DialogContainer storyId={props.storyId} opened={opened} onClose={()=>{onClose(null);}}>
            <div onClick={(e)=>e.stopPropagation()}>
                {props.storyId !== "cJU0Fnom8MTjmokacI3I" &&
                <>
                    <DialogTitle style={{ userSelect: 'none', WebkitUserSelect: 'none', width: 240 * miniMultiplier }}>{t(title)}&nbsp;{props.titleType === bookType && t('BookTableInfoEmoji')}{props.titleType !== bookType && t('ContactInfoEmoji')}{props.titleType === bookType && (<div style={{display: 'flex', flexDirection: "row", alignItems: "baseline"}}><div className='booking-title'>{t('BookTableInfo')}</div></div>)}</DialogTitle>
                    <List>
                        <ListItemButton onClick={() => selectOption(internetBookingType)} key={internetBookingType} style={showOnline}>
                            <ListItemIcon>
                                <InsertInvitationIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24 }} />
                            </ListItemIcon>
                            <ListItemText className="booking-option" primary={t('BookOnline')} />
                        </ListItemButton>
                        <ListItemButton onClick={() => selectOption(phoneType)} key={phoneType} style={showPhone}>
                            <ListItemIcon>
                                <PhoneIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24, }} />
                            </ListItemIcon>
                            <ListItemText className="booking-option" primary={t('BookPhone')} />
                        </ListItemButton>
                        {props.storyId  === "zUEpyuVwo5Tws3hnvFds" && 
                            <>
                                <ListItemButton onClick={() => selectOption(phoneType, "05 57 34 94 78", "Bordeaux Gambetta")} key={phoneType}>
                                    <ListItemIcon>
                                        <PhoneIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24, }} />
                                    </ListItemIcon>
                                    <ListItemText className="booking-option" primary={"Bordeaux Gambetta"} />
                                </ListItemButton>
                                <ListItemButton onClick={() => selectOption(phoneType, "05 56 94 79 83", "Bordeaux Belcier")} key={phoneType}>
                                    <ListItemIcon>
                                        <PhoneIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24, }} />
                                    </ListItemIcon>
                                    <ListItemText className="booking-option" primary={"Bordeaux Belcier"} />                            
                                </ListItemButton>
                            </>
                        }
                        {props.storyId  === "LnSX2qKrthKsRmr1vcxc" && 
                            <>
                                <ListItemButton onClick={() => selectOption(phoneType, "04 78 62 39 11", "Lyon Part-Dieu")} key={"04 78 62 39 11"}>
                                    <ListItemIcon>
                                        <PhoneIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24, }} />
                                    </ListItemIcon>
                                    <ListItemText className="booking-option" primary={"Lyon Part-Dieu"} />
                                </ListItemButton>
                                <ListItemButton onClick={() => selectOption(phoneType, "09 73 89 49 09", "Lyon Opéra")} key={"09 73 89 49 09"}>
                                    <ListItemIcon>
                                        <PhoneIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24, }} />
                                    </ListItemIcon>
                                    <ListItemText className="booking-option" primary={"Lyon Opéra"} />                            
                                </ListItemButton>
                            </>
                        }
                        {props.storyId  === "ZcJ9o5kOjzeIvIZ4z0kw" && 
                            <>
                                <ListItemButton onClick={() => selectOption(phoneType, "03 90 22 75 03", "Strasbourg Bateliers")} key={"03 90 22 75 03"}>
                                    <ListItemIcon>
                                        <PhoneIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24, }} />
                                    </ListItemIcon>
                                    <ListItemText className="booking-option" primary={"Strasbourg Bateliers"} />
                                </ListItemButton>
                                <ListItemButton onClick={() => selectOption(phoneType, "09 78 80 87 44", "Wacken")} key={"09 78 80 87 44"}>
                                    <ListItemIcon>
                                        <PhoneIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24, }} />
                                    </ListItemIcon>
                                    <ListItemText className="booking-option" primary={"Wacken"} />                            
                                </ListItemButton>
                            </>
                        }
                        {(props.storyId  === "YPuRVqGYO2Q2QFKEkRvQ" || props.storyId === "ABDe0HTpOfX5iVYDqrcJ") && 
                            <><List style={{overflowY: "scroll", height: 162 * miniMultiplier}}>
                                <ListItemButton onClick={() => selectOption(phoneType, "01 42 86 15 09", "Montorgueil")} key={"01 42 86 15 09"}>
                                    <ListItemIcon>
                                        <PhoneIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24, }} />
                                    </ListItemIcon>
                                    <ListItemText className="booking-option" primary={"Montorgueil"} />
                                </ListItemButton>
                                <ListItemButton onClick={() => selectOption(phoneType, "09 86 17 34 73", "Gobelins")} key={"09 86 17 34 73"}>
                                    <ListItemIcon>
                                        <PhoneIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24, }} />
                                    </ListItemIcon>
                                    <ListItemText className="booking-option" primary={"Gobelins"} />                            
                                </ListItemButton>
                                <ListItemButton onClick={() => selectOption(phoneType, "01 45 35 69 72", "Le Marais")} key={"01 45 35 69 72"}>
                                    <ListItemIcon>
                                        <PhoneIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24, }} />
                                    </ListItemIcon>
                                    <ListItemText className="booking-option" primary={"Le Marais"} />
                                </ListItemButton>
                                <ListItemButton onClick={() => selectOption(phoneType, "01 40 89 32 23", "Oxygen (La Défense)")} key={"01 40 89 32 23"}>
                                    <ListItemIcon>
                                        <PhoneIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24, }} />
                                    </ListItemIcon>
                                    <ListItemText className="booking-option" primary={"Oxygen (La Défense)"} />                            
                                </ListItemButton>
                                <ListItemButton onClick={() => selectOption(phoneType, "09 81 81 88 33", "Victoire")} key={"09 81 81 88 33"}>
                                    <ListItemIcon>
                                        <PhoneIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24, }} />
                                    </ListItemIcon>
                                    <ListItemText className="booking-option" primary={"Victoire"} />
                                </ListItemButton>
                                <ListItemButton onClick={() => selectOption(phoneType, "01 42 29 93 24", "Batignolles")} key={"01 42 29 93 24"}>
                                    <ListItemIcon>
                                        <PhoneIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24, }} />
                                    </ListItemIcon>
                                    <ListItemText className="booking-option" primary={"Batignolles"} />                            
                                </ListItemButton>
                                <ListItemButton onClick={() => selectOption(phoneType, "01 40 16 09 36", "Rosa Parks")} key={"01 40 16 09 36"}>
                                    <ListItemIcon>
                                        <PhoneIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24, }} />
                                    </ListItemIcon>
                                    <ListItemText className="booking-option" primary={"Rosa Parks"} />
                                </ListItemButton>
                                <ListItemButton onClick={() => selectOption(phoneType, "01 43 25 68 20", "Montparnasse")} key={"01 43 25 68 20"}>
                                    <ListItemIcon>
                                        <PhoneIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24, }} />
                                    </ListItemIcon>
                                    <ListItemText className="booking-option" primary={"Montparnasse"} />                            
                                </ListItemButton>
                                </List>
                            </>
                        }
                        <ListItemButton onClick={() => selectOption(chatType)} key={chatType} style={showChat}>
                            <ListItemIcon>
                                <ChatIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24 }} />
                            </ListItemIcon>
                            <ListItemText className="booking-option" primary={t('BookChat')} />
                        </ListItemButton>
                    </List>
                </>
                }
                {props.storyId === "cJU0Fnom8MTjmokacI3I" &&
                <>
                    <DialogTitle style={{ userSelect: 'none', WebkitUserSelect: 'none', width: 240 * miniMultiplier }}>{t("Apply")} {t('ApplyEmoji')}</DialogTitle>
                    <List>
                        <ListItemButton onClick={() => selectOption(internetBookingType)} key={internetBookingType} style={showOnline}>
                            <ListItemIcon>
                                <AssignmentIndIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24 }} />
                            </ListItemIcon>
                            <ListItemText className="booking-option" primary={t('ApplyButton')} />
                        </ListItemButton>
                    </List>
                    <DialogTitle style={{userSelect: 'none'}}>{t('Contact')}</DialogTitle>
                    <List>
                    <ListItemButton onClick={() => selectOption(internetSecondaryType)} key={internetBookingType} style={showOnline}>
                        <ListItemIcon>
                            <WebIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24 }} />
                        </ListItemIcon>
                        <ListItemText className="booking-option" primary={t('BookOnline')} />
                    </ListItemButton>
                    <ListItemButton onClick={() => selectOption(phoneType)} key={phoneType} style={showPhone}>
                        <ListItemIcon>
                            <PhoneIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24, }} />
                        </ListItemIcon>
                        <ListItemText className="booking-option" primary={t('BookPhone')} />
                    </ListItemButton>
                    <ListItemButton onClick={() => selectOption(chatType)} key={chatType} style={showChat}>
                        <ListItemIcon>
                            <ChatIcon className="booking-option" style={{marginLeft: 8, width: 24, height: 24 }} />
                        </ListItemIcon>
                        <ListItemText className="booking-option" primary={t('BookChat')} />
                    </ListItemButton>
                    </List>
                </>
                }
                {showOrderingPlatforms()}
            </div>
        </DialogContainer>        
    );

    function getOrderingPlatform(url) {
    
        if (url.includes("just-eat"))
            return "Just Eat";
        else if (url.includes("deliveroo"))
            return "Deliveroo";
        else if (url.includes("ubereats"))
            return "Uber Eats";
        else if (url.includes("byclickeat"))
            return "Clickeat";
        else if (url.includes("sundayapp"))
            return "Sunday";
        else if (url.includes("upreality") && url.includes("1")){
            return "À table !";
        }
        else if (url.includes("upreality") && url.includes("2")){
            return "Hep Serveur";
        }
        else {
            try {
                return (new URL(url)).hostname;
            }
            catch(error){
                Sentry.captureException(error);
                return url;
            }
        }
    }
}


export default BookDialog;