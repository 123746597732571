import '../../Player.css';

import React,{useState, useContext, useEffect} from 'react';
import { PlayerContext } from '../../PlayerContext';
import { useTranslation } from 'react-i18next';
import { analyticsManager } from '../../../index';
import BookDialog, {phoneType, internetBookingType, chatType, internetOrderingType, internetSecondaryType} from './BookDialog';
import ShareDialog from './ShareDialog';
import DialogTitle from '@mui/material/DialogTitle';
import {browserLocales, checkMobile, getDomainName, isAndroid, isInstagramInAppBrowser, isIOS, isSnapchatInAppBrowser, isTablet, isTikTokInAppBrowser, openFullscreen} from '../../Utils';
import PrimaryButton, {bookType, applyType, contactType} from '../components/PrimaryButton';
import WebPageMock from '../components/WebPageMock';
import DialogContainer from '../components/DialogContainer';
import IconButton from '@mui/material/IconButton';
import PhoneIcon from '@mui/icons-material/Phone';
import { useMediaQuery } from 'react-responsive';
import DeviceDetector from "device-detector-js";

function BookButton(props){
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedPhone, setSelectedPhone] = useState(null);
    const [isOpened, setIsOpened] = useState(false);

    const pauseVideo = useContext(PlayerContext).get("pauseVideo");
    const activeFeature = useContext(PlayerContext).get("activeFeature");
    const setActiveFeature = useContext(PlayerContext).get("setActiveFeature");
    const appAnalyticsManager = useContext(PlayerContext).get("appAnalyticsManager");    
    const propagateFocusChange = useContext(PlayerContext).get("propagateFocusChange");
    const playMode = useContext(PlayerContext).get("playMode");
    const [frameOpened, setFrameOpened] = useState(false);
    const [frameWithImageOverWebsite, setFrameWithImageOverWebsite] = useState(true);
    const [extendedFrame, setExtendedFrame] = useState(false);
    const storyDimensions = useContext(PlayerContext).get("storyDimensions");
    const setPlayAudio = useContext(PlayerContext).get("setPlayAudio");
    const audioAlreadyDisabled = useContext(PlayerContext).get("audioAlreadyDisabled");
    const playAudio = useContext(PlayerContext).get("playAudio");
    const playerOnDesktop = useContext(PlayerContext).get("playerOnDesktop");
    const fromMaker = useContext(PlayerContext).get("fromMaker");
    const fromDemo = useContext(PlayerContext).get("fromDemo");

    let btnType = null; 
    if (!props.story){
        btnType = null;
    }
    else if (props.storyId === "cJU0Fnom8MTjmokacI3I" || props.storyId === "WX2KsZRK4SNKT9hnNIp0"){
        btnType = applyType;
    }
    else if ((props.story.posBookingLink == null || props.story.posBookingLink === "none") /*&& props.story.orderingPlatforms == null*/ && !props.story.posBookingByPhone || props.story.posId === "ChIJC4g4KBNx5kcRH_z3vKUpnZw"){
        btnType = contactType;
    }
    else {
        btnType = bookType;
    }

    const { t } = useTranslation();
    
    const open = () => {          
        if (!fromMaker && !fromDemo && !playerOnDesktop && !audioAlreadyDisabled && !playAudio){            
            const ambiantPlayer = document.getElementById("audio-player");
            if (ambiantPlayer && ambiantPlayer.paused){
                ambiantPlayer.play();
            }
            setPlayAudio(true);
        }
        pauseVideo();
        openFullscreen(); 
        setActiveFeature("book");
        setFrameOpened(false);
        setExtendedFrame(false);
        setIsOpened(true);
        
        analyticsManager.logOpenPopupTag(props.storyId, "book", true, playMode);

        if (appAnalyticsManager)
            appAnalyticsManager.clickedBook();

        if (window.ttq){
            window.ttq.track('InitiateOrder', {                
                "contents": [
                    {              
                        "content_id": "0",           
                        "content_name": "main " + btnType + " button",
                    }
                ],
            });
        }
        if (window.fbq){                    
            window.fbq('trackCustom', 'InitiateOrder', {"content_name": "main " + btnType + " button"});
        }
      };

    const closeBookingMenu = () => {
        setIsOpened(false);
        setActiveFeature(null);
        analyticsManager.logOpenPopupTag(props.storyId, "book", false, playMode);
    }; 
    
    useEffect(() => {
        if (props.story.posBookingLink && !frameWithImageOverWebsite && frameOpened){
            setFrameOpened(props.story.posBookingLink);
        }
    }
    , [props.story]);
        
    if (props.show && props.story.posId != null && props.placeDetails != null && (props.story.posPhone != null || props.placeDetails.formatted_phone_number != null || props.story.posBookingLink != null || props.story.orderingPlatforms != null || props.story.posChatLink != null)){

        if (isOpened && activeFeature === "book"){
            const optionsToShow = {};

            if (props.story.email != null){
                optionsToShow.email = props.story.email;
            }

            if (props.storyId === "cJU0Fnom8MTjmokacI3I"){
                optionsToShow.phone = "01 76 35 12 43";
            }
            else if (props.placeDetails.formatted_phone_number != null && props.story.posPhone == null){
                optionsToShow.phone = props.placeDetails.formatted_phone_number;
            }
            else if (props.story.posPhone != null && props.story.posPhone !== "none"){
                optionsToShow.phone = props.story.posPhone;                
            }
            if (props.story.posBookingLink != null){
                if (props.story.posBookingLink === "googlemaps")
                    optionsToShow.webLink = props.placeDetails.url;
                else
                    optionsToShow.webLink = props.story.posBookingLink;
            }
            if (props.story.posChatLink != null){
                optionsToShow.chatLink = props.story.posChatLink;
            }
        
            const close = (bookingType, value = null, label = null) => {                  
                if (bookingType !== phoneType){     
                    setSelectedValue(null);               
                    if (bookingType === internetBookingType){                        
                        if (playMode !== "demo" && props.storyId != null && props.storyId.toLowerCase() !== "cafeupreality"){                             
                            if (optionsToShow.webLink.startsWith("https://bookings.zenchef.com")){                                
                                const langParam = browserLocales(true)[0] !== "fr" ? "&lang=en" : "";
                                setFrameOpened(optionsToShow.webLink + langParam);
                                setFrameWithImageOverWebsite(false);
                            }
                            else {
                                closeBookingMenu();
                                propagateFocusChange(false);
                                window.open(optionsToShow.webLink);                                
                            }
                            analyticsManager.logBookTag(props.storyId, playMode, "website");  
                            if (window.ttq){                                
                                window.ttq.track('InitiateBooking', {                
                                    "contents": [
                                        {              
                                            "content_id": "0",           
                                            "content_name": "initiate a booking on an external website",
                                        }
                                    ],
                                });                                
                            }  
                            if (window.fbq){                    
                                window.fbq('trackCustom', 'InitiateBooking', {"content_name": "initiate a booking on an external website"});
                            }
                        }                    
                        else{
                            setFrameOpened("/images/demo_book.png");
                            setFrameWithImageOverWebsite(true);
                        }
                    }  
                    else if (bookingType === internetSecondaryType){  
                        closeBookingMenu();
                        propagateFocusChange(false);
                        window.open("https://www.holbertonschool.fr/contact");                                
                        analyticsManager.logBookTag(props.storyId, playMode, "website");
                        if (window.ttq){
                            window.ttq.track('InitiateContact', {                
                                "contents": [
                                    {              
                                        "content_id": "0",           
                                        "content_name": "initiate contact on website",
                                    }
                                ],
                            });
                        }
                        if (window.fbq){                    
                            window.fbq('trackCustom', 'InitiateContact', {"content_name": "initiate contact on website"});
                        } 
                    }
                    else if (bookingType === internetOrderingType){                                                     
                        /*if (value.startsWith("https://www.bioburger.fr/commander/")){                                                            
                            setFrameOpened(value);
                            setExtendedFrame(true);
                            setFrameWithImageOverWebsite(false);
                        }
                        else {*/
                            closeBookingMenu();
                            propagateFocusChange(false);
                            window.open(value);                                
                        //}
                        analyticsManager.logBookTag(props.storyId, playMode, "ordering_platform");
                        if (window.ttq){
                            window.ttq.track('InitiateOrder', {                
                                "contents": [
                                    {              
                                        "content_id": "0",           
                                        "content_name": "initiate order on " + getDomainName(bookingType),
                                    }
                                ],
                            });
                        }
                        if (window.fbq){                    
                            window.fbq('trackCustom', 'InitiateOrder', {"content_name": "initiate order on " + getDomainName(bookingType)});
                        }                     
                    }      
                    else if (bookingType === chatType){
                        if (playMode !== "demo" && props.storyId != null && props.storyId.toLowerCase() !== "cafeupreality"){                            
                            closeBookingMenu();
                            propagateFocusChange(false);
                            window.open(optionsToShow.chatLink);
                            analyticsManager.logBookTag(props.storyId, playMode, "chat");
                            if (window.ttq){                                
                                if (btnType === bookType){
                                    window.ttq.track('InitiateBooking', {                
                                        "contents": [
                                            {              
                                                "content_id": "0",           
                                                "content_name": "initiate a chat",
                                            }
                                        ],
                                    });
                                }
                                else if (btnType === contactType){
                                    window.ttq.track('InitiateContact', {                
                                        "contents": [
                                            {              
                                                "content_id": "0",           
                                                "content_name": "initiate a chat",
                                            }
                                        ],
                                    });
                                }
                            }
                            if (window.fbq){                                   
                                if (btnType === bookType){
                                    window.fbq('trackCustom', 'InitiateBooking', {"content_name": "initiate a chat"});
                                }
                                else if (btnType === contactType){
                                    window.fbq('trackCustom', 'InitiateContact', {"content_name": "initiate a chat"});
                                }                                
                            }
                        }
                        else {
                            setFrameOpened("/images/demo_instagram.webp");
                            setFrameWithImageOverWebsite(true);
                        }
                    }
                    else {
                        closeBookingMenu();
                    }
                }
                else {                   
                    const deviceDetector = new DeviceDetector();
                    const device = deviceDetector.parse(window.navigator.userAgent);                                
                    analyticsManager.logBookTag(props.storyId, playMode, "phone");
                    if (window.ttq){                        
                        if (btnType === bookType){
                            window.ttq.track('InitiateBooking', {                
                                "contents": [
                                    {              
                                        "content_id": "0",           
                                        "content_name": "initiate a phone call" + (label ? " for " + label : ""),
                                    }
                                ],
                            });
                        }
                        else if (btnType === contactType){
                            window.ttq.track('InitiateContact', {                
                                "contents": [
                                    {              
                                        "content_id": "0",           
                                        "content_name": "initiate a phone call" + (label ? " for " + label : ""),
                                    }
                                ],
                            });
                        }
                    }
                    if (window.fbq){                                   
                        if (btnType === bookType){
                            window.fbq('trackCustom', 'InitiateBooking', {"content_name": "initiate a phone call" + (label ? " for " + label : "")});
                        }
                        else if (btnType === contactType){
                            window.fbq('trackCustom', 'InitiateContact', {"content_name": "initiate a phone call" + (label ? " for " + label : "")});
                        }                                
                    }
                    if (playMode !== "demo" && props.storyId != null && props.storyId.toLowerCase() !== "cafeupreality"){
                        if (device.device.type === "smartphone" && ! (isIOS() && isSnapchatInAppBrowser())){
                            window.location.href = "tel:" + (value ? value : optionsToShow.phone);
                        }                          
                        else {                    
                            setSelectedValue(bookingType);
                            setSelectedPhone(value ? value : optionsToShow.phone);
                        }
                    }
                    else {
                        setFrameOpened("/images/demo_phone.webp");
                        setFrameWithImageOverWebsite(true);
                    }
                }
                
            };    

            if (selectedValue == null){
                if (frameOpened){
                    return (
                        <div style={{position: "absolute", width: storyDimensions.containerWidth, height: storyDimensions.containerHeight, display: "flex", justifyContent: "center", alignItems: "center", }}>
                            <div className="BlockingOverlay" style={{zIndex: 10, width: storyDimensions.containerWidth, height: storyDimensions.containerHeight, }} /> 
                            <WebPageMock url={frameOpened} imageOverWebsite={frameWithImageOverWebsite} close={()=>{setFrameOpened(false);setExtendedFrame(false);setFrameWithImageOverWebsite(true);closeBookingMenu();propagateFocusChange(false);}} open={frameOpened} width={storyDimensions.containerWidth - (frameWithImageOverWebsite? 0 : (extendedFrame ? 10 : 32))} height={storyDimensions.containerHeight - (frameWithImageOverWebsite ? 0 : 128)} iframeStyle={frameWithImageOverWebsite ? {} : {borderRadius: 20, borderWidth: 0} }/>
                        </div>
                    );
                }     
                else {
                    return (
                        <BookDialog selectedValue={() => setSelectedValue()} storyId={props.storyId} story={props.story} opened={isOpened} onClose={close} optionsToShow={optionsToShow} titleType={btnType} />
                    );
                }
            }
            else if (selectedValue === phoneType){
                const deviceDetector = new DeviceDetector();
                const device = deviceDetector.parse(window.navigator.userAgent);                
                
                if (device.device.type === "smartphone"){
                    return null;
                }
                else {
                    if (frameOpened){
                        return (
                            <WebPageMock url={frameOpened} imageOverWebsite={frameWithImageOverWebsite} close={()=>{setFrameOpened(false);setExtendedFrame(false);setFrameWithImageOverWebsite(true);closeBookingMenu();}} open={frameOpened} width={storyDimensions.containerWidth} height={storyDimensions.containerHeight}/>
                        );
                    }     
                    else {
                        return (
                            <DialogContainer onClose={() => {setSelectedValue(null);setSelectedPhone(null);closeBookingMenu();}} opened={isOpened} >
                                <DialogTitle>{selectedPhone}{(device.device.type === "tablet" || device.device.type === "desktop") && <IconButton aria-label="call" style={{marginLeft: 8, color: "#49454F"}} onClick={()=>{window.open("tel:" + selectedPhone);}}><PhoneIcon /></IconButton>}</DialogTitle>
                            </DialogContainer>
                        );
                    }
                }
            }                   
            else{
                return null;
            }
        }
        else {            
            return <PrimaryButton buttonType={btnType} open={open} story={props.story} storyId={props.storyId}/>;            
        }
    }
    else return null;
}

export default BookButton;