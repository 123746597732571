import React, {useContext, useState} from 'react';
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MaterialButton from './../maker/component/MaterialButton';
import ConfirmDialog from '../maker/component/ConfirmDialog';
import { analyticsManager } from '..';
import { LoggedContext } from './LoggedContext';
import { AppContext } from '../AppContext';
import { cleanMakerQueryString, updateStoryOrGoToStories } from '../maker/MakerController';
import LoginDialog from '../maker/LoginDialog';

function MyAccountMenu(props) {
    const [showMyAccount, setShowMyAccount] = useState(false); 
    const [anchorMyAccount, setAnchorMyAccount] = useState(null);
    const [showDisconnectConfirm, setShowDisconnectConfirm] = useState(false);
    const fbResponse = useContext(AppContext).get("fbResponse");
    const [showLogin, setShowlogin] = useState(false);
    let setActivePage = null;
    let uid = null;
    const loggedContext = useContext(LoggedContext);
    if (loggedContext != null) {
        setActivePage = loggedContext.get("setActivePage");
        uid = loggedContext.get("uid");
    }
    const navigate = useNavigate();
    const { t } = useTranslation();

    function toggleMyAccountMenu(e){
        if (showMyAccount){
            analyticsManager.logOpenPopupTag(null, "account_menu", false);
            setShowMyAccount(false);
            setAnchorMyAccount(null);
        }
        else {
            analyticsManager.logOpenPopupTag(null, "account_menu", true);
            setAnchorMyAccount(e.currentTarget.parentNode);
            setShowMyAccount(true);
        }
    }

    function handleClose(){
        analyticsManager.logOpenPopupTag(null, "account_menu", false);
        setShowMyAccount(false);
    }

    function logOut(){
        setShowDisconnectConfirm(true);  
        analyticsManager.logOpenPopupTag(null, "logout", true);         
    }

    function showMyAccountMenu(){
        if (showMyAccount){
            return (
                <Menu
                    id="basic-menu"
                    anchorEl={anchorMyAccount}
                    open={showMyAccount}
                    onClose={handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                    >                    
                    <MenuItem onClick={() => {analyticsManager.logOpenAccountMenuTag(props.storyId, "preferences");if (setActivePage == null) navigate("/logged/preferences"); else setActivePage("preferences"); setShowMyAccount(false);}}>{t('ManageMyAccount')}</MenuItem>
                    {<MenuItem onClick={() => {analyticsManager.logOpenAccountMenuTag(props.storyId, "help");if (setActivePage == null) navigate("/logged/help"); else setActivePage("help"); setShowMyAccount(false);}}>{t('Help')}</MenuItem>}
                    {(uid === "3L90kLcGGyW6XilZmjINqFOUDWH3" || uid === "WgpXG6xvvYUdpit8zuK1PDDmXNM2") && <MenuItem onClick={() => {if (setActivePage == null) navigate("/logged/menu-editor"); else setActivePage("menu-editor"); setShowMyAccount(false);}}>{t('MenuEditor')}</MenuItem>}
                    <MenuItem onClick={() => {analyticsManager.logOpenAccountMenuTag(props.storyId, "logout");logOut();}}>{t('LogOut')}</MenuItem>
                </Menu>
            );            
        }
        else return null;
    }
    function onLogin(user){        
        updateStoryOrGoToStories(user, props.storyId, props.story)
            .then((navigateToLogged)=>{
                const loginTitle = new URLSearchParams(window.location.search).get("login_title");
                const fromUseItPageWithLoginWithRedirect = loginTitle === t('Save');
                if (navigateToLogged || fromUseItPageWithLoginWithRedirect) 
                    navigate("/logged"); 
                else 
                    cleanMakerQueryString(props.story.baseStoryId != null ? props.story.baseStoryId : props.storyId);
            });
    }
            
    return (<div style={{position: "absolute", right: -0, marginTop: 16, marginRight: 32}}>
                {fbResponse != null && fbResponse.isAnonymous != null && ! fbResponse.isAnonymous &&
                    <MaterialButton style="filled-inversed-neutral" onClick={(e) => {toggleMyAccountMenu(e)}} value={t('MyAccount')}/>
                }
                {fbResponse != null && fbResponse.isAnonymous != null && fbResponse.isAnonymous &&
                    <>
                        <MaterialButton style="filled-inversed-neutral" onClick={()=>{analyticsManager.logOpenPopupTag(null, "maker", true);setShowlogin(true);}} value={t("Login")} />                        
                    </>
                }
                <LoginDialog origin="maker" showLogin={showLogin !== false} customLoginButton={showLogin != false} title={t('Login')} resetLoginDialog={setShowlogin} onSuccess={(result)=>onLogin(result.user)}/>
                {showMyAccountMenu()}    
                {showDisconnectConfirm && <ConfirmDialog showConfirm={showDisconnectConfirm} setShowConfirm={setShowDisconnectConfirm} dialogTitle={t('LogOut')} dialogText={t('LogOutWarning')} dialogAction={t('LogOut')} tagName="logout" onSuccess={()=>{
                        analyticsManager.logLogoutTag(true);
                        signOut(getAuth());  
                    }} />}
            </div>);
}

export default MyAccountMenu;