import './../../Player.css';
import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { PlayerContext } from '../../PlayerContext';
import { openFullscreen, getTextWidth, isAndroid, isInstagramInAppBrowser, isTikTokInAppBrowser, isFirefox } from '../../Utils';
import { analyticsManager } from '../../../index';
import SecondaryButton, {reviewsType} from '../components/SecondaryButton';
import { appAnalyticsManager } from '../../Player';
import * as Sentry from "@sentry/react";
import CloseButton from '../components/CloseButton';
import { useAnimate } from 'framer-motion';

function ReviewsButton(props){    
    const [isOpened, setIsOpened] = useState(false);

    const storyDimensions = useContext(PlayerContext).get("storyDimensions");
    const propagateFocusChange = useContext(PlayerContext).get("propagateFocusChange");
    const pauseVideo = useContext(PlayerContext).get("pauseVideo");
    const setActiveFeature = useContext(PlayerContext).get("setActiveFeature");
    const activeFeature = useContext(PlayerContext).get("activeFeature");
    const appAnalyticsManager = useContext(PlayerContext).get("appAnalyticsManager");
    const playMode = useContext(PlayerContext).get("playMode");
    const miniMultiplier = useContext(PlayerContext).get("miniMultiplier");
    const setPlayAudio = useContext(PlayerContext).get("setPlayAudio");
    const audioAlreadyDisabled = useContext(PlayerContext).get("audioAlreadyDisabled");
    const playerOnDesktop = useContext(PlayerContext).get("playerOnDesktop");
    const fromMaker = useContext(PlayerContext).get("fromMaker");
    const fromDemo = useContext(PlayerContext).get("fromDemo");
    const [showTexts, setShowTexts] = useState(false);

    const { t } = useTranslation();

    const [scope, animate] = useAnimate();

    useEffect(() => {
        if (props.placeDetails && props.show && isOpened && activeFeature === "reviews"){           
            const galleryOverlay = document.getElementById("gallery-overlay");
            if (galleryOverlay != null){  
                const animation = async () => {                       
                    animate(galleryOverlay, { opacity: 1}, {duration: 0.3});                    
                }
                
                animation();
            }                        
        }
    }, [props.placeDetails, props.show, isOpened, activeFeature]);

    if (props.story.posId == null) return (null);

    const reviewNbToShow = 3;

    const closeWidth = 55 * miniMultiplier;
    const closeHeight = 53 * miniMultiplier;
    const brushesWidth = miniMultiplier === 1 ? 328 : 328 * miniMultiplier * 0.85;
    const brushesHeight = miniMultiplier === 1 ? 204 : 204 * miniMultiplier * 0.85;
    const reviewTextWidth = miniMultiplier === 1 ? 240 : 240 * miniMultiplier * 0.85;
    const heightBtwNameNBg = 10 * miniMultiplier;
    const ratingTextsLeftMargin = (56 + 4) * miniMultiplier;
    const ratingVignettePadding = 4 * miniMultiplier;
    const reviewWidth = brushesWidth;
    const reviewHeight = brushesHeight + heightBtwNameNBg;
    const heightToText = 114 * miniMultiplier;
    const reviewsLayoutHeight = reviewNbToShow * reviewHeight;
    const closeBtnX = (storyDimensions.containerWidth - reviewWidth)/2 + reviewWidth - closeWidth;
    const closeBtnY = (storyDimensions.containerHeight - reviewsLayoutHeight)/2 + reviewsLayoutHeight - closeHeight + (miniMultiplier === 1 ? 40 : 44);
    const firstReviewY = (storyDimensions.containerHeight - reviewsLayoutHeight)/2;
    const firstReviewX = (storyDimensions.containerWidth - reviewWidth)/2; 
    //const googleX = (storyDimensions.containerWidth - reviewWidth)/2 + reviewWidth - closeWidth;
    const googleY = (storyDimensions.containerHeight - reviewsLayoutHeight)/2 + reviewsLayoutHeight + 20 * miniMultiplier;

    const open = () => {                       
        if (!fromMaker && !fromDemo && !playerOnDesktop && !audioAlreadyDisabled){            
            const ambiantPlayer = document.getElementById("audio-player");
            if (ambiantPlayer && ambiantPlayer.paused){
                ambiantPlayer.play();
            }
            setPlayAudio(true);
        }
        pauseVideo();
        openFullscreen();
        setActiveFeature("reviews");
        setIsOpened(true);

        analyticsManager.logCheckReviewsTag(props.storyId, playMode, true);
        
        if (appAnalyticsManager)
            appAnalyticsManager.clickedReviews();

        if (window.ttq){
            window.ttq.track('ViewContent', {                
                "contents": [
                    {              
                        "content_id": "0",           
                        "content_name": "reviews",
                    }
                ],
            });
        }
        if (window.fbq){                    
            window.fbq('track', 'ViewContent', {"content_name": "reviews"});
        }
    };

    const close = () => {
        propagateFocusChange(true);
        setIsOpened(false);
        setActiveFeature(null);
      };
    
    if (props.show && props.placeDetails && props.placeDetails.reviews && props.placeDetails.reviews.length > 0){        
        if (isOpened && activeFeature === "reviews"){
            let lineNb = 0;
            // snapchat / instagram tablet on ios            
            const smallScreenRatio = storyDimensions.containerHeight < 640 && miniMultiplier === 1;
            const additionalTopMargin = smallScreenRatio ? 3 * 32 /2 : 0;
            const spaceBtwReviews = smallScreenRatio ? -32 : 16 * miniMultiplier;

            const reviews = props.placeDetails.reviews.map((review) =>{                    
                const top = firstReviewY + additionalTopMargin + lineNb * (heightBtwNameNBg + brushesHeight + spaceBtwReviews); 
                const ratingBarWidth = Math.min(reviewWidth, ratingVignettePadding + ratingTextsLeftMargin + Math.max(getTextWidth(review.author_name, 18 * miniMultiplier + "px Roboto"), getTextWidth(review.rating + " - " + review.relative_time_description, 16 * miniMultiplier + "px Roboto") + (14 + 2 + 2) * miniMultiplier) + ratingVignettePadding + (- 1 + 4) * miniMultiplier);
                
                if (review.rating < 4) return null;
                
                lineNb = lineNb + 1;            
                if (lineNb <= reviewNbToShow){
                    return <div key={review.text} style={{position: 'absolute', top: top, left: firstReviewX, width: reviewWidth, height: reviewHeight, zIndex: 20}}>  
                        <img alt="reviews background" src="/images/reviews.png" onLoad={()=>setShowTexts(true)} style={{position: 'absolute', top: heightBtwNameNBg, width: brushesWidth, height: brushesHeight, pointerEvents: 'none'}}/>
                        <div className="reviews-background" style={{top: heightBtwNameNBg, width: brushesWidth, height: brushesHeight}}/>
                        <div id={"review-" + lineNb + "-rating"} className="review-rating-container" style={{top: 0, left:0, visibility: showTexts ? 'visible' : 'hidden'}}>
                            <div className="review-rating-vignette" style={{top: 1, left:1, width: ratingBarWidth, height: 46 * miniMultiplier, fontSize: 18 * miniMultiplier, padding: ratingVignettePadding, background: props.story.primaryColor}}>
                                <div className="review-rating-name" style={{marginLeft: ratingTextsLeftMargin, fontSize: 18 * miniMultiplier}}>
                                    {review.author_name}
                                </div>
                                <div className="review-rating-rating" style={{marginLeft: ratingTextsLeftMargin, fontSize: 15 * miniMultiplier}}>
                                {review.rating}
                                <img alt={t('Star')} src="/images/star.png" style={{width: 14 * miniMultiplier, height: 14 * miniMultiplier, marginLeft: 2 * miniMultiplier, pointerEvents: 'none'}}/>
                                &nbsp;-&nbsp;
                                <div style={{marginLeft: 2 * miniMultiplier}}>{review.relative_time_description}</div>                               
                                </div>
                            </div>
                            <img className="review-avatar" onLoad={(e) => e.target.style.color='white'} onError={(e) => e.target.style.color='transparent'} style={{color: 'transparent',top: 0, left:0, width: 56 * miniMultiplier, height: 56 * miniMultiplier, pointerEvents: 'none'}} alt={isFirefox() ? "":"reviewer avatar"} src={review.profile_photo_url} />                        
                        </div>
                        <div id={"review-" + lineNb + "-desc"} className="review-description" style={{top: miniMultiplier === 1 ? heightToText : heightToText - 6, width: reviewTextWidth, fontSize: 16 * miniMultiplier * 0.85, WebkitLineClamp: miniMultiplier === 1 ? 6 : 6, lineClamp: miniMultiplier === 1 ? 6 : 6, marginLeft: -4, opacity: showTexts ? 1.0 : 0}}>
                            {review.text}
                        </div>
                    </div>
                }
                else {
                    return null
                }            
            }
            );

            const styleOverride = {};
            if ((props.storyId === "LJUbpj5ZyMMdpqmdDc0e" || props.storyId === "dMS24cQaTXn47tuspxet" || props.storyId === "32etJrIVkk0BjlVtPLG1" || props.storyId === "iopJF8UCdIrnOgcYw6Tk"  || props.storyId === "LnSX2qKrthKsRmr1vcxc" || props.storyId === "PaBK2iq1At5um1V6nNGo" || props.storyId === "1iDXY4hOyo1PVyrvCmvG" || props.storyId === "4WlhflMBRftrhBabhzqL" || props.storyId === "G4uGdmkDklxxMumN9FIc" || props.storyId === "LQ8cYZF1BrqCbKYOI7g7" || props.storyId === "P4hss0ADGtE2wMY5fcrQ" || props.storyId === "YPuRVqGYO2Q2QFKEkRvQ" || props.storyId === "ZcJ9o5kOjzeIvIZ4z0kw" || props.storyId === "clexvJMGmwlGXjY8KLaT" || props.storyId === "nRyYRVND1xh34m32eGMa" || props.storyId === "rj1ZbTjACKeCMOqqAi4l" || props.storyId === "wfnhdxPJPy3v54dXJAr4" || props.storyId === "xlqSrGF4RJz9yfV2MQrr" || props.storyId === "zUEpyuVwo5Tws3hnvFds")){
                styleOverride.background = "#FFFFFFC0";
            } 

            return (
                <div className="reviews-container" style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight}} onClick={close}>
                    <div id="gallery-overlay" className="BlockingOverlay" style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight, opacity: 0, ...styleOverride}}/>
                    {reviews}                                
                    <CloseButton onClick={close} color={props.story.primaryColor} width={closeWidth} height={closeHeight} className="reviews-close" style={{top: closeBtnY, left: closeBtnX}} />
                    <img className="google" alt="google" src="/images/google_on_non_white.png" style={{top: googleY, width: 29 * miniMultiplier, height: 9 * miniMultiplier, pointerEvents: 'none',}}/>
                </div>
            );
        }
        else {
            if (props.placeDetails != null){
                return <SecondaryButton buttonType={reviewsType} open={open}/>;
            }
            else {
                return null;
            }
        }
    }
    else return null;
}

export default ReviewsButton;