import React, {useState, useEffect, useContext, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import {MakerContext} from "./MakerContext";
import { getFirestore, doc, deleteField, updateDoc, getDoc, deleteDoc } from "firebase/firestore";
import {getFileFormat, getFileNameFromUrl, isImg, isVideo, isIOS, browserLocales, isSafari, isFirefox} from '../player/Utils';
import MaterialButton from './component/MaterialButton';
import { useMediaQuery } from 'react-responsive';
import MobileMenu from './component/MobileMenu';
import {AppContext} from '../AppContext';
import Switch from '@mui/material/Switch';

import { MEDIA_TYPE_DAY_MENU, MEDIA_TYPE_MENU, cleanMakerQueryString, deleteExistingMediaContent, deleteFromStorage, updateMediasForTransformation } from './MakerController';
import ContentPickerDialog from './component/ContentPickerDialog';
import ConfirmDialog from './component/ConfirmDialog';
import { functions, analyticsManager } from '..';
import useInterval from './component/UseInterval';
import MobileBottomBar from './component/MobileBottomBar';
import MenuStylePreview from './component/MenuStylePreview';
import { Alert, Button, Snackbar } from '@mui/material';
import { httpsCallable } from 'firebase/functions';

function ChooseMenuForm(props) {
    const story = useContext(MakerContext).get("story");
    const storyId = useContext(MakerContext).get("storyId");
    const baseStory = useContext(MakerContext).get("baseStory");
    const setFeatureReset = useContext(MakerContext).get("setFeatureReset");
    const [showMenuStyle, setShowMenuStyle] = useState(false);
    const setHighlight = useContext(MakerContext).get("setHighlight");
    const fbResponse = useContext(AppContext).get("fbResponse");
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const [showPicker, setShowPicker] = useState(false);
    const [bypassContentPickerLogin, setBypassContentPickerLogin] = useState(false); 
    const activePopup = useContext(MakerContext).get("activePopup");
    const setActivePopup = useContext(MakerContext).get("setActivePopup");
    const [showPublishConfirm, setShowPublishConfirm] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    const [refreshStyle, setRefreshStyle] = useState(null);
    const [optimizedFormatAtOpening, setOptimizedFormatAtOpening] = useState(null);
    const [optimizedFormat, setOptimizedFormat] = useState(null);
    const instagramLoginType = useContext(MakerContext).get("instagramLoginType");
    const [showInfoCreateAccount, setShowInfoCreateAccount] = useState(false);
    const windowDimensions = useContext(MakerContext).get("windowDimensions");
    const { t } = useTranslation();
    
    const setActiveForm = useContext(MakerContext).get("setActiveForm");

    const touchStartPositionRef = useRef(null);
    const dragTime = useRef(0);

    const changeMenuStyleTemp = useRef();

    // iOS: menu preview does not refresh if we dont change the style -> we change the style and then we change it back for color change
    // TODO do not refresh style at start
    useEffect(() => {        
        if (props.show && (isIOS() || isSafari()) && story != null && story.menuColor != null){
            changeMenuStyleTemp.current = story.menuStyle;
            if (story.menuStyle === "simple"){
                setRefreshStyle("elegant");                    
            }
            else {
                setRefreshStyle("simple");
            }  
        }
    }, [props.show && (isIOS() || isSafari()) && story != null && optimizedFormat && story.menuColor]);

    useInterval(() => {
        if (changeMenuStyleTemp.current != null){
            setRefreshStyle(changeMenuStyleTemp.current);
            changeMenuStyleTemp.current = null;
        }
        else if (refreshStyle != null){
            setRefreshStyle(null);
        }
    }, props.show && (isIOS() || isSafari()) && (changeMenuStyleTemp.current != null || refreshStyle != null) ? 200 : null);

    useEffect(() => {
        if (story != null){
            setOptimizedFormatAtOpening(story.menuReformatDate != null);
            setOptimizedFormat(story.menuReformatDate != null);
        }
    }
    , [story]);

    useEffect(() => {
        if (props.show && (activePopup === MEDIA_TYPE_DAY_MENU)){   
            analyticsManager.logOpenPopupTag(storyId, "content_picker_day_menu", true);    
            setShowPicker(activePopup);
            setBypassContentPickerLogin(true);
            setActivePopup(null);
            if (instagramLoginType === "ig")
                cleanMakerQueryString(story.baseStoryId != null ? story.baseStoryId : storyId);
        }
      }, [activePopup && props.show]);

    function showMenuAdder(){
        if (story.withMenu == null || !story.withMenu){
            return (
                <div className='maker-gallery-container-add' onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "content_picker_full_menu", true);setShowPicker(MEDIA_TYPE_MENU);}}>
                    +
                </div>
            );
        }
    }

    function showDayMenuAdder(){
        if (story.withDayMenu == null || !story.withDayMenu){
            return (
                <div className='maker-gallery-container-add' onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "content_picker_day_menu", true);setShowPicker(MEDIA_TYPE_DAY_MENU);}}>
                    +
                </div>
            );
        }
    }              

    async function addDayMenuFileToStory(url, source, type){
        analyticsManager.logChooseDayMenuTag(storyId, source, type, true);                
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);
        const update = {
            dayMenuFile: url,  
            withDayMenu: true,      
            savedTime: new Date().getTime(),              
        };
        if (story.mediasRef == null){
            update.mediasCenterXpct = 50;
            update.mediasCenterYpct = 60;
        }
        
        await updateDoc(docRef, update);
        setTimeout(()=>{setHighlight("menu")}, 1000);
    }

    async function setMenuFile(url, source = null, type = null){        
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);

        const emptyUrl = url === "";
        if (emptyUrl){
            url = deleteField();
            const fileFormat = getFileFormat(getFileNameFromUrl(story.menuFile));            
            const menuType = fileFormat == null ? "web_link" : fileFormat === "pdf" ? "pdf" : isImg(fileFormat) ? "photo" : isVideo(fileFormat) ? "video" : "web_link"
            analyticsManager.logChooseFullMenuTag(storyId, "choose_menu_form", menuType, false);
        }
        else {
            analyticsManager.logChooseFullMenuTag(storyId, source, type, true);
        }
        const update = {
            withMenu: !emptyUrl,
            menuFile: url,            
            savedTime: new Date().getTime(),            
        };
        if (emptyUrl){
            update.menuId = deleteField();
            update.menuReformatDate = deleteField();
            update.menuOptimizedFormat = deleteField();
        }
        if (story.mediasRef == null){
            update.mediasCenterXpct = 50;
            update.mediasCenterYpct = 60;
        }
        
        await updateDoc(docRef, update);

        if (! emptyUrl)
            setTimeout(()=>{setHighlight("menu")}, 1000);
    }

    function deleteMenu(){        
        deleteExistingMediaContent(story, storyId, baseStory, MEDIA_TYPE_MENU)
            .then(()=>{setMenuFile("");setFeatureReset(true);}); 
    }

    function deleteDayMenu(){
        deleteExistingMediaContent(story, storyId, baseStory, MEDIA_TYPE_DAY_MENU)
            .then(()=>{
                analyticsManager.logChooseDayMenuTag(storyId, "choose_menu_form", "photo", false);
                deleteDayMenuFromStory();
                setFeatureReset(true);
            });                 
    }

    async function deleteDayMenuFromStory(){
        const db = getFirestore();        
        const docRef = doc(db, "stories", storyId);

        const update = {
            withDayMenu: false,
            dayMenuFile: deleteField(),
            savedTime: new Date().getTime(),
        };
        await updateDoc(docRef, update);
    }

    function showMenuSelection(){
        if (story.withMenu != null && story.withMenu){  
            let imgSrc= "";
            if (story.menuFile != null && !story.menuFile.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){
                imgSrc = "/images/web_menu.png";
            }
            else if (story.menuFile != null && isImg(getFileFormat(getFileNameFromUrl(story.menuFile)))){
                imgSrc = "/images/img_menu.png";
            }
            else if (story.menuFile != null && getFileFormat(getFileNameFromUrl(story.menuFile)).toLowerCase() == "pdf"){
                imgSrc = "/images/pdf_menu.png";
            }

            return (
                <div style={{position: 'relative'}} className="maker-menu-box">
                    <img alt="file type of the menu" src={imgSrc} />
                    <div style={{cursor: 'pointer', background: "#FFFFFF", borderTopLeftRadius: 4, position: 'absolute', bottom: 0, right: 0, padding: 2, width: 12, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={()=>deleteMenu()}>x</div>
                </div>
            );                                            
        }
        else return null;
    }

    function showDayMenuSelection(){
        if (story.withDayMenu != null && story.withDayMenu && story.dayMenuFile != null){  

            return (
                <div style={{position: 'relative', height: 100}}>
                    <img alt={isFirefox() ? "":"thumbnail of the day menu"} onLoad={(e) => e.target.style.color='white'} onError={(e) => e.target.style.color='transparent'} style={{color: 'transparent', borderRadius: 4,}} src={story.dayMenuFile} className='maker-day-menu-box-img' />
                    <div style={{cursor: 'pointer', background: "#FFFFFF", borderTopLeftRadius: 4, position: 'absolute', bottom: 0, right: 0, padding: 2, width: 12, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={()=>deleteDayMenu()}>x</div>
                </div>
            ); 
        }
        else return null;
    }

    async function publishStory(){
        setShowProgress(true);
        const db = getFirestore();    
        const editionVersionDocRef = doc(db, "stories", storyId);        
        const editedVersionDoc = await getDoc(editionVersionDocRef);
        // standard case
        if (editedVersionDoc.id !== editedVersionDoc.data().baseStoryId){            
            const baseDocRef = doc(db, "stories", editedVersionDoc.data().baseStoryId);        
            const update = editedVersionDoc.data();            
            update.publicationDate = new Date().getTime();
            update.savedTime = new Date().getTime();
            // stats            
            if (baseStory.duration != null) update.duration = baseStory.duration;
            if (baseStory.viewNb != null) update.viewNb = baseStory.viewNb;
            if (baseStory.shares != null) update.shares = baseStory.shares;
            if (baseStory.bookingIntentNb != null) update.bookingIntentNb = baseStory.bookingIntentNb;
            if (baseStory.itineraryNb != null) update.itineraryNb = baseStory.itineraryNb;
            if (baseStory.returnNb != null) update.returnNb = baseStory.returnNb;
            if (baseStory.rating != null) update.rating = baseStory.rating;            
            // WARNING FOR EVERY FIELD THAT CAN BE DELETED
            // already deleted in update, we need to update the base story with the deletion 
            if (baseStory.avatarUrl != null && update.avatarUrl == null){
                update.avatarUrl = deleteField();
            } 
            if (baseStory.avatarUrl != null && update.avatarUrl != baseStory.avatarUrl && baseStory.avatarUrl.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){                           
                deleteFromStorage(baseStory.avatarUrl);
            }
            if (baseStory.musicUrl != null && update.musicUrl == null){
                update.musicUrl = deleteField();
            } 
            if (baseStory.musicUrl != null && update.musicUrl != baseStory.musicUrl && baseStory.musicUrl.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){                           
                deleteFromStorage(baseStory.musicUrl);
            }
            if (baseStory.imageRef != null && update.imageRef == null){
                update.imageRef = deleteField();
            }         
            if (baseStory.imageRef != null && update.imageRef != baseStory.imageRef){                
                deleteFromStorage(baseStory.imageRef);
            }
            if (baseStory.videoRef != null && update.videoRef == null){
                update.videoRef = deleteField();
            }  
            if (baseStory.videoRef != null && update.videoRef != baseStory.videoRef){               
                deleteFromStorage(baseStory.videoRef);
            }   
            const videoChanged = update.videoRef != null && update.videoRef != baseStory.videoRef;
            const imageChanged = update.imageRef != null && update.imageRef != baseStory.imageRef
            const videoToImage = baseStory.videoRef != null && update.imageRef != null;
            const imageToVideo = baseStory.imageRef != null && update.videoRef != null;
            const noStoreFront = update.videoRef == null && update.imageRef == null;
            if (baseStory.shareTnRef != null && (videoChanged || imageChanged || videoToImage || imageToVideo || noStoreFront)){               
                update.shareTnRef = deleteField();
            }  
            if (baseStory.shareTnRef != null && (videoChanged || imageChanged || videoToImage || imageToVideo || noStoreFront)){               
                deleteFromStorage(baseStory.shareTnRef);
            } 
            if (baseStory.videoFSTnRef != null && (videoChanged || videoToImage || noStoreFront)){ 
                update.videoFSTnRef = deleteField();
            }  
            if (baseStory.videoFSTnRef != null && (videoChanged || videoToImage || noStoreFront)){
                deleteFromStorage(baseStory.videoFSTnRef);
            } 
            if (baseStory.tourMediasRef != null){
                for (let i = 0; i < baseStory.tourMediasRef.length; i++){
                    if (update.tourMediasRef == null || !Array.from(update.tourMediasRef).includes(baseStory.tourMediasRef[i])){                        
                        deleteFromStorage(baseStory.tourMediasRef[i]);
                    }
                }
            }                
            if (baseStory.tourMediasRef != null){                
                if (update.tourMediasRef == null){
                    update.tourMediasRef = deleteField();
                }
            }
                                   
            if (baseStory.mediasRef != null){
                for (let i = 0; i < baseStory.mediasRef.length; i++){
                    if (update.mediasRef == null || !Array.from(update.mediasRef).includes(baseStory.mediasRef[i])){                        
                        deleteFromStorage(baseStory.mediasRef[i]);
                    }
                }
            }
            if (baseStory.mediasRef != null){                
                if (update.mediasRef == null){
                    update.mediasRef = deleteField();
                }
            }

            if (baseStory.menuFile != null && update.menuFile == null){
                update.menuFile = deleteField();
            }  
            if (baseStory.menuFile != null && update.menuFile != baseStory.menuFile && baseStory.menuFile.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){               
                deleteFromStorage(baseStory.menuFile);
            } 

            if (baseStory.dayMenuFile != null && update.dayMenuFile == null){
                update.dayMenuFile = deleteField();
            }  
            if (baseStory.dayMenuFile != null && update.dayMenuFile != baseStory.dayMenuFile && baseStory.dayMenuFile.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){               
                deleteFromStorage(baseStory.dayMenuFile);
            } 
            if (baseStory.menuOptimizedFormat != null && update.menuOptimizedFormat == null){
                update.menuOptimizedFormat = deleteField();
            } 
            if (baseStory.menuReformatDate != null && update.menuReformatDate == null){
                update.menuReformatDate = deleteField();
            } 
            if (baseStory.menuId != null && update.menuId == null){
                update.menuId = deleteField();
            }
            if (baseStory.posChatLink != null && update.posChatLink == null){
                update.posChatLink = deleteField();
            } 
            if (baseStory.instagramProfileName != null && update.instagramProfileName == null){
                update.instagramProfileName = deleteField();
            } 
            if (baseStory.instagramProfileUserName != null && update.instagramProfileUserName == null){
                update.instagramProfileUserName = deleteField();
            } 
            if (baseStory.orderingPlatforms != null && update.orderingPlatforms == null){
                update.orderingPlatforms = deleteField();
            }             
            
            //
            update.baseStoryId = null;
            update.baseStory = true;
            update.neverPublishedAsLogged = deleteField();
            await updateDoc(baseDocRef, update);        
            await deleteDoc(editionVersionDocRef);    
            await updateMediasForTransformation(editedVersionDoc.data().baseStoryId, story, baseStory, story.uid);    
            const launchDownsizeMedias = httpsCallable(functions, "launchDownsizeMedias");
            launchDownsizeMedias({storyId: editedVersionDoc.data().baseStoryId});      
        }
        // case where the user is editing his first story created while he was not logged in
        else {            
            const update = {
                publicationDate: new Date().getTime(),                
                baseStoryId : null,
                baseStory: true,
                neverPublishedAsLogged: deleteField(),
                savedTime: new Date().getTime(),
            };
            await updateDoc(editionVersionDocRef, update);                        
            await updateMediasForTransformation(editedVersionDoc.id, story, null, story.uid);      
            const launchDownsizeMedias = httpsCallable(functions, "launchDownsizeMedias");
            launchDownsizeMedias({storyId: editedVersionDoc.id});         
        }          
        
        setShowProgress(false);
        setActiveForm("use-it");
    }

    function showUseItButton(){
        const screenWidthToMakerForm = isTabletOrMobile ? 0 : 360;
        if (fbResponse != null && (fbResponse.isAnonymous == null || ! fbResponse.isAnonymous)){
            return null;
        }
        else return (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', bottom: 16, right: screenWidthToMakerForm+16, position: 'absolute'}}>
                <MaterialButton style="filled-tonal" onClick={() => {analyticsManager.logUseItScreenViewTag(storyId);setActiveForm("use-it");}} value={t('MenuToUse')} />
            </div>
        );  
    }

    function showMenuStylePreview(){
            if (story.withMenu && fbResponse != null && !fbResponse.isAnonymous || fbResponse != null && fbResponse.isAnonymous){
                const label = { inputProps: { 'aria-label': 'TODO' } };

                return (
                    <div style={{marginTop: 10}}>                                          
                        {story.withMenu && fbResponse != null && !fbResponse.isAnonymous && 
                        <div style={{marginTop: 20, display: 'flex', flexDirection: "row", alignItems: 'center',}}>
                            <MaterialButton style="outlined-tonal" onClick={()=>{analyticsManager.logOpenPopupTag(storyId, optimizedFormat ? "menu_style" : "cover_style", true);setShowMenuStyle(true);}} value={t('ChangeStyle')}/>
                            <div style={{marginLeft: (refreshStyle != null ? refreshStyle : story.menuStyle) == 'modern' ? 130 : 80}}>
                                <MenuStylePreview menuStyleTemp={(refreshStyle != null ? refreshStyle : story.menuStyle)} menuColorTemp={story.menuColor} multiplier={2.0} optimizedFormat={optimizedFormat} />
                            </div>                        
                        </div> 
                        }
                        <div style={{marginTop: 16, display: 'flex', flexDirection: "row", alignItems: 'center',}}>
                            <div>
                                {t("OptimMyMenu")}
                                {story.withMenu && fbResponse != null && !fbResponse.isAnonymous && !optimizedFormat && <div style={{marginTop: 6, fontSize: 14, fontStyle: 'italic'}}>{t("OptimMyMenuHelp")}</div>}
                                {story.withMenu && fbResponse != null && !fbResponse.isAnonymous && optimizedFormat && <div style={{marginTop: 6,fontSize: 14, fontStyle: 'italic'}}>{t('OptimStatus', {date: new Date(story.menuReformatDate).toLocaleString(browserLocales(), new Date().getTimezoneOffset())})}</div>}                                       
                            </div>                 
                            <div style={{marginLeft: 40}}/>
                                <Switch {...label} defaultChecked={optimizedFormatAtOpening} checked={optimizedFormat} onChange={(e)=>
                                {
                                    if (fbResponse != null && fbResponse.isAnonymous){
                                        setShowInfoCreateAccount(true);
                                        return;
                                    }
                                    if (e.target.checked){ 
                                        analyticsManager.logOptimizeMenuTag(storyId, true);
                                        analyticsManager.logOpenPopupTag(storyId, "menu_style", true);
                                        setOptimizedFormat(true);
                                        setShowMenuStyle(true);
                                    }
                                    else {
                                        analyticsManager.logOptimizeMenuTag(storyId, false);
                                        setOptimizedFormat(false);
                                        setFeatureReset(true);
                                        const db = getFirestore();
                                        const docRef = doc(db, "stories", storyId);                                            
                                        updateDoc(docRef, {   
                                            menuOptimizedFormat: deleteField(),     
                                            menuReformatDate: deleteField(),
                                            savedTime: new Date().getTime(),
                                        });                                        
                                    }
                                }} />                                                                                                                                            
                            </div>                                                         
                    </div>    
                );
            }
    }

    function showInfoCreateAccountSnack(){
        if (showInfoCreateAccount){
            return (
                <Snackbar open={showInfoCreateAccount} message={t('InfoOptimMyMenu')} sx={{width : "100%"}} severity="info" autoHideDuration={4000} onClose={()=>setShowInfoCreateAccount(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}/>
            );
        }
        else return null;
    } 

    if (props.show) {

        let onSuccess = null;
        let selectedContents = [];
        let setSelectedContents = null;
        let mediaContentType = null;
        switch (showPicker){
           case MEDIA_TYPE_DAY_MENU:
                mediaContentType = MEDIA_TYPE_DAY_MENU;
                onSuccess = addDayMenuFileToStory;                
                break;
            case MEDIA_TYPE_MENU:
                mediaContentType = MEDIA_TYPE_MENU;
                onSuccess = setMenuFile;
                break;              
        }
                
        const screenWidthToMakerForm = isTabletOrMobile ? 0 : 360;
        const isMobileBottomBarDisplayed = isTabletOrMobile && fbResponse != null && fbResponse.isAnonymous != null && ! fbResponse.isAnonymous;
        const additionalStyle = isMobileBottomBarDisplayed ? {overflowY: "scroll", height: windowDimensions.height - 56} : {};

        return(
            <div>
                <div style={{padding: 16}}>
                    <div style={{...additionalStyle}}>
                        <div style={{fontSize: 28}}>{t('MyMenu')}</div>
                        <br/>
                        <div style={{fontSize: 20}}>{t('AddMyMenu')}<br/><span style={{fontStyle: 'italic', fontSize: 14}}>{t("AddMyMenuHelp")}</span></div>
                        <br/>
                        <div style={{display: 'flex', flexDirection: "row", alignItems: 'center'}}>
                            <div>
                                {showMenuSelection()}
                                {showMenuAdder()}
                            </div>                                          
                        </div>                        
                        {showMenuStylePreview()}                                        
                        <br/>
                        <div style={{fontSize: 20}}>{t('AddMyDayMenu')}<br/><span style={{fontStyle: 'italic', fontSize: 14}}>{t("ChooseMyInsideHelp")}</span></div>
                        <br/>
                        <div style={{display: 'flex', flexDirection: "row", alignItems: 'center'}}>
                            <div>
                                {showDayMenuSelection()}
                                {showDayMenuAdder()}
                            </div>                        
                        </div>
                        <MobileMenu storyId={storyId} story={story}/>                        
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', bottom: 16 + (isMobileBottomBarDisplayed ? 56 : 0), left: 16, position: 'absolute'}}>
                            <MaterialButton style="outlined-tonal" onClick={() => {analyticsManager.logChooseStoreFrontScreenViewTag(storyId);setActiveForm("store-front");}} value={t('MenuToStoreFront')}/>
                        </div>     
                        {fbResponse != null && (fbResponse.isAnonymous == null || ! fbResponse.isAnonymous) &&
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', bottom: 16 + (isMobileBottomBarDisplayed ? 56 : 0), right: screenWidthToMakerForm+16, position: 'absolute'}}>
                                <MaterialButton style="filled-tonal" onClick={() => {analyticsManager.logOpenPopupTag(storyId, "publish", true);setShowPublishConfirm(true);}} value={t('PublishMyChanges')}/>
                            </div>
                        }
                        {showUseItButton()}
                        <ContentPickerDialog showContentPickerDialog={showPicker} setShowContentPickerDialog={setShowPicker} selectedContents={selectedContents} setSelectedContents={setSelectedContents} mediaContentType={mediaContentType} bypassFromIG={bypassContentPickerLogin} resetBypassFromIg={setBypassContentPickerLogin} showMenuStyle={showMenuStyle} setShowMenuStyle={setShowMenuStyle} storyId={storyId} story={story} baseStory={baseStory} storyContentField={story.dayMenuFile} withProgress={true} onSuccess={onSuccess} optimizedFormat={optimizedFormat} setOptimizedFormat={setOptimizedFormat}/>    
                        {isMobileBottomBarDisplayed && <div style={{height: 100}}/>}
                    </div> 
                    {showPublishConfirm && <ConfirmDialog showConfirm={showPublishConfirm} setShowConfirm={setShowPublishConfirm} showProgress={showProgress} setShowProgress={setShowProgress} dialogTitle={t('PublishMyChanges')} dialogText={t('PublishWarning')} dialogAction={t('Publish')} tagName="publish" onSuccess={()=>{analyticsManager.logPublishTag(storyId);publishStory();}} />}      
                </div> 
                {showInfoCreateAccountSnack()}
                <MobileBottomBar currentTab="0" storyId={storyId} />
            </div>
        );
    }
    else return null;
}

export default ChooseMenuForm;