"use client";
import * as React from "react";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
} from "react-router-dom";
import './index.css';
import Player from './player/Player';
import Maker from './maker/Maker';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";
import {getWindowDimensions} from './player/Utils';
import * as Sentry from "@sentry/react";
import Logged from './logged/Logged';
import ProductPage from './ProductPage';
import InstantAdHP from './InstantAdHP';


import i18next from './i18n'
import '@fontsource/roboto';
import "@fontsource/roboto-condensed";
import "@fontsource/alex-brush";
import AppRoot from "./AppRoot";
import ErrorPage from "./ErrorPage";
import AnalyticsManager from "./analytics/AnalyticsManager";
import { getFunctions } from "firebase/functions";
import DemoInstantAd from "./DemoInstantAd";
import ErrorPageWithRedirect from "./ErrorPageWithRedirect";

Sentry.init({
  dsn: "https://fec78e2fbae74d429a654c6793d609c3@o1178872.ingest.sentry.io/6291166",
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({   
      maskAllText: false,
      blockAllMedia: false,   
    }),
  ],
  environment: process.env.NODE_ENV,
  beforeSend(event, hint) {
    // redirect to the website if the app launches an exception    
    /*setTimeout(()=>{      
      if (event.level !== "info" && webFallback !== ""){
        window.location = webFallback;
      }
    }, 1000);*/

    return event;
  },
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/upreality\.store\//, /^https:\/\/www\.upreality\.store\//],
});

const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,  
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};
//databaseURL: "https://upreality-1550998227636.firebaseio.com",

export let webFallback = "";
function setWebFallback(fallback){
  if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
    webFallback = fallback;
  }
}
export {setWebFallback};
export const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app, "europe-west3");
export const analytics = getAnalytics(app);
export const analyticsManager = new AnalyticsManager(analytics);
analyticsManager.setUserProperty();

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <ProductPage windowDimensions={getWindowDimensions()} />, 
    errorElement: <ErrorPage />, 
  },
  {
    path: "Instant-Ad",
    element: <InstantAdHP windowDimensions={getWindowDimensions()} />, 
    errorElement: <ErrorPage />, 
  },
  {
    path: "logged/:page?",
    element: <Logged/>,
    errorElement: <ErrorPage />, 
  }, 
  {
    path: "maker/:storyId?",
    element: <Maker/>,
    errorElement: <ErrorPage />, 
  }, 
  {
    path: "p/:storyId",
    element: 
      <Player fromMaker={false} windowDimensions={getWindowDimensions()} />
    ,
    errorElement: <ErrorPageWithRedirect />,
  },
  {
    path: "/DemoInstantAd",
    element: <DemoInstantAd />, 
    errorElement: <ErrorPage />, 
  },
]);

startApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function startApp(){
  createRoot(document.getElementById("root")).render(      
      <AppRoot router={router}/>     
  );
}
