import './../../Player.css';
import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { PlayerContext } from '../../PlayerContext';
import { openFullscreen } from '../../Utils';
import { analyticsManager } from '../../../index';
import SecondaryButton, {locationType} from '../components/SecondaryButton';
import { useAnimate } from 'framer-motion';
import CloseButton from '../components/CloseButton';

function LocationButtonChain(props){
    const [isOpened, setIsOpened] = useState(false);

    const storyDimensions = useContext(PlayerContext).get("storyDimensions");
    const propagateFocusChange = useContext(PlayerContext).get("propagateFocusChange");
    const pauseVideo = useContext(PlayerContext).get("pauseVideo");
    const setActiveFeature = useContext(PlayerContext).get("setActiveFeature");
    const activeFeature = useContext(PlayerContext).get("activeFeature");
    const appAnalyticsManager = useContext(PlayerContext).get("appAnalyticsManager");
    const playMode = useContext(PlayerContext).get("playMode");
    const miniMultiplier = useContext(PlayerContext).get("miniMultiplier");
    const setPlayAudio = useContext(PlayerContext).get("setPlayAudio");
    const audioAlreadyDisabled = useContext(PlayerContext).get("audioAlreadyDisabled");
    const playAudio = useContext(PlayerContext).get("playAudio");
    const playerOnDesktop = useContext(PlayerContext).get("playerOnDesktop");
    const fromMaker = useContext(PlayerContext).get("fromMaker");
    const fromDemo = useContext(PlayerContext).get("fromDemo");
    const [mapLoaded, setMapLoaded] = useState(false);

    const closeWidth = 42 * miniMultiplier;
    const closeHeight = 40 * miniMultiplier; 
    const closeBtnX = storyDimensions.containerWidth - closeWidth - 16 * miniMultiplier;
    const closeBtnY = storyDimensions.containerHeight - closeHeight - 12 * miniMultiplier;

    const { t } = useTranslation();    

    const [scope, animate] = useAnimate();

    let map;

    useEffect(() => {
        if (props.story && props.show && isOpened && activeFeature === "location"){           
            const galleryOverlay = document.getElementById("location-overlay");
            if (galleryOverlay != null){  
                const animation = async () => {                       
                    animate(galleryOverlay, { opacity: 1}, {duration: 0.3});                    
                }
                
                animation();
            }                        
        }
    }, [props.story, props.show, isOpened, activeFeature]);

    useEffect(() => {
        if (! mapLoaded && props.show && isOpened && activeFeature === "location"){
            initMap();            
        }
    }, [props.show, isOpened, activeFeature]);

    if (props.story.posId == null) return (null);

    const open = () => {                
        if (!fromMaker && !fromDemo && !playerOnDesktop && !audioAlreadyDisabled && !playAudio){                        
            const ambiantPlayer = document.getElementById("audio-player");
            if (ambiantPlayer && ambiantPlayer.paused){
                ambiantPlayer.play();
            }
            setPlayAudio(true);            
        }
        pauseVideo();
        openFullscreen();
        setActiveFeature("location");
        setIsOpened(true);
      
        analyticsManager.logCheckItineraryTag(props.storyId, playMode);  
                
        if (appAnalyticsManager)
            appAnalyticsManager.clickedLocation();
        if (window.ttq){
            window.ttq.track('ViewContent', {                
                "contents": [
                    {              
                        "content_id": "0",           
                        "content_name": "map",
                    }
                ],
            });
        }
        if (window.fbq){                    
            window.fbq('track', 'ViewContent', {"content_name": "map"});
        }
      };


    async function initMap() {
        // The location of Uluru
        let restaurants = [{title: "Nancy Le Palais", address: "17 rue Grand Rabbin Haguenauer, 54000 Nancy", position:{ lat: -25.344, lng: 131.031 }}];
        let center = restaurants[0].position;
        let zoom = 13;
        let minZoom = 7;
        let maxZoom = 20;
        
        if (props.storyId === "LnSX2qKrthKsRmr1vcxc"){
            restaurants = [{title: "Bioburger Lyon Opéra", address: "18 Rue du Bât d'Argent, 69001 Lyon", position:{ lat: 45.7659367, lng: 4.8363936 }},
                {title: "Bioburger Lyon Part-Dieu", address: "Les Tables, Niveau 3, Centre commercial de, 17 Rue Dr Bouchut, 69003 Lyon", position:{ lat: 45.7613366, lng: 4.8565528 }}
            ];            
        }
        if (props.storyId === "ZcJ9o5kOjzeIvIZ4z0kw"){
            restaurants = [
                {title: "Bioburger Strasbourg Bateliers", address: "38 Quai des Bateliers, 67000 Strasbourg", position:{ lat: 48.5813593, lng: 7.7528548, }},
                {title: "Bioburger Wacken", address: "4B Pl. Adrien Zeller, 67000 Strasbourg", position:{ lat: 48.5986377, lng: 7.7601402, }},
            ];  
            zoom = 12;
        }
        if (props.storyId === "YPuRVqGYO2Q2QFKEkRvQ"){
            restaurants = [
                {title: "Bioburger Oxygen", address: "3 Esplanade de la Défense, 92800 Puteaux", position:{ lat: 48.8877099, lng: 2.2509287 }},                
                {title: "Bioburger Batignolles", address: "88 Rue Mstislav Rostropovitch, 75017 Paris", position:{ lat: 48.8921236, lng: 2.310051 }},
                {title: "Bioburger Victoire", address: "10 Rue de la Victoire, 75009 Paris", position:{ lat: 48.8751393, lng: 2.3401194, }},
                {title: "Bioburger Montorgueil", address: "45 Rue des Petits Carreaux, 75002 Paris", position:{ lat: 48.868259, lng: 2.3475383, }},
                {title: "Bioburger Montparnasse", address: "79 Bd du Montparnasse, 75006 Paris", position:{ lat: 48.8436197, lng: 2.3253646, }},
                {title: "Bioburger Le Marais", address: "13 Rue Rambuteau, 75004 Paris", position:{ lat: 48.8604283, lng: 2.3556501 }},
                {title: "Bioburger Rosa Parks", address: "17/19 All. Rose Dieng-Kuntz, 75019 Paris", position:{ lat: 48.8966185, lng: 2.3726827 }},
                {title: "Bioburger Gobelins", address: "54 Av. des Gobelins, 75013 Paris", position:{ lat: 48.8337794, lng: 2.35364 }},
            ];   
            zoom = 11;    
        }
        if (props.storyId === "zUEpyuVwo5Tws3hnvFds"){
            restaurants = [
                {title: "Bioburger Bordeaux Gambetta", address: "12 Rue Georges Bonnac, 33000 Bordeaux", position:{ lat: 44.8403188, lng: -0.5818149, }},
                {title: "Bioburger Bordeaux Belcier", address: "2 All. de la Pacific, 33800 Bordeaux", position:{ lat: 44.8213105, lng: -0.5530797, }},
            ];            
        }
        if (props.storyId === "cJU0Fnom8MTjmokacI3I"){
            restaurants = [{title: "Holberton School Paris", address: "22 Terr. Bellini, 92800 Puteaux", position:{ lat: 48.88699, lng: 2.2480045 }, website: "https://www.holbertonschool.fr/campus/paris"},
                {title: "Holberton School Toulouse", address: "37 Rue des Marchands, 31000 Toulouse", position:{ lat: 43.600128, lng: 1.443468 }, website: "https://www.holbertonschool.fr/campus/toulouse"},
                {title: "Holberton School Laval", address: "106 Av. Pierre de Coubertin, 53000 Laval", position:{ lat: 48.0811616, lng: -0.7570275 }, website: "https://www.holbertonschool.fr/campus/laval"},
                {title: "Holberton School Bordeaux", address: "18 Av. Jean Jaurès, 33150 Cenon", position:{ lat: 44.8541883, lng: -0.537557}, website: "https://www.holbertonschool.fr/campus/bordeaux"},
                {title: "Holberton School Lille", address: "Le Jacquard, 25 Rue Corneille 2ème, 59100 Roubaix", position:{ lat: 50.7006342, lng: 3.1592798}, website: "https://www.holbertonschool.fr/campus/lille"},
                {title: "Holberton School Dijon", address: "2 Bis Av. de Marbotte, 21000 Dijon", position:{ lat: 47.3299399, lng: 5.0493052}, website: "https://www.holbertonschool.fr/campus/dijon"},
                {title: "Holberton School Fréjus", address: "1196 Bd de la Mer, 83600 Fréjus", position:{ lat: 43.4180363, lng: 6.7401268}, website: "https://www.holbertonschool.fr/campus/frejus"},
                {title: "Holberton School Rennes", address: "14e Rue du Patis Tatelin, 35700 Rennes", position:{ lat: 48.1329857, lng: -1.6397973}, website: "https://www.holbertonschool.fr/campus/rennes"},
                {title: "Holberton School Rodez, l'annexe du campus de Toulouse", address: "19 Av. Amans Rodat, 12000 Rodez", position:{ lat: 44.349402, lng: 2.5662646}, website: "https://www.holbertonschool.fr/campus/rodez"},
                {title: "Holberton School Thonon", address: "5 Av. des Allobroges, 74200 Thonon-les-Bains", position:{ lat: 46.3701691, lng: 6.4765625}, website: "https://www.holbertonschool.fr/campus/thonon-les-bains"},
            ];    
            zoom = 5; 
            minZoom = 5;            
            maxZoom = 20;       
        }

        let latSum = 0;
        let lngSum = 0;
        for (const restaurant of restaurants){
            latSum += restaurant.position.lat;
            lngSum += restaurant.position.lng;
        }
        center = {lat: latSum/restaurants.length, lng: lngSum/restaurants.length};

        // Request needed libraries.
        //@ts-ignore
        const { Map, InfoWindow } = await window.google.maps.importLibrary("maps");
        const { AdvancedMarkerElement, PinElement } = await window.google.maps.importLibrary("marker");
        
        // The map, centered at Uluru
        map = new Map(document.getElementById("map"), {
            zoom: zoom,
            minZoom: minZoom,
            maxZoom: maxZoom,
            center: center,
            mapId: "DEMO_MAP_ID",            
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false
        });            

        const infoWindow = new InfoWindow();

        for (const restaurant of restaurants){  
            let glyphSvgPinElement = new PinElement({
                scale: 1.4,
            });
            
            if (props.storyId === "LnSX2qKrthKsRmr1vcxc" || props.storyId === "ZcJ9o5kOjzeIvIZ4z0kw" || props.storyId === "zUEpyuVwo5Tws3hnvFds" || props.storyId === "YPuRVqGYO2Q2QFKEkRvQ"){
                const glyphImg = document.createElement("img");
                glyphImg.style.width = "20px";
                glyphImg.style.height = "20px";
                glyphImg.src = "/images/bioburger_glyph.png";
                
                glyphSvgPinElement = new PinElement({
                    glyph: glyphImg,
                    borderColor: "#16241d",
                    background: "#16241d",   
                    scale: 1.4,
                });
            }
            if (props.storyId === "cJU0Fnom8MTjmokacI3I"){
                const glyphImg = document.createElement("img");
                glyphImg.style.width = "22px";
                glyphImg.style.height = "22px";
                glyphImg.src = "/images/holberton_glyph.png";
                
                glyphSvgPinElement = new PinElement({
                    glyph: glyphImg,
                    borderColor: "#FFFFFF",
                    background: "#FFFFFF",   
                    scale: 1.4,
                });
            }

            let marker = new AdvancedMarkerElement({
                map: map,
                position: restaurant.position,
                content: glyphSvgPinElement.element,
                gmpClickable: true,
                title: restaurant.title,
            });
            marker.addListener("click", (_) => {
                infoWindow.close();
                let website = "";
                if (restaurant.website){
                    website = "<br/><br/><b><a href='" + restaurant.website + "' target='_blank'>Visitez le site web du campus</a> 👋</b>";
                }
                infoWindow.setContent("<h1>" + restaurant.title + "</h1>" + restaurant.address + website);
                infoWindow.open(marker.map, marker);
                if (window.ttq){
                    window.ttq.track('ViewContent', {                
                        "contents": [
                            {              
                                "content_id": "0",           
                                "content_name": "map marker for " + restaurant.title,
                            }
                        ],
                    });
                }
                if (window.fbq){                    
                    window.fbq('track', 'ViewContent', {"content_name": "map marker for " + restaurant.title});
                }
              });
        }

        // TODO Restore + hide div instead of removing it from
        setMapLoaded(true);
    }
    
      /*if (props.show){                           

            if (isOpened && activeFeature === "location"){
                
                return (
                    <div style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight}}>
                        <div id="location-overlay" className="BlockingOverlay" style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight, opacity: 0}}/>
                        <div id="map" className='location-chain' style={{ zIndex: 20, width: storyDimensions.containerWidth - 32, height: storyDimensions.containerHeight - 128, borderRadius: 20}}/>                                                    
                    </div>
                );
            }
            else {                                
                return (
                    <SecondaryButton buttonType={locationType} open={open}/>
                );                
            }
        }
        else {
            return null;
        }*/

        const styleOverride = {};
        if ((props.storyId === "LJUbpj5ZyMMdpqmdDc0e" || props.storyId === "LnSX2qKrthKsRmr1vcxc" || props.storyId === "ZcJ9o5kOjzeIvIZ4z0kw" || props.storyId === "zUEpyuVwo5Tws3hnvFds" || props.storyId === "YPuRVqGYO2Q2QFKEkRvQ")){
            styleOverride.background = "#FFFFFFB0";
        } 

        return <div style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight, position: 'absolute'}}>
                {props.show && isOpened && activeFeature === "location" && <div id="location-overlay" className="BlockingOverlay" style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight, opacity: 0, ...styleOverride}}/>}
                <div id="map" onClick={(e)=>{e.stopPropagation();}} className='location-chain' style={{ zIndex: 20, visibility: props.show && isOpened && activeFeature === "location" ? 'visible' : 'hidden', width: storyDimensions.containerWidth - 32, height: storyDimensions.containerHeight - 128, borderRadius: 20}}/>
                {props.show && isOpened && activeFeature === "location" && playerOnDesktop && <CloseButton onClick={()=>propagateFocusChange(true)} color={props.story.primaryColor}  width={closeWidth} height={closeHeight} className="hours-close" style={{top: closeBtnY, left: closeBtnX}} />}
                {props.show && !(isOpened && activeFeature === "location") && <SecondaryButton buttonType={locationType} open={open}/>}
            </div>;
    }

export default LocationButtonChain;