import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {MakerContext} from "./MakerContext";
import MaterialButton from './component/MaterialButton';
import {AppContext} from '../AppContext';
import { useNavigate } from "react-router-dom";
import { cleanMakerQueryString, updateAlias, updateStoryOrGoToStories } from './MakerController';
import LoginDialog from './LoginDialog';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { analyticsManager } from '..';
import MobileBottomBar from './component/MobileBottomBar';
import { useMediaQuery } from 'react-responsive';
import { TextField } from '@mui/material';
import { getTextWidth, log, printObj } from '../player/Utils';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { use } from 'i18next';

function UseIt(props) {
    const story = useContext(MakerContext).get("story");
    const storyId = useContext(MakerContext).get("storyId");
    const fbResponse = useContext(AppContext).get("fbResponse");
    const navigate = useNavigate();    
    const { t } = useTranslation();
    const windowDimensions = useContext(MakerContext).get("windowDimensions");
    const [linkEditable, setLinkEditable] = useState(false);
    const [invalidAlias, setInvalidAlias] = useState(false);
    const [aliasInputWidth, setAliasInputWidth] = useState(0);
    
    const setActiveForm = useContext(MakerContext).get("setActiveForm");
    const [showLogin, setShowLogin] = useState(false);
    const [showAckLinkCopied, setShowAckLinkCopied] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const makerDesktopHeight = useContext(AppContext).get("makerDesktopHeight");
    const verticalMarginBtwElmts = makerDesktopHeight > 680 ? 60 : 40;

    useEffect(() => {
        if (props.show) {
            setAliasInputWidth(getTextWidth(story.alias, "16px Roboto") + 2 * 4);// 20 input padding + buffer
        }
    }, [props.show]);

    function showSaveButton(){
        if (fbResponse != null && (fbResponse.isAnonymous == null || ! fbResponse.isAnonymous)){
            return null;
        }
        else {
            return (
                <div>                    
                    <div style={{fontSize: 14, fontStyle: 'italic', textAlign: "start", marginTop: verticalMarginBtwElmts}}>{t('SaveWork')}</div><br/>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <MaterialButton style="filled" value={t("Save")} onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "use_it", true);setShowLogin(true);}}/>                                                
                    </div>
                </div>
            );
        }        
    }

    function onLogin(user){
        updateStoryOrGoToStories(user, storyId, story)
            .then((navigateToLogged)=>{
                if (navigateToLogged) 
                    navigate("/logged");
                else 
                    cleanMakerQueryString(story.baseStoryId != null ? story.baseStoryId : storyId);
                });
    }

    function showFormReturnOrMyStories(){
        if (fbResponse != null && (fbResponse.isAnonymous == null || ! fbResponse.isAnonymous)){
            return (
                <div>
                    <br/><br/>                    
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <MaterialButton style="filled" value={t("BackToMyStories")} onClick={()=>{analyticsManager.logReturnStoriesTag(storyId);navigate("/logged");}}/>
                    </div>
                </div>
            );
        }
        else {
            const isMobileBottomBarDisplayed = isTabletOrMobile && fbResponse != null && fbResponse.isAnonymous != null && ! fbResponse.isAnonymous;

            return (                
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', bottom: 16 + (isMobileBottomBarDisplayed ? 56 : 0), left: 16, position: 'absolute'}}>
                <MaterialButton style="outlined-tonal" onClick={() => {analyticsManager.logChooseMenuScreenViewTag(storyId);setActiveForm("choose-menu");}} value={t('UseItToMenu')} />
            </div>  
            );
        }
    }

    function showAckLinkCopiedSnack(){
        if (showAckLinkCopied){
            return (
                <Snackbar open={showAckLinkCopied} autoHideDuration={2000} onClose={()=>setShowAckLinkCopied(false)} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                    <Alert onClose={()=>setShowAckLinkCopied(false)} severity="success" sx={{ width: '100%' }}>     
                    {t('AckLinkCopied')}
                    </Alert>
                </Snackbar>
            );
        }
        else return null;
    } 

    if (props.show) {             
        const availableWidth = (isTabletOrMobile ? windowDimensions.width : 360.0) - 2 * 16;
        const fullAliasInputWidth = Math.min(getTextWidth("upreality.store/p/", "16px Roboto") + aliasInputWidth, availableWidth);
        
        const modifyBtnMarginRight = Math.max((availableWidth - fullAliasInputWidth) / 2 - 2, 0); // 2 text button inner padding

        function checkValidity(e){
            const alias = e.target.value;
            const regex = /[^a-zA-Z0-9-]/g;
            const invalid = alias.match(regex) || alias === "";
            setInvalidAlias(invalid ? t('aliasNotValid') : false);
        }

        function resetAlias(){
            document.getElementById("alias-input").value = story.alias;
            setInvalidAlias(false);
            setLinkEditable(false);
            analyticsManager.logChangeLinkTag(story.baseStoryId != null ? story.baseStoryId : storyId, false);
        }

        async function validateAlias(){
            if (invalidAlias) return;

            const alias = document.getElementById("alias-input").value;

            if (alias === story.alias) {
                setLinkEditable(false);
                return;
            }
            
            let validAlias = await updateAlias(story.baseStoryId != null ? story.baseStoryId : storyId, story.alias, story.previousAlias, alias);                        
            setInvalidAlias(validAlias ? false : t('aliasAlreadyExists'));
            setLinkEditable(!validAlias);              
            analyticsManager.logChangeLinkTag(story.baseStoryId != null ? story.baseStoryId : storyId, true);  
            if (validAlias){
                setAliasInputWidth(getTextWidth(alias, "16px Roboto") + 2 * 4);
            }     
        }

        async function copyLink(){
            analyticsManager.logCopyLinkTag(story.baseStoryId != null ? story.baseStoryId : storyId, "use_it");
            const db = getFirestore();  
            const docRef = doc(db, "stories", story.baseStoryId != null ? story.baseStoryId : storyId);
            const storyDoc = await getDoc(docRef);            
            navigator.clipboard.writeText(storyDoc.data().storyLink);
            setShowAckLinkCopied(true);
        }

        return(
            <div>
                <div style={{padding: 16, height: '100%', textAlign: 'center'}}>
                    <div style={{marginTop:verticalMarginBtwElmts, fontSize: 28}}>{t('Congratulation')}</div>                    
                    <div style={{fontSize: 20, marginTop: verticalMarginBtwElmts}}>{t("StoryLink")}</div><br/>
                    <div width={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                            <div style={{marginBottom: invalidAlias ? 22 : 0}}>upreality.store/p/</div><TextField id="alias-input" inputProps={{style: {padding: 2}}} onChange={(e)=>checkValidity(e)} error={invalidAlias} helperText={invalidAlias ? invalidAlias : null} type='text' style={{width: linkEditable ? '100%' : aliasInputWidth, }} disabled={!linkEditable} defaultValue={story.alias} />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'end',}}>
                            <div style={{marginRight: linkEditable ? 0 : modifyBtnMarginRight}}>
                                {!linkEditable && <MaterialButton style="text" value="modifier" reducedPadding={true} lowercase={true} onClick={()=>{setLinkEditable(true);analyticsManager.logChangeLinkTag(story.baseStoryId != null ? story.baseStoryId : storyId, "");}} />}
                                {linkEditable && <><MaterialButton style="text" value="x" onClick={()=>resetAlias()} reducedPadding={true} /><MaterialButton style="text" value="ok" onClick={()=>validateAlias()} reducedPadding={true} /></>}
                            </div>
                        </div>
                    </div>                    
                    <div style={{marginTop: 10, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <MaterialButton style="filled" onClick={()=>copyLink()} value={t("CopyLink")} />
                    </div>                    
                    <div style={{fontSize: 20, marginTop: verticalMarginBtwElmts}}>{t('PossibleUsages')}</div><br/>
                    {t('SocialeProfile')}<br/><br/>                    
                    {t('SocialAds')}<br/><br/>
                    {t('Website')}
                    {showSaveButton()}
                    <LoginDialog origin="use_it" showLogin={showLogin !== false} customLoginButton={showLogin != false} title={t('Save')} resetLoginDialog={setShowLogin} onSuccess={(result)=>onLogin(result.user)}/>
                    {showFormReturnOrMyStories()}  
                    {showAckLinkCopiedSnack()}              
                </div>
                <MobileBottomBar currentTab="0" storyId={storyId} /> 
            </div>
        );
    }
    else return null;
}

export default UseIt;