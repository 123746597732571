import { useContext, useEffect } from "react";
import { AppContext } from "../AppContext";
import { LoggedContext } from "./LoggedContext";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { analyticsManager } from "..";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from "@mui/material";
import MaterialButton from "../maker/component/MaterialButton";
import './Logged.css';

function Legals(props) {
    const windowDimensions = useContext(LoggedContext).get("windowDimensions");
    const setActivePage = useContext(LoggedContext).get("setActivePage");
    const makerDesktopHeight = useContext(AppContext).get("makerDesktopHeight");
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const { t } = useTranslation();

    let pageHeight = makerDesktopHeight;
    if (isTabletOrMobile && windowDimensions != null) {
        pageHeight = windowDimensions.height;
    }    

    useEffect(() => {
        analyticsManager.logLegalsScreenViewTag();
        if (window.history.pushState) {   
            var newurl = window.location.protocol + "//" + window.location.host + "/logged" + '/' + "legals";
            window.history.pushState({path:newurl},'',newurl);
        } 
    }, []);

    async function closeLegalsOnMobile(){    
        analyticsManager.logBackToPreferencesFromLegalsTag();           
        setActivePage("preferences");
    }

    return (
        <div style={{width: '100%', height: pageHeight, backgroundColor: 'white', borderRadius: isTabletOrMobile ? 0 : 10,}}>
            <div className="list-legals" style={{padding: 16,height: pageHeight - 32}}>
                <div style={{display: "flex", height: 50, flexDirection: "row", justifyContent: 'flex-start', alignItems: "center", alignContent: "center"}}>                    
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <IconButton style={{width: 40, height: 40, marginRight: 8}} onClick={()=>closeLegalsOnMobile()}>
                            <ArrowBackIcon style={{color: '#2FC4B5', width: 32, height:32, }} />
                        </IconButton>
                        <div style={{fontSize: 28}}>{t('Legals')}</div>
                    </div>                    
                </div>                
                <div style={{marginTop: 20}}>
                    <div style={{fontSize: 20, marginBottom: 10}}>{t('Terms')}</div>
                    <MaterialButton style="text" onClick={()=> {analyticsManager.logReadCGUTag("legals");window.open("/terms/cgu.pdf", "_blank");}} value={t('ReadCgus')}/>
                </div>
                <div style={{marginTop: 20}}>
                    <div style={{fontSize: 20, marginBottom: 10}}>{t('Privacy')}</div>
                    <MaterialButton style="text" onClick={()=> {analyticsManager.logReadPrivacyTag("legals");window.open("/terms/charte_confidentialite.pdf", "_blank");}} value={t('ReadPrivacy')}/>
                </div>
                <div style={{marginTop: 20}}>
                    <div style={{fontSize: 20, marginBottom: 10}}>{t('Credits')}</div>
                    <div style={{marginBottom: 10}}>
                        {t('Images')}
                        <ul>
                            <li>Travel, restaurant, adventure icon by <a href="https://www.iconfinder.com/igraphics/icon-sets" target="_blank">Igraphics</a></li>
                            <li>Logo, media, social icon by <a href="https://www.iconfinder.com/AlfredoCreates" target="_blank">Alfredo</a>, <a href="https://creativecommons.org/licenses/by/3.0/" target="_blank">CC BY 3.0 DEED</a>, not modified</li>
                            <li>Android device background by <a href="https://developer.android.com/distribute/marketing-tools/device-art-generator" target="_blank">Google</a>, <a href="https://creativecommons.org/licenses/by/2.5/legalcode" target="_blank">CC BY 2.5 LEGAL CODE</a>, modified</li>
                            <li>Kitchen, utensils, rolling icon by <a href="https://www.iconfinder.com/rsetiawan93" target="_blank">Rakhmat Setiawan</a>, <a href="https://creativecommons.org/licenses/by/3.0/" target="_blank">CC BY 3.0 DEED</a>, not modified</li>
                            <li>Cauldron, cook, food icon by <a href="https://www.iconfinder.com/hacastudio" target="_blank">Haca Studio</a>, <a href="https://creativecommons.org/licenses/by/2.5/" target="_blank">CC BY 2.5 DEED</a>, not modified</li>                            
                        </ul>
                    </div>
                    <div style={{marginBottom: 10}}>
                        {t('Others')}
                        <ul>
                            <li>Tasty website template by <a href="https://themewagon.com/author/freehtml5co/" target="_blank">Free HTML5 CO</a>, <a href="https://creativecommons.org/licenses/by/3.0/" target="_blank">CC BY 3.0 DEED</a>, modified</li>
                        </ul>
                    </div>                    
                </div>                                
            </div>
        </div>
    );
}

export default Legals;