import { useTranslation } from 'react-i18next';
import MaterialButton from './maker/component/MaterialButton';
import { useEffect } from 'react';
import { useRouteError } from "react-router";
import { webFallback } from '.';
import * as Sentry from "@sentry/react";
function ErrorPageWithRedirect(props) {    
    const { t } = useTranslation();

    document.getElementsByTagName('body')[0].className = 'PatternBackground';
    
    let error = useRouteError();
    Sentry.captureMessage("error leading to a redirect (this error may create a duplicate) :  " + error.message);

    useEffect(() => {
        setTimeout(()=>{            
            if (webFallback !== ""){
                window.location = webFallback;
            }
            }, 500);        
      }, []);

    return (
      <div style={{width: 360, height: 360, borderRadius:60, display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: 'center', alignContent: 'center', transform: 'translate(-50%, -50%)', position: 'absolute', top: '50%', left: '50%', background: 'white'}}>
            <img src="/images/img_error.png" alt="the dish is a failure" style={{width: 128, height: 128 }}/>
            <br/>
            <div style={{fontSize: 18}}>{t('ErrorTitle')}</div>
            <br/>
            <div style={{fontSize: 14}}>{t('ErrorMsg')}</div>
            <br/>
            <div>
                <MaterialButton style="filled" onClick={() => window.location.reload()}  value={t('ErrorAction')} />                        
            </div>
        </div>
    );
}

export default ErrorPageWithRedirect;