import { useContext, useEffect } from "react";
import { AppContext } from "../AppContext";
import { LoggedContext } from "./LoggedContext";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { analyticsManager } from "..";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from "@mui/material";
import Faq from "react-faq-component";
import './Logged.css';

function Help(props) {
    const windowDimensions = useContext(LoggedContext).get("windowDimensions");
    const setActivePage = useContext(LoggedContext).get("setActivePage");
    const makerDesktopHeight = useContext(AppContext).get("makerDesktopHeight");
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const { t } = useTranslation();

    let pageHeight = makerDesktopHeight;
    if (isTabletOrMobile && windowDimensions != null) {
        pageHeight = windowDimensions.height;
    }

    const dataUnderstandUpReality = {
        /*title: t("FAQ"),*/
        rows: [            
            {
                title: t("FAQWebsiteSubstituteQuestion"),
                content: t("FAQWebsiteSubstituteAnswer"),
            },   
            {
                title: t("FAQInstantAdQuestion"),
                content: t("FAQInstantAdAnswer"),
            }, 
            {
                title: t("FAQInstantAdDifferenceQuestion"),
                content: t("FAQInstantAdDifferenceAnswer"),
            }, 
            {
                title: t("FAQEasyToUseQuestion"),
                content: t("FAQEasyToUseAnswer"),
            },      
            {
                title: t("FAQPriceQuestion"),
                content: t("FAQPriceAnswer"),
            },      
        ],
    };
    
    const dataUseMyStory = {
        /*title: t("FAQ"),*/
        rows: [
            {
                title: t("FAQSocialNetworksQuestion"),
                content: t("FAQSocialNetworksAnswer"),
            },
            {
                title: t("FAQSocialProfileQuestion"),
                content: t("FAQSocialProfileAnswer"),
            },
            {
                title: t("FAQSocialAdDestQuestion"),
                content: t("FAQSocialAdDestAnswer"),
            },
            {
                title: t("FAQWebsiteQuestion"),
                content: t("FAQWebsiteAnswer"),
            },
            {
                title: t("FAQOriginalWaysQuestion"),
                content: 
                <>
                    {t("FAQOriginalWaysAnswer")}
                    <ul>                        
                        <li>{t("FAQOriginalWaysAnswerl0")}</li>
                        <li>{t("FAQOriginalWaysAnswerl1")}</li>
                        <li>{t("FAQOriginalWaysAnswerl2")}</li>
                        <li>{t("FAQOriginalWaysAnswerl3")}</li>
                        <li>{t("FAQOriginalWaysAnswerl4")}</li>                        
                        <li>{t("FAQOriginalWaysAnswerl5")}</li>
                    </ul>
                </>,
            },            
        ],
    };

    const dataMyStory = {
        /*title: t("FAQ"),*/
        rows: [  
            {
                title: t("FAQProContentsQuestion"),
                content: 
                    <>
                        {t("FAQProContentsAnswer")}
                        <ul>                        
                            <li>{t("FAQProContentsAnswerl0")}</li>
                            <li>{t("FAQProContentsAnswerl1")}</li>
                            <li>{t("FAQProContentsAnswerl2")}</li>
                            <li>{t("FAQProContentsAnswerl3")}</li>
                            <li>{t("FAQProContentsAnswerl4")}</li>
                            <li>{t("FAQProContentsAnswerl5")}</li>
                        </ul>
                    </>,
            },                      
            {
                title: t("FAQGoogleDataQuestion"),
                content:t("FAQGoogleDataAnswer"),
            },
            {
                title: t("FAQStoreFrontQuestion"),
                content: t("FAQStoreFrontAnswer"),
            },
            {
                title: t("FAQMenuTranslatedQuestion"),
                content: t("FAQMenuTranslatedAnswer"),
            },            
        ],
    };

    const dataStats = {
        /*title: t("FAQ"),*/
        rows: [
            {
                title: t("FAQStatsNoteQuestion"),
                content: 
                    <>
                        {t("FAQStatsNoteAnswer")}
                        <ul>
                            <li>{t("FAQStatsNoteAnswerl1")}</li>
                            <li>{t("FAQStatsNoteAnswerl2")}</li>
                            <li>{t("FAQStatsNoteAnswerl3")}</li>
                            <li>{t("FAQStatsNoteAnswerl4")}</li>
                            <li>{t("FAQStatsNoteAnswerl5")}</li>
                        </ul>
                    </>,
            },
            {
                title: t("FAQStatsQuestion"),
                content: 
                    <>
                        <ul>
                            <li>{t("FAQStatsAnswerUniqueView")}</li>
                            <li>{t("FAQStatsAnswerMoy")}</li>
                            <li>{t("FAQStatsAnswerShares")}</li>
                            <li>{t("FAQStatsAnswerBooks")}</li>
                            <li>{t("FAQStatsAnswerItinerary")}</li>
                            <li>{t("FAQStatsAnswerReturn")}</li>
                        </ul>
                    </>,
            },            
        ],
    };

    const dataVideo = {
        /*title: t("FAQ"),*/
        rows: [
            {
                title: t("FAQVideoRecommendedResolutionQuestion"),
                content:t("FAQVideoRecommendedResolutionAnswer"),
            },
            {
                title: t("FAQVideoFormatQuestion"),
                content: t("FAQVideoFormatAnswer"),
            },
            {
                title: t("FAQVideoSizeQuestion"),
                content: t("FAQVideoSizeAnswer"),
            },
        ],
    };

    const dataImage = {
        /*title: t("FAQ"),*/
        rows: [
            {
                title: t("FAQImageRecommendedResolutionQuestion"),
                content:t("FAQImageRecommendedResolutionAnswer"),
            },
            {
                title: t("FAQImageFormatQuestion"),
                content: t("FAQImageFormatAnswer"),
            },
            {
                title: t("FAQImageSizeQuestion"),
                content: t("FAQImageSizeAnswer"),
            },
        ],
    };

    const dataMusic = {
        /*title: t("FAQ"),*/
        rows: [
            {
                title: t("FAQMusicFormatQuestion"),
                content: t("FAQMusicFormatAnswer"),
            },
            {
                title: t("FAQMusicSizeQuestion"),
                content: t("FAQMusicSizeAnswer"),
            },
        ],
    };

    const dataAccount = {
        /*title: t("FAQ"),*/
        rows: [            
            {
                title: t("FAQNoFacebookPageQuestion"),
                content: 
                    <>
                        {t("FAQNoFacebookPageAnswer")}<br/><br/>
                        <a href="https://www.facebook.com/business/help/connect-instagram-to-page" target="_blank">{t("FAQNoFacebookPageAnswer2")}</a>
                    </>,
            },            
        ],
    };
    
    const styles = {
        titleTextColor: "#1D192B",
        rowTitleColor: "#1D192B",
        arrowColor: "#2FC4B5",        
    };
    
    const config = {};

    useEffect(() => {
        function logFaq(position, faqTheme){
            return (event) => {
                if (document.getElementsByClassName("row-title")[position].className.includes("closed"))
                    analyticsManager.logReadFAQTag("help", faqTheme);
            };
        }

        
        const handleClickListener0 = logFaq(0, "alreadyWebsite");
        const handleClickListener1 = logFaq(1, "instantAd");
        const handleClickListener2 = logFaq(2, "instantAdDifference");
        const handleClickListener3 = logFaq(3, "easyToUse");
        const handleClickListener4 = logFaq(4, "free");

        const handleClickListener5 = logFaq(5, "socialNetwork");
        const handleClickListener6 = logFaq(6, "socialProfile");
        const handleClickListener7 = logFaq(7, "website");
        const handleClickListener8 = logFaq(8, "socialAdDest");
        const handleClickListener9 = logFaq(9, "originalWays");

        const handleClickListener10 = logFaq(10, "proPhoto");
        const handleClickListener11 = logFaq(11, "dataRestaurant");
        const handleClickListener12 = logFaq(12, "storeFrontReco");
        const handleClickListener13 = logFaq(13, "menuTranslation");

        const handleClickListener14 = logFaq(14, "videoResolution");
        const handleClickListener15 = logFaq(15, "videoFormat");
        const handleClickListener16 = logFaq(16, "videoSize");
        
        const handleClickListener17 = logFaq(17, "imageResolution");
        const handleClickListener18 = logFaq(18, "imageFormat");
        const handleClickListener19 = logFaq(19, "imageSize");

        const handleClickListener20 = logFaq(20, "musicFormat");
        const handleClickListener21 = logFaq(21, "musicSize");

        const handleClickListener22 = logFaq(22, "interestRating");
        const handleClickListener23 = logFaq(23, "stats");

        const handleClickListener24 = logFaq(24, "fbPageError");

        document.getElementsByClassName("row-title")[0].addEventListener("click", handleClickListener0);
        document.getElementsByClassName("row-title")[1].addEventListener("click", handleClickListener1);
        document.getElementsByClassName("row-title")[2].addEventListener("click", handleClickListener2);
        document.getElementsByClassName("row-title")[3].addEventListener("click", handleClickListener3);
        document.getElementsByClassName("row-title")[4].addEventListener("click", handleClickListener4);
        document.getElementsByClassName("row-title")[5].addEventListener("click", handleClickListener5);
        document.getElementsByClassName("row-title")[6].addEventListener("click", handleClickListener6);
        document.getElementsByClassName("row-title")[7].addEventListener("click", handleClickListener7);
        document.getElementsByClassName("row-title")[8].addEventListener("click", handleClickListener8);
        document.getElementsByClassName("row-title")[9].addEventListener("click", handleClickListener9);
        document.getElementsByClassName("row-title")[10].addEventListener("click", handleClickListener10);
        document.getElementsByClassName("row-title")[11].addEventListener("click", handleClickListener11);
        document.getElementsByClassName("row-title")[12].addEventListener("click", handleClickListener12);
        document.getElementsByClassName("row-title")[13].addEventListener("click", handleClickListener13);
        document.getElementsByClassName("row-title")[14].addEventListener("click", handleClickListener14);
        document.getElementsByClassName("row-title")[15].addEventListener("click", handleClickListener15);
        document.getElementsByClassName("row-title")[16].addEventListener("click", handleClickListener16);
        document.getElementsByClassName("row-title")[17].addEventListener("click", handleClickListener17);
        document.getElementsByClassName("row-title")[18].addEventListener("click", handleClickListener18);
        document.getElementsByClassName("row-title")[19].addEventListener("click", handleClickListener19);
        document.getElementsByClassName("row-title")[20].addEventListener("click", handleClickListener20);    
        document.getElementsByClassName("row-title")[21].addEventListener("click", handleClickListener21);  
        document.getElementsByClassName("row-title")[22].addEventListener("click", handleClickListener22); 
        document.getElementsByClassName("row-title")[23].addEventListener("click", handleClickListener23);
        document.getElementsByClassName("row-title")[24].addEventListener("click", handleClickListener24);

        return () => {
            window.removeEventListener('click', handleClickListener0);
            window.removeEventListener('click', handleClickListener1);
            window.removeEventListener('click', handleClickListener2);
            window.removeEventListener('click', handleClickListener3);
            window.removeEventListener('click', handleClickListener4);
            window.removeEventListener('click', handleClickListener5);
            window.removeEventListener('click', handleClickListener6);
            window.removeEventListener('click', handleClickListener7);
            window.removeEventListener('click', handleClickListener8);
            window.removeEventListener('click', handleClickListener9);
            window.removeEventListener('click', handleClickListener10);
            window.removeEventListener('click', handleClickListener11);
            window.removeEventListener('click', handleClickListener12);
            window.removeEventListener('click', handleClickListener13);
            window.removeEventListener('click', handleClickListener14);
            window.removeEventListener('click', handleClickListener15);
            window.removeEventListener('click', handleClickListener16);
            window.removeEventListener('click', handleClickListener17);
            window.removeEventListener('click', handleClickListener18);
            window.removeEventListener('click', handleClickListener19);
            window.removeEventListener('click', handleClickListener20);
            window.removeEventListener('click', handleClickListener21);
            window.removeEventListener('click', handleClickListener22);
            window.removeEventListener('click', handleClickListener23);
            window.removeEventListener('click', handleClickListener24);
        };
    }, []);

    useEffect(() => {
        analyticsManager.logHelpScreenViewTag();
        if (window.history.pushState) {   
            var newurl = window.location.protocol + "//" + window.location.host + "/logged" + '/' + "help";
            window.history.pushState({path:newurl},'',newurl);
        } 
    }, []);

    async function closeHelpOnMobile(){    
        analyticsManager.logBackToPreferencesFromHelpTag();    
        hideHubspotHelp();        
        setActivePage("preferences");
    }

    function hideHubspotHelp(){
        const scriptList = document.querySelectorAll("script[type='text/javascript']");
        const convertedNodeList = Array.from(scriptList);
        const hubspotScript = convertedNodeList.find(script => script.id === "hs-script-loader");        
        if (hubspotScript != null){
            const hubspotTag =  document.getElementById("hubspot-messages-iframe-container");
            if (hubspotTag != null)
                hubspotTag.style.visibility = "hidden";
        }        
    }

    useEffect(() => {
        if (isTabletOrMobile){
            const hubspotTag =  document.getElementById("hubspot-messages-iframe-container");
            if (hubspotTag == null){
                const script = document.createElement('script');
            
                script.id = "hs-script-loader";
                script.src = "//js-eu1.hs-scripts.com/143936779.js?cachebuster="+ new Date().getTime();
                script.async = true;
                script.defer = true;
                script.type = "text/javascript";
            
                document.body.appendChild(script);
                
                // do not remove script when unmounting
            }
            else {
                hubspotTag.style.visibility = "visible";
            }
        }

        const faqTexts = document.getElementsByClassName("row-content-text");
        if (faqTexts != null){
            for (let i = 0; i < faqTexts.length; i++) {
                faqTexts[i].style.whiteSpace = "pre-line";
            }
        }
      }, []);

    return (
        <div style={{width: '100%', height: pageHeight, backgroundColor: 'white', borderRadius: isTabletOrMobile ? 0 : 10,}}>
            <div className="list-faqs" style={{padding: 16, height: pageHeight - 32}}>                
                {isTabletOrMobile &&
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <IconButton style={{width: 40, height: 40, marginRight: 8}} onClick={()=>closeHelpOnMobile()}>
                            <ArrowBackIcon style={{color: '#2FC4B5', width: 32, height:32, }} />
                        </IconButton>
                        <div style={{fontSize: 28}}>{t('Help')}</div>
                    </div>
                }                    
                {! isTabletOrMobile && <div style={{fontSize: 28}}>{t('Help')}</div>}        
                <div style={{fontSize: 20, marginTop: 20, marginBottom: 10}}>{t('FAQCatUprealityOffer')}</div>
                <div style={{padding: 16, backgroundColor: "#FFFFFF"}}>
                    <Faq
                        data={dataUnderstandUpReality}
                        styles={styles}
                        config={config}
                    />
                </div>
                <div style={{fontSize: 20, marginTop: 20, marginBottom: 10}}>{t('FAQCatUseMyStory')}</div>
                <div style={{padding: 16, backgroundColor: "#FFFFFF"}}>
                    <Faq
                        data={dataUseMyStory}
                        styles={styles}
                        config={config}
                    />
                </div>
                <div style={{fontSize: 20, marginTop: 20, marginBottom: 10}}>{t('FAQCatMyStory')}</div>
                <div style={{padding: 16, backgroundColor: "#FFFFFF"}}>
                    <Faq
                        data={dataMyStory}
                        styles={styles}
                        config={config}
                    />
                </div>
                <div style={{fontSize: 20, marginTop: 20, marginBottom: 10}}>{t('FAQCatVideos')}</div>
                <div style={{padding: 16, backgroundColor: "#FFFFFF",}}>
                    <Faq
                        data={dataVideo}
                        styles={styles}
                        config={config}
                    />
                </div>
                <div style={{fontSize: 20, marginTop: 20, marginBottom: 10}}>{t('FAQCatImages')}</div>
                <div style={{padding: 16, backgroundColor: "#FFFFFF",}}>
                    <Faq
                        data={dataImage}
                        styles={styles}
                        config={config}
                    />
                </div>
                <div style={{fontSize: 20, marginTop: 20, marginBottom: 10}}>{t('FAQCatMusic')}</div>
                <div style={{padding: 16, backgroundColor: "#FFFFFF",}}>
                    <Faq
                        data={dataMusic}
                        styles={styles}
                        config={config}
                    />
                </div>
                <div style={{fontSize: 20, marginTop: 20, marginBottom: 10}}>{t('FAQCatMyStats')}</div>
                <div style={{padding: 16, backgroundColor: "#FFFFFF",}}>
                    <Faq
                        data={dataStats}
                        styles={styles}
                        config={config}
                    />
                </div>
                <div style={{fontSize: 20, marginTop: 20, marginBottom: 10}}>{t('FAQCatMyAccount')}</div>
                <div style={{padding: 16, backgroundColor: "#FFFFFF",}}>
                    <Faq
                        data={dataAccount}
                        styles={styles}
                        config={config}
                    />
                </div>
            </div>
        </div>
    );
}

export default Help;