import { and, arrayUnion, collection, deleteDoc, deleteField, doc, getDoc, getDocs, getFirestore, orderBy, query, runTransaction, where } from "firebase/firestore";
import { printObj } from "../player/Utils";
import { getStorage, listAll, ref, updateMetadata } from "firebase/storage";
import { deleteStoryVersions } from "./StatsController";

// TODO mutualize with ListStories
export async function getStories(uid){              
    const db = getFirestore();     
    const q = query(collection(db, "stories"), where("uid", "==", uid), where("baseStory", "==", true), orderBy("savedTime", "desc"));        
    const querySnapshot = await getDocs(q);
    const storiesFromDB = [];
    querySnapshot.forEach((doc) => {     
        const story = doc.data();
        story.id = doc.id; 
        storiesFromDB.push(story);
    });    

    return storiesFromDB;
}

export async function transferToAnotherAccount(storyId, email){    
    const db = getFirestore();        
    const storyRef = doc(db, "stories", storyId);

    await runTransaction(db, async (transaction) => {
        const storyDoc = await transaction.get(storyRef);
        if (!storyDoc.exists()) {
            throw "Document does not exist!";
        }

        let editedVersionDoc = null;
        
        const q = query(collection(db, "stories"), where("baseStoryId", "==", storyDoc.id), where("baseStory", "==", false));
        const querySnapshot = await getDocs(q);            
        querySnapshot.forEach((doc) => {            
            editedVersionDoc = doc;
        });    

        if (editedVersionDoc != null){
            const editedVersionRef = doc(db, "stories", editedVersionDoc.id);
            transaction.update(editedVersionRef, {
                transferTo: email != null ? email : deleteField(),     
                savedTime: new Date().getTime(),              
            });
        }

        transaction.update(storyRef, {
            transferTo: email != null ? email : deleteField(),     
            savedTime: new Date().getTime(),              
        });
    });
}

export async function getStoryTransfers(fbResponse){    
    if (fbResponse.email == null || fbResponse.emailVerified == null || ! fbResponse.emailVerified) return null;

    const db = getFirestore();     
    const q = query(collection(db, "stories"), where("transferTo", "==", fbResponse.email), where("baseStory", "==", true));        

    const querySnapshot = await getDocs(q);
    const storiesFromDB = [];
    querySnapshot.forEach((doc) => {     
        const story = doc.data();
        story.id = doc.id; 
        storiesFromDB.push(story);
    });

    return storiesFromDB;
}

export async function acceptTransfer(storyToTransfer, newUid){
    const db = getFirestore();        
    const storyRef = doc(db, "stories", storyToTransfer.id);

    let story = await runTransaction(db, async (transaction) => {
        const storyDoc = await transaction.get(storyRef);
        if (!storyDoc.exists()) {
            throw "Document does not exist!";
        }

        let editedVersionDoc = null;
        
        const q = query(collection(db, "stories"), where("baseStoryId", "==", storyDoc.id), where("baseStory", "==", false));
        const querySnapshot = await getDocs(q);            
        querySnapshot.forEach((doc) => {            
            editedVersionDoc = doc;
        });    

        if (editedVersionDoc != null){
            const editedVersionRef = doc(db, "stories", editedVersionDoc.id);
            transaction.update(editedVersionRef, {
                uid: newUid,
                previousUids: arrayUnion(storyToTransfer.uid),
                transferTo: deleteField(),     
                savedTime: new Date().getTime(),              
            });
        }

        transaction.update(storyRef, {
            uid: newUid,
            previousUids: arrayUnion(storyToTransfer.uid),
            transferTo: deleteField(),     
            savedTime: new Date().getTime(),              
        });
    })
    .then(()=> {
        const storage = getStorage();   
        const storyStorageRef = ref(storage, 'restaurants/' + storyToTransfer.uid + "/" + storyToTransfer.id);   
        
        // Find all the prefixes and items.
        listAll(storyStorageRef)
            .then((res) => {            
                res.items.forEach((itemRef) => {
                    const newOwnerMetadata = {
                        customMetadata: {
                        'uid': newUid,                          
                        }
                    };  
                    updateMetadata(itemRef, newOwnerMetadata);
                });
            }); 
        
        if (storyToTransfer.anonymousUid != null){
            const anonymousStorageRef = ref(storage, 'restaurants/' + storyToTransfer.anonymousUid + "/" + storyToTransfer.id);
            // Find all the prefixes and items.
            listAll(anonymousStorageRef)
                .then((res) => {            
                    res.items.forEach((itemRef) => {
                        const newOwnerMetadata = {
                            customMetadata: {
                            'uid': newUid,                          
                            }
                        };  
                        updateMetadata(itemRef, newOwnerMetadata);
                    });
                }); 
        }
    })
    .then(()=> getDoc(storyRef));

    return story;
}

export async function deleteStoriesFromUser(uid){
    const db = getFirestore();  

    const allStoriesFromUserQ = query(collection(db, "stories"), where("uid", "==", uid), where("baseStory", "==", true));
    const allStoriesFromUser = await getDocs(allStoriesFromUserQ); 

    for (const doc of allStoriesFromUser.docs) {
        await deleteStoryVersions(doc.id);
    }
}

export async function deleteUserFromDB(uid){
    const db = getFirestore();  
    await deleteDoc(doc(db, "users", uid));
}

export async function getUser(uid){
    const db = getFirestore();    
    const userRef = doc(db, "users", uid);
    const userDoc = await getDoc(userRef);
    const user = userDoc.data();
    user.id = uid;
    return user;
}