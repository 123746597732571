import { useState, useContext } from "react";
import { BlockPicker } from "react-color";
import { updateMainColor } from "../MakerController";
import { analyticsManager } from "../..";
import { MakerContext } from "../MakerContext";

function ColorPicker(props) {
    const [showColorPicker, setShowColorPicker] = useState(false);
    const storyId = useContext(MakerContext).get("storyId");
    const setHighlight = useContext(MakerContext).get("setHighlight");
    let colors = ['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722', '#795548', '#607D8B', '#FFFFFF', '#000000'];
    if (props.type === "primaryColor" && props.source === "my_restaurant_form"){
        colors = ['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722', '#795548', '#607D8B', '#000000'];
    }

    const handleClick = () => {
        setShowColorPicker(!showColorPicker);
    };

    const handleChange = (color) => { 
        if (props.handleChange != null){
            props.handleChange(color);
        }
        // TODO do outside
        else if (props.type === "primaryColor" && props.source === "my_restaurant_form"){
            analyticsManager.logChoosePrimaryColorTag(storyId, "my_restaurant_form");
            updateMainColor(storyId, color.hex, props.type)
                .then(() => setTimeout(()=>{setHighlight("book share")}, 1000) );
        }
        else if (props.type === "secondaryColor" && props.source === "my_restaurant_form"){
            analyticsManager.logChooseSecondaryColorTag(storyId, "my_restaurant_form");
            updateMainColor(storyId, color.hex, props.type)
                .then(() => setTimeout(()=>{setHighlight("share")}, 1000) );
        }

    };

    const handleClose = () => {
        setShowColorPicker(false);
    };

    return (
        <div style={props.style}>
            <div onClick={ handleClick } style={{width:48, height: 48, borderRadius: 24, backgroundColor: "#F0F0F0", boxShadow: '0 0 0 1px rgba(0,0,0,.4)', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <div id={props.id} name={props.id} style={{width:40, height: 40, borderRadius: 20, backgroundColor:props.color, boxShadow: '0 0 0 1px rgba(0,0,0,.4)'}} />
            </div>
            { showColorPicker ? <div style={{ position: 'absolute', zIndex: '2', }}>
            <div style={{marginTop: 10,  }} onClick={ handleClose }/>
            <BlockPicker triangle="hide" color={ props.color } colors={colors} onChangeComplete={ handleChange } />
            </div> : null }
            {showColorPicker &&<div style={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0, width: '100%', height: '100%'}} onClick={(e)=>{
                if (showColorPicker){
                    e.stopPropagation();
                    setShowColorPicker(false);                    
                }            
                }} />
            }
        </div>
    );
}

export default ColorPicker;