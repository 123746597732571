import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import React, {useState, useContext} from 'react';
import {MakerContext} from "../MakerContext";
import MaterialButton from "./MaterialButton";
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import {AppContext} from '../../AppContext';
import { useNavigate } from "react-router-dom";
import { abandonChanges, resetStory } from '../MenuController';
import ConfirmDialog from './ConfirmDialog';
import { analyticsManager } from '../..';
import LineBreak from './LineBreak';

export default function MobileMenu(props){
    const savingText = useContext(MakerContext).get("savingText");  
    const setPreviewDisplayed = useContext(MakerContext).get("setPreviewDisplayed");
    const fbResponse = useContext(AppContext).get("fbResponse");
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const [showCancelConfirm, setShowCancelConfirm] = useState(false);       
    const [showResetConfirm, setShowResetConfirm] = useState(false);   
    const baseStory = useContext(MakerContext).get("baseStory");
    const story = useContext(MakerContext).get("story");
    const storyId = useContext(MakerContext).get("storyId");
    const activeForm = useContext(MakerContext).get("activeForm");
    const navigate = useNavigate();    
    const { t } = useTranslation();

    function showButtons(){
        if (fbResponse != null && fbResponse.isAnonymous != null && fbResponse.isAnonymous){
            return (
                <div style={{display: "flex", flexDirection: "row", justifyContent: 'center', background: 'white', borderTopLeftRadius: 10, borderTopRightRadius: 10}}>                                  
                    <IconButton aria-label="restart" onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "reset_story", true);setShowResetConfirm(true);}}>
                        <CancelIcon />
                    </IconButton>
                    <div style={{width: 8}}/>
                    <MaterialButton style="filled" onClick={() => {analyticsManager.logPreviewStoryTag(storyId, true, activeForm);setPreviewDisplayed(true);}} value={t('Preview')}/>
                </div>
            );
        }
        else if (fbResponse != null && fbResponse.isAnonymous != null && !fbResponse.isAnonymous){

            function showCancelButton(){                
                if (props.story != null && !props.story.baseStory){
                    return (
                        <>
                            <IconButton aria-label="cancel" onClick={()=>{analyticsManager.logOpenPopupTag(storyId, "abandon_changes", true);setShowCancelConfirm(true);}}>
                                <CancelIcon />
                            </IconButton>
                            <div style={{width: 8}}/>
                        </>
                    );
                }
                else return null;
            }

            return (
                <div style={{display: "flex", flexDirection: "row", justifyContent: 'center', background: 'white', borderTopLeftRadius: 10, borderTopRightRadius: 10}}>                    
                    {showCancelButton()}
                    <MaterialButton style="filled" onClick={() => {analyticsManager.logPreviewStoryTag(storyId, true, activeForm);setPreviewDisplayed(true);}} value={t('Preview')}/>
                </div>
            );
        }
        else return null;
    }

    if (isTabletOrMobile){
        return (
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <LineBreak/>                
                <div id="saving" name="saving" style={{fontSize: 14, fontStyle: "italic", marginBottom: 16}}>{savingText}</div>                
                {showButtons()}  
                {showCancelConfirm && <ConfirmDialog showConfirm={showCancelConfirm} setShowConfirm={setShowCancelConfirm} dialogTitle={t('AbandonChanges')} dialogText={t('AbandonStoryWarning')} dialogAction={t('Abandon')} tagName="abandon_changes" onSuccess={()=>{analyticsManager.logAbandonChangesTag(storyId, true,activeForm);abandonChanges(baseStory, props.story, props.storyId, navigate);}} />}
                {showResetConfirm && <ConfirmDialog showConfirm={showResetConfirm} setShowConfirm={setShowResetConfirm} dialogTitle={t('RestartStory')} dialogText={t('RestartStoryWarning')} dialogAction={t('Restart')} tagName="reset_story" onSuccess={()=>{analyticsManager.logResetStoryTag(storyId, true, activeForm);resetStory(story, storyId, setShowResetConfirm);}} />}                                
            </div>
        );
    }
    else return null;
}