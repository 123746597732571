// TODO cut in many : player specific, maker specific, shared
import { getImageSize } from 'react-image-size';
import * as Sentry from "@sentry/react";

// not sure
export function isTikTokInAppBrowser(){
    return navigator.userAgent.toLowerCase().indexOf("bytedance") !== -1 || navigator.userAgent.toLowerCase().indexOf("tiktok") !== -1;
}

export function isChromeiOS(){
  return navigator.userAgent.indexOf("CriOS") !== -1;
}

export function isFacebookInAppBrowser(){
  return /FB_IAB/.test(navigator.userAgent) || /FBAN/.test(navigator.userAgent) || /FBAV/.test(navigator.userAgent);
}

export function isSnapchatInAppBrowser(){
  return navigator.userAgent.toLowerCase().indexOf("snapchat") !== -1;
}

export function isInstagramInAppBrowser(){
  return navigator.userAgent.toLowerCase().indexOf("instagram") !== -1;
}

export function log(message, ...optionalParams){
  if(process.env.NODE_ENV === 'development'){
      console.log(message, ...optionalParams);
  }
}

export function isAndroid(){
  return /(android)/i.test(navigator.userAgent);
}

export function isFirefox(){
  return navigator.userAgent.toLowerCase().includes('firefox');
}

export function isSafari(){
  return navigator.userAgent.toLowerCase().includes('safari') && !navigator.userAgent.toLowerCase().includes('chrome');
}

export function isEdge(){
  return navigator.userAgent.toLowerCase().includes('edge');
}

export function isChromeOnMacOS(){
  return navigator.userAgent.toLowerCase().includes('chrome') && isMacOS();
}

export function isChrome(){
  // https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
  // please note, 
  // that IE11 now returns undefined again for window.chrome
  // and new Opera 30 outputs true for window.chrome
  // but needs to check if window.opr is not undefined
  // and new IE Edge outputs to true now for window.chrome
  // and if not iOS Chrome check
  // so use the below updated condition
  var isChromium = window.chrome;
  var winNav = window.navigator;
  var vendorName = winNav.vendor;
  var isOpera = typeof window.opr !== "undefined";
  var isIEedge = winNav.userAgent.indexOf("Edg") > -1;
  var isIOSChrome = winNav.userAgent.match("CriOS");

  if (isIOSChrome) {
    // is Google Chrome on IOS
    return true;
  } else if(
    isChromium !== null &&
    typeof isChromium !== "undefined" &&
    vendorName === "Google Inc." &&
    isOpera === false &&
    isIEedge === false
  ) {
    // is Google Chrome
    return true;
  } else { 
    // not Google Chrome 
    return false;
  }
}

export function isIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document) || /iPad|iPhone|iPod/.test(navigator.userAgent)/* || ('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)*/;
  }

export function isIPhone() {
  return [
    'iPhone',
    'iPhone Simulator'
  ].includes(navigator.platform)
}

export function isIpad() {
  return [
    'iPad Simulator',
    'iPad'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document) || /iPad/.test(navigator.userAgent);
}

/**
 * 
 * @returns not tested
 */
export function getIOSVersion() {
  if (typeof navigator !== 'undefined' && isIOS()) {
    const match = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (match) {
      return [parseInt(match[1], 10), parseInt(match[2], 10), parseInt(match[3] || '0', 10)];
    }
  }
  return null;
}

export function isMacOS() {
  return navigator.userAgent.includes("Mac") && !isIpad();
}
  
export function checkMobile() {
  var isMobile = false; //initiate as false
  // device detection
  if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))
      || isIOS() // do better, duplicates with first lines
      ) { 
      isMobile = true;
  }

  return isMobile;
};

  export function browserLocales(languageCodeOnly = false) {
    return navigator.languages.map((locale) =>
      languageCodeOnly ? locale.split("-")[0] : locale,
    );
  }

  export function getAppLanguage(forceFr = false) {
    if (forceFr){
      return "fr";
    }
    else {
      return browserLocales(true)[0] === "fr" ? "fr" : "en";
    }
  }

  export function getTextWidth(txt, font) {    
    const element = document.createElement('canvas');
    const context = element.getContext("2d");
    context.font = font;
    
    return context.measureText(txt).width;
  }

  export function addOpacityToHexColor(hexColor, opacity){
    if (hexColor == null){
      return null;
    }

    let hex = hexColor.replace('#', '');
    return '#' + hex + Math.round(opacity * 255).toString(16).padStart(2, '0');
  }

  // TODO change
  export function getCurrencySymbol(locale, currency){
                
    let currencySymbol = '€';
    if (locale != null && currency != null){                   
        currencySymbol = (0).toLocaleString(locale, { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, '').trim();                
    }                
    
    return currencySymbol;
}

export function removeDiacritics(str) {

  var defaultDiacriticsRemovalMap = [
    {'base':'A', 'letters':/[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g},
    {'base':'AA','letters':/[\uA732]/g},
    {'base':'AE','letters':/[\u00C6\u01FC\u01E2]/g},
    {'base':'AO','letters':/[\uA734]/g},
    {'base':'AU','letters':/[\uA736]/g},
    {'base':'AV','letters':/[\uA738\uA73A]/g},
    {'base':'AY','letters':/[\uA73C]/g},
    {'base':'B', 'letters':/[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g},
    {'base':'C', 'letters':/[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g},
    {'base':'D', 'letters':/[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g},
    {'base':'DZ','letters':/[\u01F1\u01C4]/g},
    {'base':'Dz','letters':/[\u01F2\u01C5]/g},
    {'base':'E', 'letters':/[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g},
    {'base':'F', 'letters':/[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g},
    {'base':'G', 'letters':/[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g},
    {'base':'H', 'letters':/[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g},
    {'base':'I', 'letters':/[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g},
    {'base':'J', 'letters':/[\u004A\u24BF\uFF2A\u0134\u0248]/g},
    {'base':'K', 'letters':/[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g},
    {'base':'L', 'letters':/[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g},
    {'base':'LJ','letters':/[\u01C7]/g},
    {'base':'Lj','letters':/[\u01C8]/g},
    {'base':'M', 'letters':/[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g},
    {'base':'N', 'letters':/[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g},
    {'base':'NJ','letters':/[\u01CA]/g},
    {'base':'Nj','letters':/[\u01CB]/g},
    {'base':'O', 'letters':/[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g},
    {'base':'OI','letters':/[\u01A2]/g},
    {'base':'OO','letters':/[\uA74E]/g},
    {'base':'OU','letters':/[\u0222]/g},
    {'base':'P', 'letters':/[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g},
    {'base':'Q', 'letters':/[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g},
    {'base':'R', 'letters':/[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g},
    {'base':'S', 'letters':/[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g},
    {'base':'T', 'letters':/[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g},
    {'base':'TZ','letters':/[\uA728]/g},
    {'base':'U', 'letters':/[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g},
    {'base':'V', 'letters':/[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g},
    {'base':'VY','letters':/[\uA760]/g},
    {'base':'W', 'letters':/[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g},
    {'base':'X', 'letters':/[\u0058\u24CD\uFF38\u1E8A\u1E8C]/g},
    {'base':'Y', 'letters':/[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g},
    {'base':'Z', 'letters':/[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g},
    {'base':'a', 'letters':/[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g},
    {'base':'aa','letters':/[\uA733]/g},
    {'base':'ae','letters':/[\u00E6\u01FD\u01E3]/g},
    {'base':'ao','letters':/[\uA735]/g},
    {'base':'au','letters':/[\uA737]/g},
    {'base':'av','letters':/[\uA739\uA73B]/g},
    {'base':'ay','letters':/[\uA73D]/g},
    {'base':'b', 'letters':/[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g},
    {'base':'c', 'letters':/[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g},
    {'base':'d', 'letters':/[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g},
    {'base':'dz','letters':/[\u01F3\u01C6]/g},
    {'base':'e', 'letters':/[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g},
    {'base':'f', 'letters':/[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g},
    {'base':'g', 'letters':/[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g},
    {'base':'h', 'letters':/[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g},
    {'base':'hv','letters':/[\u0195]/g},
    {'base':'i', 'letters':/[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g},
    {'base':'j', 'letters':/[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g},
    {'base':'k', 'letters':/[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g},
    {'base':'l', 'letters':/[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g},
    {'base':'lj','letters':/[\u01C9]/g},
    {'base':'m', 'letters':/[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g},
    {'base':'n', 'letters':/[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g},
    {'base':'nj','letters':/[\u01CC]/g},
    {'base':'o', 'letters':/[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g},
    {'base':'oi','letters':/[\u01A3]/g},
    {'base':'ou','letters':/[\u0223]/g},
    {'base':'oo','letters':/[\uA74F]/g},
    {'base':'p','letters':/[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g},
    {'base':'q','letters':/[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g},
    {'base':'r','letters':/[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g},
    {'base':'s','letters':/[\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g},
    {'base':'t','letters':/[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g},
    {'base':'tz','letters':/[\uA729]/g},
    {'base':'u','letters':/[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g},
    {'base':'v','letters':/[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g},
    {'base':'vy','letters':/[\uA761]/g},
    {'base':'w','letters':/[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g},
    {'base':'x','letters':/[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g},
    {'base':'y','letters':/[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g},
    {'base':'z','letters':/[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g}
  ];

  for(var i=0; i<defaultDiacriticsRemovalMap.length; i++) {
    str = str.replace(defaultDiacriticsRemovalMap[i].letters, defaultDiacriticsRemovalMap[i].base);
  }

  return str;

}

export function openFullscreen() {
  var elem = document.documentElement;
  // TODO prevent on desktop with developer mode
  if (document.fullscreenElement || window.innerWidth > 480 || ! checkMobile() || isIpad() || isTikTokInAppBrowser() || window.location.pathname.startsWith("/maker") || window.location.pathname === "/"){
    return;
  }

  if (elem.requestFullscreen) {
    elem.requestFullscreen().catch(err => {});
  } else if (elem.webkitRequestFullscreen) { /* Safari */
    try {
      elem.webkitRequestFullscreen();
    }
    catch (error) {}
  } else if (elem.msRequestFullscreen) { /* IE11 */
    elem.msRequestFullscreen();
  }
}

// TODO never used ? normal ?
export function closeFullscreen() {
  // TODO prevent on desktop with developer mode
  if (! document.fullscreenElement || ! checkMobile()){
    return;
  }

  if (document.exitFullscreen) {
    document.exitFullscreen().catch(err => {});
  } else if (document.webkitExitFullscreen) { /* Safari */
    try {
      document.webkitExitFullscreen();
    }
    catch (error) {}
  } else if (document.msExitFullscreen) { /* IE11 */
  document.msExitFullscreen();
  }
}

export function getMimeType(url){
  if (url == null){
      return null;
  }

  const startIdx = url.lastIndexOf('.');  
  const endIdx = url.lastIndexOf('?');

  return url.substring(startIdx + 1, endIdx);
}

export function getFileNameFromUrl(url){
  if (url == null){
      return null;
  }

  const startIdx = url.lastIndexOf('/');
  const endIdx = url.lastIndexOf('?');

  if (endIdx === -1)
    if (startIdx != url.length - 1)
      return url.substring(startIdx + 1);
    else
      return null;
  else
    if (startIdx != url.length - 1)
      return url.substring(startIdx + 1, endIdx);
    else
      return null;
}

export function reduceMusicFileName(url){
  if (url == null){
      return null;
  }
  let fileName = getFileNameFromUrl(url);
  if (url.startsWith(process.env.REACT_APP_STORAGE_LOCATION)){
    // music prefix
    fileName = fileName.substring(6);
  }
  let fileFormat = getFileFormat(fileName);
  
  if (fileName == null || fileFormat == null){
    return null;
  }

  return fileName.length > 8 ? fileName.substring(0, 8) + "..." + fileFormat : fileName + "." + fileFormat;
}

export function getDomainName(url) {
  try {
    // Create a URL object to handle parsing
    const parsedUrl = new URL(url);

    // Return the hostname property of the parsed URL
    return parsedUrl.hostname;
  } catch (error) {    
    return null;
  }
}

export function getFileFormat(fileName){
  if (fileName == null){
      return null;
  }

  const startIdx = fileName.lastIndexOf('.');
  if (startIdx === -1)
    return null;

  return fileName.substring(startIdx + 1);
}

export function getFileNameWithoutFormat(fileName){
  if (fileName == null){
      return null;
  }

  const startIdx = fileName.lastIndexOf('.');
  if (startIdx === -1)
    return fileName;

  return fileName.substring(0, startIdx);
}

export async function fetchImageSize(url) {
  if (url == null) return null;

  let dimensions = null;
  try {
      dimensions = await getImageSize(url);            
  } catch (error) {
    Sentry.captureMessage(printObj(error));
  }

  return dimensions;
}

export function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

// https://github.com/eligrey/FileSaver.js/wiki/Saving-a-remote-file#using-http-header
export async function getFileFromURL(url){
  var myHeaders = new Headers();
  const fileName = getFileNameFromUrl(url);
  myHeaders.append('Content-Type', 'application/octet-stream; charset=utf-8');  
  myHeaders.append('Content-Disposition', 'attachment; filename="' + fileName + '"; filename*="' + fileName + '"');

  // TODO cors vs no-cors ??
  var myInit = {
    method: "GET",
    headers: myHeaders,
    mode: "no-cors",
    cache: "default",
  };

  const response = await fetch(url, myInit);

  if (!response.ok) {
    Sentry.captureMessage("getFileFromURL error " + printObj(response));
    return null;
  }
  else {
    return response.blob();
  }     
}

export function isImg(format){ 
  if (format == null){
      return false;
  }

  const lCaseFormat = format.toLowerCase();
  
  if (lCaseFormat === "gif" || lCaseFormat === "png" || lCaseFormat === "jpeg" || lCaseFormat === "jpg" || lCaseFormat === "bmp" || lCaseFormat === "webp" || lCaseFormat === "svg"){
      return true;
  }
  else {
      return false;
  }        
}

export function isVideo(format){ 
  if (format == null){
      return false;
  }
      
  const lCaseFormat = format.toLowerCase();
  
  if (lCaseFormat === "mp4" || lCaseFormat === "mpeg" || lCaseFormat === "mov"){
      return true;
  }
  else {
      return false;
  }        
}

export function isAudio(format){ 
  if (format == null){
      return false;
  }

  const lCaseFormat = format.toLowerCase();
  
  if (lCaseFormat === "wav" || lCaseFormat === "mp3" || lCaseFormat === "m4a"){
      return true;
  }
  else {
      return false;
  }        
}

export function lighterColor(color1, color2) {
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  const hsl1 = rgbToHsl(rgb1.r, rgb1.g, rgb1.b);
  const hsl2 = rgbToHsl(rgb2.r, rgb2.g, rgb2.b);  

  if (hsl1[2] > hsl2[2]){
    return color1;
  }
  else {
    return color2;
  }
}

export function darkerColor(color1, color2) {
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  const hsl1 = rgbToHsl(rgb1.r, rgb1.g, rgb1.b);
  const hsl2 = rgbToHsl(rgb2.r, rgb2.g, rgb2.b);  

  if (hsl1[2] < hsl2[2]){
    return color1;
  }
  else {
    return color2;
  }
}

var hexDigits = new Array
        ("0","1","2","3","4","5","6","7","8","9","a","b","c","d","e","f"); 

//Function to convert rgb color to hex format
export function rgb2hex(rgb) {
 rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
 return "#" + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
}

function hex(x) {
  return isNaN(x) ? "00" : hexDigits[(x - x % 16) / 16] + hexDigits[x % 16];
 }

export function hexToRgb(hex){
  if (hex == null){
    return null;
  }

  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);

  return {r: r, g: g, b: b};
}

export function rgbToHsl(r, g, b) {
  r /= 255; g /= 255; b /= 255;
  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);
  let d = max - min;
  let h;
  if (d === 0) h = 0;
  else if (max === r) h = (g - b) / d % 6;
  else if (max === g) h = (b - r) / d + 2;
  else if (max === b) h = (r - g) / d + 4;
  let l = (min + max) / 2;
  let s = d === 0 ? 0 : d / (1 - Math.abs(2 * l - 1));
  return [h * 60, s, l];
}

export function hslToRgb(h, s, l) {
  let c = (1 - Math.abs(2 * l - 1)) * s;
  let hp = h / 60.0;
  let x = c * (1 - Math.abs((hp % 2) - 1));
  let rgb1;
  if (isNaN(h)) rgb1 = [0, 0, 0];
  else if (hp <= 1) rgb1 = [c, x, 0];
  else if (hp <= 2) rgb1 = [x, c, 0];
  else if (hp <= 3) rgb1 = [0, c, x];
  else if (hp <= 4) rgb1 = [0, x, c];
  else if (hp <= 5) rgb1 = [x, 0, c];
  else if (hp <= 6) rgb1 = [c, 0, x];
  let m = l - c * 0.5;

  return [
    Math.abs(Math.round(255 * (rgb1[0] + m))),
    Math.abs(Math.round(255 * (rgb1[1] + m))),
    Math.abs(Math.round(255 * (rgb1[2] + m)))];
}

export function get100Color(hexColor){
  const rgb = hexToRgb(hexColor);
  const hsl = rgbToHsl(rgb.r, rgb.g, rgb.b);  
  hsl[1] = 0.85;
  hsl[2] = 0.85;

  const rgbTable = hslToRgb(hsl[0], hsl[1], hsl[2]);
  return rgb2hex("rgb(" + rgbTable[0] + ", " + rgbTable[1] + ", " + rgbTable[2] + ")")
}

export function getPlus200Color(hexColor){
  const rgb = hexToRgb(hexColor);  
  const hsl = rgbToHsl(rgb.r, rgb.g, rgb.b);
  hsl[1] = Math.min(hsl[1] + 0.05, 1);
  hsl[2] = Math.min(hsl[2] + 0.05, 1);

  const rgbTable = hslToRgb(hsl[0], hsl[1], hsl[2]);
  return rgb2hex("rgb(" + rgbTable[0] + ", " + rgbTable[1] + ", " + rgbTable[2] + ")");
}

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function printObj(obj){
  return JSON.stringify(obj);
}

export function getFileExtensionFromMimeType(mimeType){
  let fileExtension = null;
  
  switch (mimeType) {
    case "image/jpeg": fileExtension = "jpg"; break;
    case "image/gif": fileExtension = "gif"; break;
    case "image/png": fileExtension = "png"; break;
    case "image/webp": fileExtension = "webp"; break;
    case "image/svg+xml": fileExtension = "svg"; break;
    case "image/bmp": fileExtension = "bmp"; break;
    case "video/mp4": fileExtension = "mp4"; break;
    case "video/quicktime": fileExtension = "mov"; break;
    case "video/webm": fileExtension = "webm"; break;
    default: {
      const idx = mimeType.lastIndexOf('/');
      if (idx !== -1){
        fileExtension = mimeType.substring(idx + 1);
      }
      else
        fileExtension = null;
    }
    break;
  }

  return fileExtension;
}

export function getMimeTypeFromFileExtension(fileExtension){

  let mimeType = null;

  switch (fileExtension) {
    case "jpg": mimeType = "image/jpeg"; break;
    case "jpeg": mimeType = "image/jpeg"; break;
    case "gif": mimeType = "image/gif"; break;
    case "png": mimeType = "image/png"; break;
    case "webp": mimeType = "image/webp"; break;
    case "svg": mimeType = "image/svg+xml"; break;
    case "bmp": mimeType = "image/bmp"; break;
    case "mp4": mimeType = "video/mp4"; break;
    case "mov": mimeType = "video/quicktime"; break;
    case "webm": mimeType = "video/webm"; break;
    default: mimeType = null; break;
  }

  return mimeType;
}

export function isSupportedByPlayer(fileExtension){

  if (fileExtension == null){
    return false;
  }

  switch (fileExtension) {
    case "jpg": return true;
    case "jpeg": return true;
    case "gif": return true;
    case "png": return true;
    case "webp": return true;
    case "svg": return true;
    case "bmp": return true;
    case "mp4": return true;
    case "mov": return true;
    case "webm": return false;
    default: return false;
  }
}

export function weekdayTextToGroupedByDaysOpeningHours(weekdayText){
  if (weekdayText == null){
    return null;
  }

  const groupedOpeningHours = [];
  let startingDay = null;
  let lastDayHours = null;
  let endingDay = null;

  for (let i = 0; i <= weekdayText.length; i++){
    let day = null;
    let hours = null;

    if (i < weekdayText.length){
      day = weekdayText[i].substring(0, weekdayText[i].indexOf(':'));
      hours = weekdayText[i].substring(weekdayText[i].indexOf(':') + 2);
    }

    if (i > 1){
      // days to group
      if (lastDayHours === hours){
        // nothing
        endingDay = day;
      }
      else {
        groupedOpeningHours.push({startingDay: capitalizeFirstLetter(startingDay), endingDay: capitalizeFirstLetter(endingDay), hours: lastDayHours});
      }
    }
    if (lastDayHours === hours){
      // nothing
    }
    else {
      startingDay = day;
      endingDay = day;
      lastDayHours = hours;
    }
    
  }

  if (groupedOpeningHours.length > 4){
    return {format: "day", openingHours: revertTo1lineDayFormat(weekdayText)};
  }
  else {
    return {format: "grouped", openingHours: optimizeHoursText(groupedOpeningHours)};
  }
}

function revertTo1lineDayFormat(fullOpeningHours){  
  for (let i = 0; i < fullOpeningHours.length; i++){    
    fullOpeningHours[i] = fullOpeningHours[i].replaceAll(":00", "");   
  }

  return fullOpeningHours;
}

function optimizeHoursText(openinghours){
  for (let i = 0; i < openinghours.length; i++){
    openinghours[i].hours = openinghours[i].hours.replaceAll(":00", "");
  }

  return openinghours;
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function isEmailValid(email){
  if (email == null){
    return false;
  }

  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}


var demoReplaceString = "https://storage.googleapis.com/" + process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;

export const demoJsonFr = "{\n" +
"   \"html_attributions\" : [],\n" +
"   \"result\" : {\n" +
"      \"formatted_address\" : \"65 Boulevard Haussmann, 75008 Paris, France\",\n" +
"      \"formatted_phone_number\" : \"01 02 03 04 05\",\n" +
"      \"geometry\" : {\n" +
"         \"location\" : {\n" +
"            \"lat\" : 48.8752071,\n" +
"            \"lng\" : 2.3093031\n" +
"         },\n" +
"         \"viewport\" : {\n" +
"            \"northeast\" : {\n" +
"               \"lat\" : 48.87649663029149,\n" +
"               \"lng\" : 2.310659280291502\n" +
"            },\n" +
"            \"southwest\" : {\n" +
"               \"lat\" : 48.8737986697085,\n" +
"               \"lng\" : 2.307961319708498\n" +
"            }\n" +
"         }\n" +
"      },\n" +
"      \"name\" : \"Café UpReality\",\n" +
"\t\"opening_hours\" : {\n" +
"         \"open_now\" : true,\n" +
"         \"periods\" : [\n" +
"            {\n" +
"               \"close\" : {\n" +
"                  \"day\" : 0,\n" +
"                  \"time\" : \"1730\"\n" +
"               },\n" +
"               \"open\" : {\n" +
"                  \"day\" : 0,\n" +
"                  \"time\" : \"1000\"\n" +
"               }\n" +
"            },\n" +
"            {\n" +
"               \"close\" : {\n" +
"                  \"day\" : 2,\n" +
"                  \"time\" : \"0000\"\n" +
"               },\n" +
"               \"open\" : {\n" +
"                  \"day\" : 1,\n" +
"                  \"time\" : \"0700\"\n" +
"               }\n" +
"            },\n" +
"            {\n" +
"               \"close\" : {\n" +
"                  \"day\" : 3,\n" +
"                  \"time\" : \"0000\"\n" +
"               },\n" +
"               \"open\" : {\n" +
"                  \"day\" : 2,\n" +
"                  \"time\" : \"0700\"\n" +
"               }\n" +
"            },\n" +
"            {\n" +
"               \"close\" : {\n" +
"                  \"day\" : 4,\n" +
"                  \"time\" : \"0000\"\n" +
"               },\n" +
"               \"open\" : {\n" +
"                  \"day\" : 3,\n" +
"                  \"time\" : \"0700\"\n" +
"               }\n" +
"            },\n" +
"            {\n" +
"               \"close\" : {\n" +
"                  \"day\" : 5,\n" +
"                  \"time\" : \"0000\"\n" +
"               },\n" +
"               \"open\" : {\n" +
"                  \"day\" : 4,\n" +
"                  \"time\" : \"0700\"\n" +
"               }\n" +
"            },\n" +
"            {\n" +
"               \"close\" : {\n" +
"                  \"day\" : 6,\n" +
"                  \"time\" : \"0000\"\n" +
"               },\n" +
"               \"open\" : {\n" +
"                  \"day\" : 5,\n" +
"                  \"time\" : \"0700\"\n" +
"               }\n" +
"            },\n" +
"            {\n" +
"               \"close\" : {\n" +
"                  \"day\" : 0,\n" +
"                  \"time\" : \"0000\"\n" +
"               },\n" +
"               \"open\" : {\n" +
"                  \"day\" : 6,\n" +
"                  \"time\" : \"1000\"\n" +
"               }\n" +
"            }\n" +
"         ],\n" +
"         \"weekday_text\" : [\n" +
"            \"lundi: 07:00 – 00:00\",\n" +
"            \"mardi: 07:00 – 00:00\",\n" +
"            \"mercredi: 07:00 – 00:00\",\n" +
"            \"jeudi: 07:00 – 00:00\",\n" +
"            \"vendredi: 07:00 – 00:00\",\n" +
"            \"samedi: 10:00 – 00:00\",\n" +
"            \"dimanche: 10:00 – 17:30\"\n" +
"         ]\n" +
"      },\n" +
"      \"price_level\" : 3,\n" +
"      \"rating\" : 4.2,\n" +
"      \"reviews\" : [\n" +
"         {\n" +
"            \"author_name\" : \"Louise\",\n" +
"            \"author_url\" : \"https://www.google.com/maps/contrib/105686556336503285085/reviews\",\n" +
"            \"language\" : \"fr\",\n" +
"            \"profile_photo_url\" : \"" + demoReplaceString + "/demo/louise.png\",\n" +
"            \"rating\" : 5,\n" +
"            \"relative_time_description\" : \"il y a 9 mois\",\n" +
"            \"text\" : \"Très bon accueil et serveuses adorables, un cadre agréable. L’ensemble est bon mais reste un peu cher pour cette qualité...\",\n" +
"            \"time\" : 1598528396\n" +
"         },\n" +
"         {\n" +
"            \"author_name\" : \"Guy\",\n" +
"            \"author_url\" : \"https://www.google.com/maps/contrib/105635101911253295736/reviews\",\n" +
"            \"language\" : \"fr\",\n" +
"            \"profile_photo_url\" : \"" + demoReplaceString + "/demo/guy.png\",\n" +
"            \"rating\" : 3,\n" +
"            \"relative_time_description\" : \"il y a un an\",\n" +
"            \"text\" : \"Très bonne cuisine. La qualité est au rendez vous. Le chef  est très généreux au niveau des assiettes. Bonne ambiance.\",\n" +
"            \"time\" : 1578659732\n" +
"         },\n" +
"         {\n" +
"            \"author_name\" : \"Joseph\",\n" +
"            \"author_url\" : \"https://www.google.com/maps/contrib/118365252126722458330/reviews\",\n" +
"            \"language\" : \"fr\",\n" +
"            \"profile_photo_url\" : \"" + demoReplaceString + "/demo/joseph.png\",\n" +
"            \"rating\" : 4,\n" +
"            \"relative_time_description\" : \"il y a un an\",\n" +
"            \"text\" : \"Très bonne cuisine. Il y a de la musique (un chanteur). J'y étais avec un groupe d'amis, nous avions aimé. Cadre agréable, je conseille\",\n" +
"            \"time\" : 1575461143\n" +
"         },\n" +
"         {\n" +
"            \"author_name\" : \"Sam\",\n" +
"            \"author_url\" : \"https://www.google.com/maps/contrib/105953037103940705699/reviews\",\n" +
"            \"language\" : \"fr\",\n" +
"            \"profile_photo_url\" : \"" + demoReplaceString + "/demo/guy.png\",\n" +
"            \"rating\" : 5,\n" +
"            \"relative_time_description\" : \"il y a un an\",\n" +
"            \"text\" : \"J'ai adoré mangé ici.\\nLes portions sont très généreuses. La nourriture est bonne a un prix abordable\",\n" +
"            \"time\" : 1578687135\n" +
"         },\n" +
"         {\n" +
"            \"author_name\" : \"Catherine\",\n" +
"            \"author_url\" : \"https://www.google.com/maps/contrib/111627333070002285635/reviews\",\n" +
"            \"language\" : \"fr\",\n" +
"            \"profile_photo_url\" : \"https://lh3.googleusercontent.com/a/AATXAJxZLz3bSEV5yWE_d2kFuG2DN4LUcsQwS6WVRjYh=s128-c0x00000000-cc-rp-mo-ba6\",\n" +
"            \"rating\" : 5,\n" +
"            \"relative_time_description\" : \"il y a un an\",\n" +
"            \"text\" : \"La tradition est respectée, les mets sont de qualité et bien préparés (un peu trop salés).\\nOn pourrait espérer un peu plus de créativité.\",\n" +
"            \"time\" : 1577441075\n" +
"         }\n" +
"      ],\n" +
"      \"user_ratings_total\" : 75\n" +
"   },\n" +
"   \"status\" : \"OK\"\n" +
"}";

export const demoJsonEn = "{\n" +
"   \"html_attributions\" : [],\n" +
"   \"result\" : {\n" +
"      \"formatted_address\" : \"65 Boulevard Haussmann, 75008 Paris, France\",\n" +
"      \"formatted_phone_number\" : \"01 02 03 04 05\",\n" +
"      \"geometry\" : {\n" +
"         \"location\" : {\n" +
"            \"lat\" : 48.8752071,\n" +
"            \"lng\" : 2.3093031\n" +
"         },\n" +
"         \"viewport\" : {\n" +
"            \"northeast\" : {\n" +
"               \"lat\" : 48.87649663029149,\n" +
"               \"lng\" : 2.310659280291502\n" +
"            },\n" +
"            \"southwest\" : {\n" +
"               \"lat\" : 48.8737986697085,\n" +
"               \"lng\" : 2.307961319708498\n" +
"            }\n" +
"         }\n" +
"      },\n" +
"      \"name\" : \"Café UpReality\",\n" +
"\t\"opening_hours\" : {\n" +
"         \"open_now\" : true,\n" +
"         \"periods\" : [\n" +
"            {\n" +
"               \"close\" : {\n" +
"                  \"day\" : 0,\n" +
"                  \"time\" : \"1730\"\n" +
"               },\n" +
"               \"open\" : {\n" +
"                  \"day\" : 0,\n" +
"                  \"time\" : \"1000\"\n" +
"               }\n" +
"            },\n" +
"            {\n" +
"               \"close\" : {\n" +
"                  \"day\" : 2,\n" +
"                  \"time\" : \"0000\"\n" +
"               },\n" +
"               \"open\" : {\n" +
"                  \"day\" : 1,\n" +
"                  \"time\" : \"0700\"\n" +
"               }\n" +
"            },\n" +
"            {\n" +
"               \"close\" : {\n" +
"                  \"day\" : 3,\n" +
"                  \"time\" : \"0000\"\n" +
"               },\n" +
"               \"open\" : {\n" +
"                  \"day\" : 2,\n" +
"                  \"time\" : \"0700\"\n" +
"               }\n" +
"            },\n" +
"            {\n" +
"               \"close\" : {\n" +
"                  \"day\" : 4,\n" +
"                  \"time\" : \"0000\"\n" +
"               },\n" +
"               \"open\" : {\n" +
"                  \"day\" : 3,\n" +
"                  \"time\" : \"0700\"\n" +
"               }\n" +
"            },\n" +
"            {\n" +
"               \"close\" : {\n" +
"                  \"day\" : 5,\n" +
"                  \"time\" : \"0000\"\n" +
"               },\n" +
"               \"open\" : {\n" +
"                  \"day\" : 4,\n" +
"                  \"time\" : \"0700\"\n" +
"               }\n" +
"            },\n" +
"            {\n" +
"               \"close\" : {\n" +
"                  \"day\" : 6,\n" +
"                  \"time\" : \"0000\"\n" +
"               },\n" +
"               \"open\" : {\n" +
"                  \"day\" : 5,\n" +
"                  \"time\" : \"0700\"\n" +
"               }\n" +
"            },\n" +
"            {\n" +
"               \"close\" : {\n" +
"                  \"day\" : 0,\n" +
"                  \"time\" : \"0000\"\n" +
"               },\n" +
"               \"open\" : {\n" +
"                  \"day\" : 6,\n" +
"                  \"time\" : \"1000\"\n" +
"               }\n" +
"            }\n" +
"         ],\n" +
"         \"weekday_text\" : [\n" +
"            \"monday: 07:00 – 00:00\",\n" +
"            \"tuesday: 07:00 – 00:00\",\n" +
"            \"wednesday: 07:00 – 00:00\",\n" +
"            \"thursday: 07:00 – 00:00\",\n" +
"            \"friday: 07:00 – 00:00\",\n" +
"            \"saturday: 10:00 – 00:00\",\n" +
"            \"sunday: 10:00 – 17:30\"\n" +
"         ]\n" +
"      },\n" +
"      \"price_level\" : 3,\n" +
"      \"rating\" : 4.2,\n" +
"      \"reviews\" : [\n" +
"         {\n" +
"            \"author_name\" : \"Louise\",\n" +
"            \"author_url\" : \"https://www.google.com/maps/contrib/105686556336503285085/reviews\",\n" +
"            \"language\" : \"fr\",\n" +
"            \"profile_photo_url\" : \"" + demoReplaceString + "/demo/louise.png\",\n" +
"            \"rating\" : 5,\n" +
"            \"relative_time_description\" : \"9 monthes ago\",\n" +
"            \"text\" : \"Very warm welcome and adorable waitresses, a pleasant setting. The whole is good but still a bit expensive for this quality ...\",\n" +
"            \"time\" : 1598528396\n" +
"         },\n" +
"         {\n" +
"            \"author_name\" : \"Guy\",\n" +
"            \"author_url\" : \"https://www.google.com/maps/contrib/105635101911253295736/reviews\",\n" +
"            \"language\" : \"fr\",\n" +
"            \"profile_photo_url\" : \"" + demoReplaceString + "/demo/guy.png\",\n" +
"            \"rating\" : 3,\n" +
"            \"relative_time_description\" : \"one year ago\",\n" +
"            \"text\" : \"Very good food. The quality is there. The chef is very generous when it comes to the plates. Good atmosphere.\",\n" +
"            \"time\" : 1578659732\n" +
"         },\n" +
"         {\n" +
"            \"author_name\" : \"Joseph\",\n" +
"            \"author_url\" : \"https://www.google.com/maps/contrib/118365252126722458330/reviews\",\n" +
"            \"language\" : \"fr\",\n" +
"            \"profile_photo_url\" : \"" + demoReplaceString + "/demo/joseph.png\",\n" +
"            \"rating\" : 4,\n" +
"            \"relative_time_description\" : \"one year ago\",\n" +
"            \"text\" : \"Very good food. There is music (a singer). I was there with a group of friends, we had liked. Pleasant setting, I recommend\",\n" +
"            \"time\" : 1575461143\n" +
"         },\n" +
"         {\n" +
"            \"author_name\" : \"Sam\",\n" +
"            \"author_url\" : \"https://www.google.com/maps/contrib/105953037103940705699/reviews\",\n" +
"            \"language\" : \"fr\",\n" +
"            \"profile_photo_url\" : \"" + demoReplaceString + "/demo/guy.png\",\n" +
"            \"rating\" : 5,\n" +
"            \"relative_time_description\" : \"one year ago\",\n" +
"            \"text\" : \"I loved eating here.\\nThe portions are very generous. Good food at an affordable price\",\n" +
"            \"time\" : 1578687135\n" +
"         },\n" +
"         {\n" +
"            \"author_name\" : \"Catherine\",\n" +
"            \"author_url\" : \"https://www.google.com/maps/contrib/111627333070002285635/reviews\",\n" +
"            \"language\" : \"fr\",\n" +
"            \"profile_photo_url\" : \"https://lh3.googleusercontent.com/a/AATXAJxZLz3bSEV5yWE_d2kFuG2DN4LUcsQwS6WVRjYh=s128-c0x00000000-cc-rp-mo-ba6\",\n" +
"            \"rating\" : 5,\n" +
"            \"relative_time_description\" : \"one year ago\",\n" +
"            \"text\" : \"Tradition is respected, the dishes are of high quality and well prepared (a little too salty).\\nWe could hope for a little more creativity.\",\n" +
"            \"time\" : 1577441075\n" +
"         }\n" +
"      ],\n" +
"      \"user_ratings_total\" : 75\n" +
"   },\n" +
"   \"status\" : \"OK\"\n" +
"}";