import './../Player.css';

import React, {useState, useContext, useEffect} from 'react';
import { PlayerContext } from '../PlayerContext';
import BookButton from './buttons/BookButton';
import HoursButton from './buttons/HoursButton';
import ReviewsButton from './buttons/ReviewsButton';
import LocationButton from './buttons/LocationButton';
import ShareButton from './buttons/ShareButton';
import {isAndroid, isIOS, isInstagramInAppBrowser, isTikTokInAppBrowser} from '../Utils';
import { useTranslation } from 'react-i18next';
import { ButtonBase, IconButton, styled } from '@mui/material';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { useMediaQuery } from 'react-responsive';
import { analyticsManager } from '../../index';
import HoursButtonNEW from './buttons/HoursButtonNEW';
import ReviewsButtonNEW from './buttons/ReviewsButtonNEW';
import LocationButtonChain from './buttons/LocationButtonChain';
import { getBackgroundVideoElement, isVideoMuted, muteVideo, unmuteVideo } from '../Player';

function SideMenu(props){
    const { t } = useTranslation();
    const miniMultiplier = useContext(PlayerContext).get("miniMultiplier");
    
    const activeFeature = useContext(PlayerContext).get("activeFeature");
    const storyDimensions = useContext(PlayerContext).get("storyDimensions");  
    const playerOnDesktop = useContext(PlayerContext).get("playerOnDesktop"); 
    const fromMaker = useContext(PlayerContext).get("fromMaker");
    const playMode = useContext(PlayerContext).get("playMode");
    const playAudio = useContext(PlayerContext).get("playAudio");
    const setPlayAudio = useContext(PlayerContext).get("setPlayAudio");
    const setAudioAlreadyDisabled = useContext(PlayerContext).get("setAudioAlreadyDisabled");
    const resetAudio = useContext(PlayerContext).get("resetAudio");
    const setResetAudio = useContext(PlayerContext).get("setResetAudio");
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const [bgVideoSoundOn, setBgVideoSoundOn] = useState(false);
    
    const showHours = activeFeature == null || activeFeature === "hours";
    const showReviews = activeFeature == null || activeFeature === "reviews";
    const showLocation = activeFeature == null || activeFeature === "location";
    const showBook = activeFeature == null || activeFeature === "book";
    const showShare = activeFeature == null || activeFeature === "share";    

    useEffect(() => {
        if (resetAudio && playAudio){
            setPlayAudio(false);    
            const volumeBtnOn = document.getElementById("story-volume-btn-on");
            const volumeBtnOff = document.getElementById("story-volume-btn-off");
            if (volumeBtnOn != null && volumeBtnOff != null){
                volumeBtnOff.style.visibility = "hidden";
                volumeBtnOn.style.visibility = "visible";
            }            
        }
        if (resetAudio){
            setResetAudio(false);
        }
    }, [resetAudio]);

    useEffect(()=>{
        // reinit volume button if changed dynamically eg in the gallery
        if (activeFeature == null){       
            
            function updateVolumeButtonVisibility(){
                const volumeBtnOn = document.getElementById("story-volume-btn-on");
                const volumeBtnOff = document.getElementById("story-volume-btn-off");
                if (volumeBtnOn != null && volumeBtnOff != null){
                    if (! isVideoMuted()){                         
                        volumeBtnOff.style.visibility = "visible";
                        volumeBtnOn.style.visibility = "hidden";                                                
                    }
                    else {                              
                        volumeBtnOff.style.visibility = "hidden";
                        volumeBtnOn.style.visibility = "visible";                        
                    }
                }
            }

            function waitAndUpdateVolumeButtonVisibility(){
                if (getBackgroundVideoElement()){
                    updateVolumeButtonVisibility();
                }
                else {
                    setTimeout(updateVolumeButtonVisibility, 200);
                }
            }

            if (props.storyId === "WX2KsZRK4SNKT9hnNIp0" || props.storyId === "cJU0Fnom8MTjmokacI3I" || props.storyId === "ABDe0HTpOfX5iVYDqrcJ" || props.storyId === "LnSX2qKrthKsRmr1vcxc" || props.storyId === "PaBK2iq1At5um1V6nNGo" || props.storyId === "1iDXY4hOyo1PVyrvCmvG" || props.storyId === "4WlhflMBRftrhBabhzqL" || props.storyId === "G4uGdmkDklxxMumN9FIc" || props.storyId === "LQ8cYZF1BrqCbKYOI7g7" || props.storyId === "P4hss0ADGtE2wMY5fcrQ" || props.storyId === "YPuRVqGYO2Q2QFKEkRvQ" || props.storyId === "ZcJ9o5kOjzeIvIZ4z0kw" || props.storyId === "clexvJMGmwlGXjY8KLaT" || props.storyId === "nRyYRVND1xh34m32eGMa" || props.storyId === "rj1ZbTjACKeCMOqqAi4l" || props.storyId === "wfnhdxPJPy3v54dXJAr4" || props.storyId === "xlqSrGF4RJz9yfV2MQrr" || props.storyId === "zUEpyuVwo5Tws3hnvFds"){    
                waitAndUpdateVolumeButtonVisibility();
            }
            else {
                const ambiantPlayer = document.getElementById("audio-player");
                const volumeBtnOn = document.getElementById("story-volume-btn-on");
                const volumeBtnOff = document.getElementById("story-volume-btn-off");
                if (ambiantPlayer != null && volumeBtnOn != null && volumeBtnOff != null){
                    if (! ambiantPlayer.paused || playAudio){                                     
                        volumeBtnOff.style.visibility = "visible";
                        volumeBtnOn.style.visibility = "hidden";                    
                    }
                    else {                                     
                        volumeBtnOff.style.visibility = "hidden";
                        volumeBtnOn.style.visibility = "visible";                    
                    }
                }
            }
        }        
    }, [activeFeature, bgVideoSoundOn])

    if (activeFeature == null || activeFeature === "hours" || activeFeature === "reviews"|| activeFeature === "location" || activeFeature === "book" || activeFeature === "share"){                               
        return (
            <div className="VideoOverlay">                 
                {(props.storyId === "LJUbpj5ZyMMdpqmdDc0e" || props.storyId === "ubbNYdTn4JQHNqRzbH2z" || props.storyId === "dMS24cQaTXn47tuspxet" || props.storyId === "32etJrIVkk0BjlVtPLG1" || props.storyId === "iopJF8UCdIrnOgcYw6Tk" || props.storyId === "LnSX2qKrthKsRmr1vcxc" || props.storyId === "PaBK2iq1At5um1V6nNGo" || props.storyId === "1iDXY4hOyo1PVyrvCmvG" || props.storyId === "4WlhflMBRftrhBabhzqL" || props.storyId === "G4uGdmkDklxxMumN9FIc" || props.storyId === "LQ8cYZF1BrqCbKYOI7g7" || props.storyId === "P4hss0ADGtE2wMY5fcrQ" || props.storyId === "YPuRVqGYO2Q2QFKEkRvQ" || props.storyId === "ZcJ9o5kOjzeIvIZ4z0kw" || props.storyId === "clexvJMGmwlGXjY8KLaT" || props.storyId === "nRyYRVND1xh34m32eGMa" || props.storyId === "rj1ZbTjACKeCMOqqAi4l" || props.storyId === "wfnhdxPJPy3v54dXJAr4" || props.storyId === "xlqSrGF4RJz9yfV2MQrr" || props.storyId === "zUEpyuVwo5Tws3hnvFds" ) && <HoursButtonNEW show={showHours} storyId={props.storyId} story={props.story} placeDetails={props.placeDetails}/>}
                {!(props.storyId === "LJUbpj5ZyMMdpqmdDc0e" || props.storyId === "ubbNYdTn4JQHNqRzbH2z" || props.storyId === "dMS24cQaTXn47tuspxet" || props.storyId === "32etJrIVkk0BjlVtPLG1" || props.storyId === "iopJF8UCdIrnOgcYw6Tk"  || props.storyId === "LnSX2qKrthKsRmr1vcxc" || props.storyId === "PaBK2iq1At5um1V6nNGo" || props.storyId === "1iDXY4hOyo1PVyrvCmvG" || props.storyId === "4WlhflMBRftrhBabhzqL" || props.storyId === "G4uGdmkDklxxMumN9FIc" || props.storyId === "LQ8cYZF1BrqCbKYOI7g7" || props.storyId === "P4hss0ADGtE2wMY5fcrQ" || props.storyId === "YPuRVqGYO2Q2QFKEkRvQ" || props.storyId === "ZcJ9o5kOjzeIvIZ4z0kw" || props.storyId === "clexvJMGmwlGXjY8KLaT" || props.storyId === "nRyYRVND1xh34m32eGMa" || props.storyId === "rj1ZbTjACKeCMOqqAi4l" || props.storyId === "wfnhdxPJPy3v54dXJAr4" || props.storyId === "xlqSrGF4RJz9yfV2MQrr" || props.storyId === "zUEpyuVwo5Tws3hnvFds") && <HoursButton show={showHours} storyId={props.storyId} story={props.story} placeDetails={props.placeDetails}/>}
                {!(props.storyId === "LJUbpj5ZyMMdpqmdDc0e" || props.storyId === "ubbNYdTn4JQHNqRzbH2z" || props.storyId === "dMS24cQaTXn47tuspxet" || props.storyId === "32etJrIVkk0BjlVtPLG1" || props.storyId === "iopJF8UCdIrnOgcYw6Tk"  || props.storyId === "LnSX2qKrthKsRmr1vcxc" || props.storyId === "PaBK2iq1At5um1V6nNGo" || props.storyId === "1iDXY4hOyo1PVyrvCmvG" || props.storyId === "4WlhflMBRftrhBabhzqL" || props.storyId === "G4uGdmkDklxxMumN9FIc" || props.storyId === "LQ8cYZF1BrqCbKYOI7g7" || props.storyId === "P4hss0ADGtE2wMY5fcrQ" || props.storyId === "YPuRVqGYO2Q2QFKEkRvQ" || props.storyId === "ZcJ9o5kOjzeIvIZ4z0kw" || props.storyId === "clexvJMGmwlGXjY8KLaT" || props.storyId === "nRyYRVND1xh34m32eGMa" || props.storyId === "rj1ZbTjACKeCMOqqAi4l" || props.storyId === "wfnhdxPJPy3v54dXJAr4" || props.storyId === "xlqSrGF4RJz9yfV2MQrr" || props.storyId === "zUEpyuVwo5Tws3hnvFds") && <ReviewsButton show={showReviews} storyId={props.storyId} story={props.story} placeDetails={props.placeDetails}/>}
                {(props.storyId === "LJUbpj5ZyMMdpqmdDc0e" || props.storyId === "ubbNYdTn4JQHNqRzbH2z" || props.storyId === "dMS24cQaTXn47tuspxet" || props.storyId === "32etJrIVkk0BjlVtPLG1" || props.storyId === "iopJF8UCdIrnOgcYw6Tk"  || props.storyId === "LnSX2qKrthKsRmr1vcxc" || props.storyId === "PaBK2iq1At5um1V6nNGo" || props.storyId === "1iDXY4hOyo1PVyrvCmvG" || props.storyId === "4WlhflMBRftrhBabhzqL" || props.storyId === "G4uGdmkDklxxMumN9FIc" || props.storyId === "LQ8cYZF1BrqCbKYOI7g7" || props.storyId === "P4hss0ADGtE2wMY5fcrQ" || props.storyId === "YPuRVqGYO2Q2QFKEkRvQ" || props.storyId === "ZcJ9o5kOjzeIvIZ4z0kw" || props.storyId === "clexvJMGmwlGXjY8KLaT" || props.storyId === "nRyYRVND1xh34m32eGMa" || props.storyId === "rj1ZbTjACKeCMOqqAi4l" || props.storyId === "wfnhdxPJPy3v54dXJAr4" || props.storyId === "xlqSrGF4RJz9yfV2MQrr" || props.storyId === "zUEpyuVwo5Tws3hnvFds") && <ReviewsButtonNEW show={showReviews} storyId={props.storyId} story={props.story} placeDetails={props.placeDetails}/>}
                {props.storyId !== "LIiKs8PCYFdRvTtSVuVH" && props.storyId !== "LnSX2qKrthKsRmr1vcxc" && props.storyId !== "cJU0Fnom8MTjmokacI3I" && props.storyId !== "LJUbpj5ZyMMdpqmdDc0e" && props.storyId !== "ZcJ9o5kOjzeIvIZ4z0kw" && props.storyId !== "zUEpyuVwo5Tws3hnvFds" && props.storyId !== "YPuRVqGYO2Q2QFKEkRvQ" &&  <LocationButton show={showLocation} storyId={props.storyId} story={props.story} placeDetails={props.placeDetails}/>}
                {(props.storyId === "LIiKs8PCYFdRvTtSVuVH" || props.storyId === "LnSX2qKrthKsRmr1vcxc" || props.storyId === "cJU0Fnom8MTjmokacI3I" || props.storyId === "LJUbpj5ZyMMdpqmdDc0e" || props.storyId === "ZcJ9o5kOjzeIvIZ4z0kw" || props.storyId === "zUEpyuVwo5Tws3hnvFds" || props.storyId === "YPuRVqGYO2Q2QFKEkRvQ") && <LocationButtonChain show={showLocation} storyId={props.storyId} story={props.story}/>}
                <BookButton show={showBook} className="BookingMenu" storyId={props.storyId} story={props.story} placeDetails={props.placeDetails}/>
                <ShareButton show={showShare} storyId={props.storyId} story={props.story} placeDetails={props.placeDetails}/>
                {activeFeature == null && (props.story.musicUrl || props.storyId === "WX2KsZRK4SNKT9hnNIp0" || props.storyId === "cJU0Fnom8MTjmokacI3I" || props.storyId === "ABDe0HTpOfX5iVYDqrcJ" || props.storyId === "LnSX2qKrthKsRmr1vcxc" || props.storyId === "PaBK2iq1At5um1V6nNGo" || props.storyId === "1iDXY4hOyo1PVyrvCmvG" || props.storyId === "4WlhflMBRftrhBabhzqL" || props.storyId === "G4uGdmkDklxxMumN9FIc" || props.storyId === "LQ8cYZF1BrqCbKYOI7g7" || props.storyId === "P4hss0ADGtE2wMY5fcrQ" || props.storyId === "YPuRVqGYO2Q2QFKEkRvQ" || props.storyId === "ZcJ9o5kOjzeIvIZ4z0kw" || props.storyId === "clexvJMGmwlGXjY8KLaT" || props.storyId === "nRyYRVND1xh34m32eGMa" || props.storyId === "rj1ZbTjACKeCMOqqAi4l" || props.storyId === "wfnhdxPJPy3v54dXJAr4" || props.storyId === "xlqSrGF4RJz9yfV2MQrr" || props.storyId === "zUEpyuVwo5Tws3hnvFds") && playMode !== "demo" && 
                    <>
                        <IconButton id={"story-volume-btn-on"} style={{visibility: 'visible', position: 'absolute', zIndex: 10, top: isTabletOrMobile && fromMaker ? 35 : 0, left: storyDimensions.containerWidth - 48 * miniMultiplier}} onClick={(e)=>toggleSound(e)}>
                            <VolumeUpIcon style={{color: '#FFFFFF90', width: 32 * miniMultiplier, height:32 * miniMultiplier}} />
                        </IconButton>                    
                        <IconButton id={"story-volume-btn-off"} style={{visibility: 'hidden', position: 'absolute', zIndex: 10, top: isTabletOrMobile && fromMaker ? 35 : 0, left: storyDimensions.containerWidth - 48 * miniMultiplier}} onClick={(e)=>toggleSound(e)}>
                            <VolumeOffIcon style={{color: '#FFFFFF90', width: 32 * miniMultiplier, height:32 * miniMultiplier}} />
                        </IconButton> 
                    </>
                }
            </div>
        );        
    }
    else {        
        return null;
    }    

    function toggleSound(e){ 
        if (props.storyId === "WX2KsZRK4SNKT9hnNIp0" || props.storyId === "cJU0Fnom8MTjmokacI3I" || props.storyId === "ABDe0HTpOfX5iVYDqrcJ" || props.storyId === "LnSX2qKrthKsRmr1vcxc" || props.storyId === "PaBK2iq1At5um1V6nNGo" || props.storyId === "1iDXY4hOyo1PVyrvCmvG" || props.storyId === "4WlhflMBRftrhBabhzqL" || props.storyId === "G4uGdmkDklxxMumN9FIc" || props.storyId === "LQ8cYZF1BrqCbKYOI7g7" || props.storyId === "P4hss0ADGtE2wMY5fcrQ" || props.storyId === "YPuRVqGYO2Q2QFKEkRvQ" || props.storyId === "ZcJ9o5kOjzeIvIZ4z0kw" || props.storyId === "clexvJMGmwlGXjY8KLaT" || props.storyId === "nRyYRVND1xh34m32eGMa" || props.storyId === "rj1ZbTjACKeCMOqqAi4l" || props.storyId === "wfnhdxPJPy3v54dXJAr4" || props.storyId === "xlqSrGF4RJz9yfV2MQrr" || props.storyId === "zUEpyuVwo5Tws3hnvFds"){    
            const volumeBtnOn = document.getElementById("story-volume-btn-on");
            const volumeBtnOff = document.getElementById("story-volume-btn-off");
            if (volumeBtnOn != null && volumeBtnOff != null){
                if (! bgVideoSoundOn){                    
                    setBgVideoSoundOn(true);
                    unmuteVideo();
                    volumeBtnOff.style.visibility = "visible";
                    volumeBtnOn.style.visibility = "hidden";
                    trackVolumeOn();
                }
                else {                    
                    setBgVideoSoundOn(false);       
                    muteVideo();             
                    volumeBtnOff.style.visibility = "hidden";
                    volumeBtnOn.style.visibility = "visible";
                    trackVolumeOff();
                }
            }
        }
        else {       
            const ambiantPlayer = document.getElementById("audio-player");
            const volumeBtnOn = document.getElementById("story-volume-btn-on");
            const volumeBtnOff = document.getElementById("story-volume-btn-off");
            if (ambiantPlayer != null && volumeBtnOn != null && volumeBtnOff != null){
                if (! playAudio){
                    setPlayAudio(true);
                    volumeBtnOff.style.visibility = "visible";
                    volumeBtnOn.style.visibility = "hidden";
                    trackVolumeOn();
                }
                else {
                    setPlayAudio(false);
                    setAudioAlreadyDisabled(true);
                    volumeBtnOff.style.visibility = "hidden";
                    volumeBtnOn.style.visibility = "visible";
                    trackVolumeOff();
                }
            }            
        }
        e.stopPropagation();
    }

    function trackVolumeOn(){
        analyticsManager.logSetVolumeMusicTag(props.storyId, playMode, true);
        if (window.ttq){
            window.ttq.track('ViewContent', {                
                "contents": [
                    {              
                        "content_id": "0",           
                        "content_name": "volume on",
                    }
                ],
            });
        }
        if (window.fbq){                    
            window.fbq('track', 'ViewContent', {"content_name": "volume on"});
        }
    }

    function trackVolumeOff(){
        analyticsManager.logSetVolumeMusicTag(props.storyId, playMode, false);
        if (window.ttq){
            window.ttq.track('ViewContent', {                
                "contents": [
                    {              
                        "content_id": "0",           
                        "content_name": "volume off",
                    }
                ],
            });
        }
        if (window.fbq){                    
            window.fbq('track', 'ViewContent', {"content_name": "volume off"});
        }
    }
}

export default SideMenu;