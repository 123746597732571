import { useContext } from "react";
import { PlayerContext } from "../../PlayerContext";

const DialogContainer = ({children, ...props})=>{
    const storyDimensions = useContext(PlayerContext).get("storyDimensions");
    const styleOverride = {};
    if ((props.storyId === "LJUbpj5ZyMMdpqmdDc0e" || props.storyId === "dMS24cQaTXn47tuspxet" || props.storyId === "32etJrIVkk0BjlVtPLG1" || props.storyId === "iopJF8UCdIrnOgcYw6Tk" || props.storyId === "LnSX2qKrthKsRmr1vcxc" || props.storyId === "PaBK2iq1At5um1V6nNGo" || props.storyId === "1iDXY4hOyo1PVyrvCmvG" || props.storyId === "4WlhflMBRftrhBabhzqL" || props.storyId === "G4uGdmkDklxxMumN9FIc" || props.storyId === "LQ8cYZF1BrqCbKYOI7g7" || props.storyId === "P4hss0ADGtE2wMY5fcrQ" || props.storyId === "YPuRVqGYO2Q2QFKEkRvQ" || props.storyId === "ZcJ9o5kOjzeIvIZ4z0kw" || props.storyId === "clexvJMGmwlGXjY8KLaT" || props.storyId === "nRyYRVND1xh34m32eGMa" || props.storyId === "rj1ZbTjACKeCMOqqAi4l" || props.storyId === "wfnhdxPJPy3v54dXJAr4" || props.storyId === "xlqSrGF4RJz9yfV2MQrr" || props.storyId === "zUEpyuVwo5Tws3hnvFds")){
        styleOverride.background = "#FFFFFFB0";
    } 

    if (props.opened){
        return (
            <div id="dialog-background" name="dialog-background" style={{position: "absolute", zIndex : 20, width: storyDimensions.containerWidth, height: storyDimensions.containerHeight, background: '#00000080', display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", ...styleOverride}} onClick={(e)=>{if (e.target.id == "dialog-background") props.onClose();}}>
                <div style={{backgroundColor: "white", borderRadius: 20,}}>                    
                    {children}
                </div>
            </div>
        );
    }
    else return null;
}

export default DialogContainer;