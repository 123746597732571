import { useRef, useState } from 'react';
import { ButtonBase } from '@mui/material';
import { isFirefox } from '../../Utils';

function ImageButton(props){
    const [isHovered, setIsHovered] = useState(false);

    const targetedElement = useRef(null);

    const handleMouseEnter = () => {
        setIsHovered(true);    ;
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    function onTouchStartListener(e){
        targetedElement.current = e.target;
        e.stopPropagation();
    }

    function onTouchEndListener(e){
        if(targetedElement.current === e.target){
            props.onClick();
        };
        targetedElement.current = null;
        e.stopPropagation();
        e.preventDefault();
    }

    return (
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{borderRadius: 20, width: props.entryImageDimensions, height: props.entryImageDimensions, ...props.style}}>            
            <ButtonBase onTouchStart={(e)=>onTouchStartListener(e)} onTouchEnd={(e)=>onTouchEndListener(e)} onClick={()=>{props.onClick();}} sx={{color: 'white', borderRadius: 20,  width: props.entryImageDimensions, height: props.entryImageDimensions,}}>
                <img alt={isFirefox() ? "":props.alt} src={props.entryImage} onLoad={(e) => e.target.style.color='white'} onError={(e) => e.target.style.color='transparent'} style={{color: 'transparent', width: props.entryImageDimensions, height: props.entryImageDimensions, objectFit: 'cover', borderRadius: 20, pointerEvents: 'none'}}/>                                 
                {isHovered && <div style={{position: "absolute", zIndex: 20, width: props.entryImageDimensions, height: props.entryImageDimensions, background: "#FFFFFF80"}}/>}
            </ButtonBase>
        </div>
    );

}

export default ImageButton;