
import { setUserProperties, logEvent, setUserId } from "firebase/analytics";

class AnalyticsManager {

    constructor(analytics) {
        this.analytics = analytics;
    }

    setUserId(uid){
        setUserId(this.analytics, uid);
    }

    setUserProperty(){    
        if (window.location.pathname.includes("/p/")){
            setUserProperties(this.analytics, {app_type: "web_player"});
          }
          else {
            setUserProperties(this.analytics, {app_type: "web_maker"}); 
          }
    }  
        
    logHomePageScreenViewTag() {
        let bundle = {
            screen_name: "home_page",
            screen_class: "ProductPage",
        };

        logEvent(this.analytics, 'screen_view', bundle);
    }

    logHomePageAdScreenViewTag() {
        let bundle = {
            screen_name: "home_page_ad",
            screen_class: "InstantAdHP",
        };

        logEvent(this.analytics, 'screen_view', bundle);
    }

    logClickInWebDemoTag(){
        let bundle = {
            
        };

        logEvent(this.analytics, 'click_web_demo', bundle);
    }

    logScrollInWebDemoTag(){
        let bundle = {

        };

        logEvent(this.analytics, 'scroll_web_demo', bundle);
    }

    logHPTryMakerTag(origin) {
        let bundle = {
            origin: origin,
        };
        
        logEvent(this.analytics, 'try_maker', bundle);
    }

    logHPCreate1Clic(){
        let bundle = {
            
        };

        logEvent(this.analytics, 'create_1_clic', bundle);
    }

    logLoginTag(method) {
        let bundle = {
            method: method,
        };

        logEvent(this.analytics, 'login', bundle);
    }
    
    logSendEmailLinkTag() {
        let bundle = {
            
        };

        logEvent(this.analytics, 'send_email_link', bundle);
    }

    logCancelLoginTag(method) {
        let bundle = {
            method: method,
        };

        logEvent(this.analytics, 'cancel_login', bundle);
    }

    logAcceptTermsTag(acceptOverCancel) {
        let bundle = {
            positive_action_over_negative: acceptOverCancel
        };

        logEvent(this.analytics, 'accept_terms', bundle);
    }

    /**
     * maker without login to login
     */
    logLoginFromAnonymousTag(uid, anonymousUid) {
        let bundle = {
            uid: uid,
            anonymous_uid: anonymousUid,
        };

        logEvent(this.analytics, 'login_from_anonymous', bundle);
    }

    /**
     * only from the product page to the maker without login and without previous login     
     */
    logFBIdToAnonymousTag(firebaseId, anonymousUid) {
        let bundle = {
            firebase_installation_id: firebaseId,
            anonymous_uid: anonymousUid
        };

        logEvent(this.analytics, 'fbid_to_anonymous', bundle);
    }

    /**
     * only from the product page, without previous login
     */
    logFBIdToLoggedTag(firebaseId, uid) {
        let bundle = {
            firebase_installation_id: firebaseId,
            uid: uid
        };

        logEvent(this.analytics, 'fbid_to_uid', bundle);
    }

    logChoosePageTag(storyId) {
        let bundle = {    
            item_id: storyId        
        };

        logEvent(this.analytics, 'choose_page', bundle);
    }

    logReadFAQTag(origin, faqId){
        let bundle = {
            origin: origin,
            faq_id: faqId,
        };
        
        logEvent(this.analytics, 'read_faq', bundle);
    }

    logReadCGUTag(origin){
        let bundle = {
            origin: origin
        };
        
        logEvent(this.analytics, 'read_cgu', bundle); 
    }
    
    logReadPrivacyTag(){
        let bundle = {
            origin: origin
        };
        
        logEvent(this.analytics, 'read_terms', bundle); 
    }   
    
    /**
     * before the restaurant is chosen
     */
    logMakerScreenViewTag() {
        let bundle = {
            screen_name: "maker",
            screen_class: "maker",
        };

        logEvent(this.analytics, 'screen_view', bundle);
    }    

    /**
     * after the restaurant is chosen
     */
    logChooseRestaurantScreenViewTag(storyId) {
        let bundle = {
            screen_name: "choose_restaurant",
            screen_class: "choose_restaurant",
            item_id: storyId
        };

        logEvent(this.analytics, 'screen_view', bundle);
    }
    
    logChooseRestaurantTag(storyId, city, country, reservable){
        let bundle = {
            item_id: storyId,
            city: city,
            country: country,
            reservable: reservable
        };
        
        logEvent(this.analytics, 'choose_restaurant', bundle); 
    }

    logChooseBusinessTypeTag(storyId, businessType, addOverRemove){
        let bundle = {
            item_id: storyId,
            business_type: businessType,
            positive_action_over_negative: addOverRemove,
        };
        
        logEvent(this.analytics, 'choose_business_type', bundle); 
    }

    logChooseNeighborhoodTag(storyId, neighborhood, addOverRemove){
        let bundle = {
            item_id: storyId,
            neighborhood: neighborhood,
            positive_action_over_negative: addOverRemove,
        };
        
        logEvent(this.analytics, 'choose_neighborhood', bundle); 
    }

    logChoosePhoneNumberTag(storyId, origin, addOverRemove){
        let bundle = {
            item_id: storyId,
            origin: origin,
            positive_action_over_negative: addOverRemove,
        };
        
        logEvent(this.analytics, 'choose_phone_number', bundle); 
    }

    logOpenPopupTag(storyId, popupName, openOverClose, playMode = null){
        let bundle = {
            item_id: storyId,
            positive_action_over_negative: openOverClose,
            popup_name: popupName,
        };

        if (playMode){
            bundle.play_mode = playMode;
        }
        
        logEvent(this.analytics, 'open_popup', bundle); 
    }

    logOpenTabTag(storyId, tabName){
        let bundle = {
            item_id: storyId,
            tab_name: tabName,
        };
        
        logEvent(this.analytics, 'open_tab', bundle); 
    }

    logOpenAccountMenuTag(storyId, accountOption){
        let bundle = {
            item_id: storyId,
            account_option: accountOption,
        };
        
        logEvent(this.analytics, 'open_account_option', bundle); 
    }    

    logAuthorizePhoneNumberTag(storyId, authorizeOverNot){
        let bundle = {
            item_id: storyId,
            positive_action_over_negative: authorizeOverNot,
        };
        
        logEvent(this.analytics, 'authorize_phone_number', bundle); 
    }

    logReplaceGBookingByWebLinkTag(storyId){
        let bundle = {
            item_id: storyId,
        };

        logEvent(this.analytics, 'replace_g_booking_by_web_link', bundle);
    }
    
    logReplaceWebLinkByGBookingTag(storyId){
        let bundle = {
            item_id: storyId,
        };

        logEvent(this.analytics, 'replace_web_link_by_g_booking', bundle);
    }

    logChooseBookingLinkTag(storyId, addOverRemove){
        let bundle = {
            item_id: storyId,
            positive_action_over_negative: addOverRemove,
        };
        
        logEvent(this.analytics, 'choose_booking_link', bundle); 
    }
    
    logAddOrderingPlatformTag(storyId, addOverRemove){
        let bundle = {
            item_id: storyId,
            positive_action_over_negative: addOverRemove,
        };
        
        logEvent(this.analytics, 'add_ordering_platform', bundle); 
    }

    logRestartStoryTag(storyId, restartOverCancel){
        let bundle = {
            item_id: storyId,
            positive_action_over_negative: restartOverCancel,
        };
        
        logEvent(this.analytics, 'restart_story', bundle); 
    }   
        
    logMyRestaurantScreenViewTag(storyId) {
        let bundle = {
            screen_name: "my_restaurant",
            screen_class: "my_restaurant",
            item_id: storyId
        };

        logEvent(this.analytics, 'screen_view', bundle);
    }

    logConnectInstagramTag(storyId, addOverRemove){
        let bundle = {
            item_id: storyId,
            positive_action_over_negative: addOverRemove,
        };
        
        logEvent(this.analytics, 'connect_instagram', bundle); 
    }

    logChooseMyLogoTag(storyId, from, type, addOverRemove){
        let bundle = {
            item_id: storyId,
            content_source: from,
            content_type: type,
            positive_action_over_negative: addOverRemove,
        };
        
        logEvent(this.analytics, 'choose_logo', bundle); 
    }

    logChoosePrimaryColorTag(storyId, from){
        let bundle = {
            item_id: storyId,
            content_source: from,            
        };
        
        logEvent(this.analytics, 'choose_primary_color', bundle); 
    }

    logChooseSecondaryColorTag(storyId, from){
        let bundle = {
            item_id: storyId,
            content_source: from,            
        };
        
        logEvent(this.analytics, 'choose_secondary_color', bundle); 
    }

    logChooseMyMusicTag(storyId, from, type, addOverRemove){
        let bundle = {
            item_id: storyId,
            content_source: from,
            content_type: type,
            positive_action_over_negative: addOverRemove,
        };
        
        logEvent(this.analytics, 'choose_music', bundle); 
    }

    logSetVolumeMusicTag(storyId, playMode, onOverOff){
        let bundle = {
            item_id: storyId,
            play_mode: playMode,
            positive_action_over_negative: onOverOff,
        };
        
        logEvent(this.analytics, 'set_volume_music', bundle); 
    }

    logChooseTaglineTag(storyId, addOverRemove){
        let bundle = {
            item_id: storyId,
            positive_action_over_negative: addOverRemove,
        };            
        
        logEvent(this.analytics, 'choose_tagline', bundle); 
    }
            
    logChooseStoreFrontScreenViewTag(storyId) {
        let bundle = {
            screen_name: "choose_store_front",
            screen_class: "choose_store_front",
            item_id: storyId
        };

        logEvent(this.analytics, 'screen_view', bundle);
    }

    logChooseStoreFrontTag(storyId, source, type, addOverRemove) {
        let bundle = {
            item_id: storyId,
            content_source: source,
            content_type: type,
            positive_action_over_negative: addOverRemove,
        };

        logEvent(this.analytics, 'choose_store_front', bundle);
    }    

    logChooseInsideTag(storyId, source, type, addOverRemove) {
        let bundle = {
            item_id: storyId,
            content_source: source,
            content_type: type,
            positive_action_over_negative: addOverRemove,
        };

        logEvent(this.analytics, 'choose_inside', bundle);
    }
    
    logChooseMenuTag(storyId, source, type, addOverRemove) {
        let bundle = {
            item_id: storyId,
            content_source: source,
            content_type: type,
            positive_action_over_negative: addOverRemove,
        };

        logEvent(this.analytics, 'choose_menu', bundle);
    }

    logNextPageTag(storyId, origin) {
        let bundle = {
            item_id: storyId,
            origin: origin
        };

        logEvent(this.analytics, 'next_page', bundle);
    }

    logPreviousPageTag(storyId, origin) {
        let bundle = {
            item_id: storyId,
            origin: origin
        };

        logEvent(this.analytics, 'previous_page', bundle);
    }

    logClickWeTransferTag(storyId){
        let bundle = {
            item_id: storyId
        };

        logEvent(this.analytics, 'click_we_transfer', bundle);

    }

    logChooseStyleTag(storyId, type, color) {
        const changeType = type == null ? color : type + "_" + color;
        let bundle = {
            item_id: storyId,
            type: changeType,
        };

        logEvent(this.analytics, 'choose_style', bundle);
    }

    logDragNDropTag(storyId, origin) {
        let bundle = {
            item_id: storyId,
            origin: origin
        };

        logEvent(this.analytics, 'drag_drop', bundle);
    }
                
    logChooseMenuScreenViewTag(storyId) {
        let bundle = {
            screen_name: "choose_menu",
            screen_class: "choose_menu",
            item_id: storyId
        };

        logEvent(this.analytics, 'screen_view', bundle);
    }

    logChooseFullMenuTag(storyId, source, type, addOverRemove) {
        let bundle = {
            item_id: storyId,
            content_source: source,
            content_type: type,
            positive_action_over_negative: addOverRemove,
        };

        logEvent(this.analytics, 'choose_full_menu', bundle);
    }

    logChooseDayMenuTag(storyId, source, type, addOverRemove) {
        let bundle = {
            item_id: storyId,
            content_source: source,
            content_type: type,
            positive_action_over_negative: addOverRemove,
        };

        logEvent(this.analytics, 'choose_day_menu', bundle);
    }
    
    logDeleteStoryTag(storyId, deleteOverCancel) {
        let bundle = {
            item_id: storyId,
            positive_action_over_negative: deleteOverCancel
        };

        logEvent(this.analytics, 'delete_story', bundle);
    }

    logAbandonChangesTag(storyId, abandonOverCancel, origin) {
        let bundle = {
            item_id: storyId,
            positive_action_over_negative: abandonOverCancel,
            origin: origin
        };

        logEvent(this.analytics, 'abandon_changes', bundle);
    }
    
    logResetStoryTag(storyId, resetOverCancel, origin) {
        let bundle = {
            item_id: storyId,
            positive_action_over_negative: resetOverCancel,
            origin: origin
        };

        logEvent(this.analytics, 'reset_story', bundle);
    }

    logPreviewStoryTag(storyId, openOverClose, origin) {
        let bundle = {
            item_id: storyId,
            positive_action_over_negative: openOverClose,
            origin: origin
        };

        logEvent(this.analytics, 'preview_story', bundle);
    }

    logCheckAccountMenuTag() {
        let bundle = {
            
        };

        logEvent(this.analytics, 'check_account_menu', bundle);
    }
    
    logLogoutTag(logoutOverCancel) {
        let bundle = {
            positive_action_over_negative: logoutOverCancel
        };

        logEvent(this.analytics, 'logout', bundle);
    }

    logOptimizeMenuTag(storyId, enableOverDisable) {
        let bundle = {
            item_id: storyId,
            positive_action_over_negative: enableOverDisable,
        };

        logEvent(this.analytics, 'optimizeMenu', bundle);
    }

    logPublishTag(storyId) {
        let bundle = {
            item_id: storyId
        };

        logEvent(this.analytics, 'publish_story', bundle);
    }  
    
    logReturnStoriesTag(storyId) {
        let bundle = {
            item_id: storyId
        };

        logEvent(this.analytics, 'return_stories', bundle);
    } 
                
    logUseItScreenViewTag(storyId) {
        let bundle = {
            screen_name: "use_it",
            screen_class: "use_it",
            item_id: storyId
        };

        logEvent(this.analytics, 'screen_view', bundle);
    }
        
    logChangeLinkTag(storyId, changeOverCancel) {
        let bundle = {
            item_id: storyId,
            positive_action_over_negative: changeOverCancel
        };

        logEvent(this.analytics, 'list_stories', bundle);
    }

    logListStoriesTag(storyId, origin) {
        let bundle = {
            item_id: storyId,
            origin: origin
        };

        logEvent(this.analytics, 'list_stories', bundle);
    }

    logListStoriesScreenViewTag() {
        let bundle = {
            screen_name: "list_stories",
            screen_class: "list_stories"
        };

        logEvent(this.analytics, 'screen_view', bundle);
    }

    logPreferencesScreenViewTag() {
        let bundle = {
            screen_name: "preferences",
            screen_class: "preferences"
        };

        logEvent(this.analytics, 'screen_view', bundle);
    }

    logClickHelpTag() {
        let bundle = {

        };

        logEvent(this.analytics, 'click_help', bundle);
    }

    logBackToPreferencesFromHelpTag() {
        let bundle = {
            
        };

        logEvent(this.analytics, 'back_help_to_preferences', bundle);
    }

    logHelpScreenViewTag() {
        let bundle = {
            screen_name: "help",
            screen_class: "help"
        };

        logEvent(this.analytics, 'screen_view', bundle);
    }

    logReadLegalsTag() {
        let bundle = {
            
        };

        logEvent(this.analytics, 'read_legals', bundle);
    }
    
    logBackToPreferencesFromLegalsTag() {
        let bundle = {
            
        };

        logEvent(this.analytics, 'back_legals_to_preferences', bundle);
    }

    logLegalsScreenViewTag() {
        let bundle = {
            screen_name: "legals",
            screen_class: "legals"
        };

        logEvent(this.analytics, 'screen_view', bundle);
    }

    logAssociateToFacebookTag() {

        logEvent(this.analytics, 'associate_to_facebook');
    }

    logTransferTag(transferOverCancel) {
        let bundle = {
            positive_action_over_negative: transferOverCancel
        };

        logEvent(this.analytics, 'transfer', bundle);
    }

    logAcceptTransferTag(storyId) {
        let bundle = {
            item_id: storyId
        };

        logEvent(this.analytics, 'accept_transfer', bundle);
    }

    logDeleteAccountTag() {
        let bundle = {
           
        };

        logEvent(this.analytics, 'delete_account', bundle);
    }

    logCreateStoryTag() {
        let bundle = {
            
        };

        logEvent(this.analytics, 'create_story', bundle);
    }

    logClickStatDetailTag(storyId) {
        let bundle = {  
            item_id: storyId
        };

        logEvent(this.analytics, 'click_stat_detail', bundle);
    }

    logCreateStoryTag() {
        let bundle = {  

        };

        logEvent(this.analytics, 'create_story', bundle);
    }

    logViewStatDetailTag(storyId) {
        let bundle = {
            item_id: storyId
        };

        logEvent(this.analytics, 'view_stat_detail', bundle);
    }

    logStatDetailScreenViewTag(storyId) {
        let bundle = {
            screen_name: "stat_detail",
            screen_class: "Stats",
            item_id: storyId
        };

        logEvent(this.analytics, 'screen_view', bundle);
    }

    logCloseDetailScreenViewTag(storyId) {
        let bundle = {
            item_id: storyId
        };

        logEvent(this.analytics, 'close_detail_stat', bundle);
    }

    logModifyStoryScreenViewTag(storyId, downsizeRunning = false) {
        let bundle = {
            item_id: storyId
        };
        if (downsizeRunning){
            bundle.downsize_running = downsizeRunning;
        }

        logEvent(this.analytics, 'modify_story', bundle);
    }
    
    logLaunchStoryScreenViewTag(storyId) {
        let bundle = {
            item_id: storyId
        };

        logEvent(this.analytics, 'launch_story', bundle);
    }
        
    logCopyLinkTag(storyId, origin) {
        let bundle = {
            item_id: storyId,
            origin: origin,
        };

        logEvent(this.analytics, 'copy_link', bundle);
    }

    logPlayStoryScreenViewTag(storyId, playMode, fromMetaShare){        
        let bundle = {
            item_id: storyId,
            screen_name: "story",
            play_mode: playMode,
            screen_class: "Player",
            from_meta_share: fromMetaShare
        };
        
        logEvent(this.analytics, 'screen_view', bundle);        
    }    
            
    logShareTag(storyId, playMode) {
        let bundle = {
            item_id: storyId,
            play_mode: playMode,
        };

        logEvent(this.analytics, 'share', bundle);
    }
                
    logBookTag(storyId, playMode, type) {
        let bundle = {
            item_id: storyId,
            play_mode: playMode,
            type: type
        };

        logEvent(this.analytics, 'book', bundle);
    }
                
    logContactTag(storyId, playMode, type) {
        let bundle = {
            item_id: storyId,
            play_mode: playMode,
            type: type
        };

        logEvent(this.analytics, 'contact', bundle);
    }
                    
    logCheckHoursTag(storyId, playMode, openOverClose) {
        let bundle = {
            item_id: storyId,
            play_mode: playMode,
            positive_action_over_negative: openOverClose
        };

        logEvent(this.analytics, 'check_hours', bundle);
    }
                    
    logCheckReviewsTag(storyId, playMode, openOverClose) {
        let bundle = {
            item_id: storyId,
            play_mode: playMode,
            positive_action_over_negative: openOverClose
        };

        logEvent(this.analytics, 'check_reviews', bundle);
    }
                        
    logCheckItineraryTag(storyId, playMode) {
        let bundle = {
            item_id: storyId,
            play_mode: playMode,
        };

        logEvent(this.analytics, 'check_itinerary', bundle);
    }
                            
    logOpenInsideTag(storyId, playMode, openOverClose) {
        let bundle = {
            item_id: storyId,
            play_mode: playMode,
            positive_action_over_negative: openOverClose
        };

        logEvent(this.analytics, 'open_inside', bundle);
    }
                                
    logSwipeInsideTag(storyId, playMode) {
        let bundle = {
            item_id: storyId,
            play_mode: playMode,
        };

        logEvent(this.analytics, 'swipe_inside', bundle);
    }
                                
    logOpenMenuTag(storyId, playMode, openOverClose) {
        let bundle = {
            item_id: storyId,
            play_mode: playMode,
            positive_action_over_negative: openOverClose
        };

        logEvent(this.analytics, 'open_menu', bundle);
    }
                                
    logSwipeMenuTag(storyId, playMode) {
        let bundle = {
            item_id: storyId,
            play_mode: playMode,
        };

        logEvent(this.analytics, 'swipe_menu', bundle);
    }

    logOpenFullMenuTag(storyId, playMode, type, openOverClose) {
        let bundle = {
            item_id: storyId,
            play_mode: playMode,
            type: type,
            positive_action_over_negative: openOverClose
        };

        logEvent(this.analytics, 'open_full_menu', bundle);
    }

    logOpenDishDetalTag(storyId, playMode, openOverClose) {
        let bundle = {
            item_id: storyId,
            play_mode: playMode,
            positive_action_over_negative: openOverClose
        };

        logEvent(this.analytics, 'open_dish_detail', bundle);
    }
                                    
    logSwipeFullMenuTag(storyId, playMode) {
        let bundle = {
            item_id: storyId,
            play_mode: playMode,
        };

        logEvent(this.analytics, 'swipe_full_menu', bundle);
    }
   
}

export default AnalyticsManager;
