import './../../Player.css';
import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { PlayerContext } from '../../PlayerContext';
import { openFullscreen, getTextWidth, isAndroid, isInstagramInAppBrowser, isTikTokInAppBrowser, isFirefox } from '../../Utils';
import { analyticsManager } from '../../../index';
import SecondaryButton, {reviewsType} from '../components/SecondaryButton';
import { appAnalyticsManager } from '../../Player';
import * as Sentry from "@sentry/react";
import CloseButton from '../components/CloseButton';
import { useAnimate } from 'framer-motion';

function ReviewsButtonNEW(props){    
    const [isOpened, setIsOpened] = useState(false);

    const storyDimensions = useContext(PlayerContext).get("storyDimensions");
    const propagateFocusChange = useContext(PlayerContext).get("propagateFocusChange");
    const pauseVideo = useContext(PlayerContext).get("pauseVideo");
    const setActiveFeature = useContext(PlayerContext).get("setActiveFeature");
    const activeFeature = useContext(PlayerContext).get("activeFeature");
    const appAnalyticsManager = useContext(PlayerContext).get("appAnalyticsManager");
    const playMode = useContext(PlayerContext).get("playMode");
    const miniMultiplier = useContext(PlayerContext).get("miniMultiplier");
    const setPlayAudio = useContext(PlayerContext).get("setPlayAudio");
    const audioAlreadyDisabled = useContext(PlayerContext).get("audioAlreadyDisabled");
    const playerOnDesktop = useContext(PlayerContext).get("playerOnDesktop");
    const fromMaker = useContext(PlayerContext).get("fromMaker");
    const fromDemo = useContext(PlayerContext).get("fromDemo");

    const { t } = useTranslation();

    const [scope, animate] = useAnimate();

    useEffect(() => {
        if (props.placeDetails && props.show && isOpened && activeFeature === "reviews"){           
            const galleryOverlay = document.getElementById("gallery-overlay");
            if (galleryOverlay != null){  
                const animation = async () => {                       
                    animate(galleryOverlay, { opacity: 1}, {duration: 0.3});                    
                }
                
                animation();
            }                        
        }
    }, [props.placeDetails, props.show, isOpened, activeFeature]);

    if (props.story.posId == null) return (null);

    const reviewNbToShow = 3;

    const closeWidth = 42 * miniMultiplier;
    const closeHeight = 40 * miniMultiplier;
    const brushesWidth = miniMultiplier === 1 ? 328 : 328 * miniMultiplier * 0.85;
    const brushesHeight = miniMultiplier === 1 ? 204 : 204 * miniMultiplier * 0.85;
    const reviewTextWidth = storyDimensions.containerWidth - 32 * miniMultiplier;
    const heightBtwNameNBg = 10 * miniMultiplier;
    const reviewWidth = brushesWidth;
    const reviewHeight = brushesHeight + heightBtwNameNBg;
    const reviewsLayoutHeight = reviewNbToShow * reviewHeight;
    const closeBtnX = storyDimensions.containerWidth - closeWidth - 8 * miniMultiplier;
    const closeBtnY = storyDimensions.containerHeight - closeHeight - 8 * miniMultiplier;
    const googleY = (storyDimensions.containerHeight - reviewsLayoutHeight)/2 + reviewsLayoutHeight + 20 * miniMultiplier;

    const open = () => {                       
        if (!fromMaker && !fromDemo && !playerOnDesktop && !audioAlreadyDisabled){            
            const ambiantPlayer = document.getElementById("audio-player");
            if (ambiantPlayer && ambiantPlayer.paused){
                ambiantPlayer.play();
            }
            setPlayAudio(true);
        }
        pauseVideo();
        openFullscreen();
        setActiveFeature("reviews");
        setIsOpened(true);

        analyticsManager.logCheckReviewsTag(props.storyId, playMode, true);
        
        if (appAnalyticsManager)
            appAnalyticsManager.clickedReviews();

        if (window.ttq){
            window.ttq.track('ViewContent', {                
                "contents": [
                    {              
                        "content_id": "0",           
                        "content_name": "reviews",
                    }
                ],
            });
        }
        if (window.fbq){                    
            window.fbq('track', 'ViewContent', {"content_name": "reviews"});
        }
    };

    const close = () => {
        propagateFocusChange(true);
        setIsOpened(false);
        setActiveFeature(null);
      };
    
    if (props.show && props.placeDetails && props.placeDetails.reviews && props.placeDetails.reviews.length > 0){        
        if (isOpened && activeFeature === "reviews"){
            let lineNb = 0;

            const reviews = props.placeDetails.reviews.map((review) =>{                       
                const ratingBarWidth = Math.min(reviewWidth, Math.max((40 + 8) * miniMultiplier + getTextWidth(review.author_name, 16 * miniMultiplier + "px Roboto"), (40 + 8) * miniMultiplier + getTextWidth(review.rating + " - " + review.relative_time_description, 14 * miniMultiplier + "px Roboto") + (14 + 2 + 2) * miniMultiplier) + 12 * miniMultiplier);
                
                if (review.rating < 4) return null;
                
                lineNb = lineNb + 1;            
                if (lineNb <= reviewNbToShow){
                    return <div key={review.text} style={{width: reviewTextWidth, zIndex: 20,}}>                                                  
                        <div id={"review-" + lineNb + "-rating"} >
                            <div className="review-rating-vignette" style={{position: "relative", zIndex: 20, width: ratingBarWidth, height: 40 * miniMultiplier, fontSize: 16 * miniMultiplier, backgroundColor: props.story.primaryColor}}>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'left'}}>
                                    <div>
                                        <img onLoad={(e) => e.target.style.color='white'} onError={(e) => {e.target.style.display="none";}} style={{zIndex: 20, color: 'transparent', marginLeft: -2 * miniMultiplier, marginTop: -1 * miniMultiplier, marginBottom: -1 * miniMultiplier, width: 42 * miniMultiplier, height: 42 * miniMultiplier, pointerEvents: 'none'}} alt={isFirefox() ? "":"reviewer avatar"} src={review.profile_photo_url} />                        
                                    </div>

                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'left', alignContent: 'left', justifyContent: 'left'}}>
                                        <div className="review-rating-name" style={{marginTop: 2 * miniMultiplier, marginLeft: 8 * miniMultiplier, fontSize: 16 * miniMultiplier}}>
                                            {review.author_name}
                                        </div>
                                        <div className="review-rating-rating" style={{marginLeft: 8 * miniMultiplier, fontSize: 14 * miniMultiplier}}>
                                        {review.rating}
                                        <img alt={t('Star')} src="/images/star.png" style={{width: 14 * miniMultiplier, height: 14 * miniMultiplier, marginLeft: 2 * miniMultiplier, pointerEvents: 'none'}}/>
                                        &nbsp;-&nbsp;
                                        <div style={{marginLeft: 2 * miniMultiplier}}>{review.relative_time_description}</div>                               
                                        </div>
                                    </div>
                                </div>                                                                
                            </div>                            
                        </div>
                        <div id={"review-" + lineNb + "-desc"} style={{zIndex: 20, marginTop: 8 * miniMultiplier, width: reviewTextWidth - 16 * miniMultiplier, background: 'white', padding: 8 * miniMultiplier, borderRadius: 8, }}>
                            <div style={{fontSize: 16 * miniMultiplier * 0.85, overflow: 'hidden', textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: 6, lineClamp: 6, WebkitBoxOrient: 'vertical'}}>
                                {review.text}
                            </div>
                        </div>
                    </div>
                }
                else {
                    return null
                }            
            }
            );

            const styleOverride = {};
            if ((props.storyId === "LJUbpj5ZyMMdpqmdDc0e" || props.storyId === "dMS24cQaTXn47tuspxet" || props.storyId === "32etJrIVkk0BjlVtPLG1" || props.storyId === "iopJF8UCdIrnOgcYw6Tk" || props.storyId === "LnSX2qKrthKsRmr1vcxc" || props.storyId === "PaBK2iq1At5um1V6nNGo" || props.storyId === "1iDXY4hOyo1PVyrvCmvG" || props.storyId === "4WlhflMBRftrhBabhzqL" || props.storyId === "G4uGdmkDklxxMumN9FIc" || props.storyId === "LQ8cYZF1BrqCbKYOI7g7" || props.storyId === "P4hss0ADGtE2wMY5fcrQ" || props.storyId === "YPuRVqGYO2Q2QFKEkRvQ" || props.storyId === "ZcJ9o5kOjzeIvIZ4z0kw" || props.storyId === "clexvJMGmwlGXjY8KLaT" || props.storyId === "nRyYRVND1xh34m32eGMa" || props.storyId === "rj1ZbTjACKeCMOqqAi4l" || props.storyId === "wfnhdxPJPy3v54dXJAr4" || props.storyId === "xlqSrGF4RJz9yfV2MQrr" || props.storyId === "zUEpyuVwo5Tws3hnvFds" )){
                styleOverride.background = "#FFFFFFB0";
            } 

            return (
                <div className="reviews-container" style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight}} onClick={close}>
                    <div id="gallery-overlay" className="BlockingOverlay" style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight, opacity: 0, ...styleOverride}}/>                    
                    <div style={{width: storyDimensions.containerWidth, height: storyDimensions.containerHeight, display: 'flex', flexDirection: 'column', justifyContent: "space-evenly", alignItems: 'center', alignContent: 'center',}}>
                        {reviews}                                
                    </div>
                    <CloseButton onClick={close} color={props.story.primaryColor} width={closeWidth} height={closeHeight} className="reviews-close" style={{top: closeBtnY, left: closeBtnX}} />
                    <img className="google" alt="google" src="/images/google_on_white.png" style={{top: googleY, width: 29 * miniMultiplier, height: 9 * miniMultiplier, pointerEvents: 'none',}}/>
                </div>
            );
        }
        else {
            if (props.placeDetails != null){
                return <SecondaryButton buttonType={reviewsType} open={open}/>;
            }
            else {
                return null;
            }
        }
    }
    else return null;
}

export default ReviewsButtonNEW;